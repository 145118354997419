import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../../../../../services/api';
import {Payment} from '../models/payment';
import {map, switchMap, tap} from 'rxjs/operators';
import {Token} from 'ngx-stripe';
import {VendorStatusService} from '../../../../../services/vendor-status/vendor-status.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {plainToClass} from 'class-transformer';
import {Id} from '../../../../../models/types';
import {SetPaymentDefaultRequestDto} from '../../../../../../users/shared/models/set-payment-default-request.dto';
import {AddCardRequest} from '../models/add-card-request';

@Injectable()
export class PaymentsService {

  private _payments = new BehaviorSubject<Payment[]>(null);
  private _defaultPayment = new BehaviorSubject<Payment>(null);

  private readonly _URL: string;


  constructor(
    private _http: HttpClient,
    private _api: ApiService,
    private _vendorStatusService: VendorStatusService
  ) {
    this._URL = `${_api.API}vendor/payments`;
  }


  get defaultPaymentObservable(): Observable<Payment> {
    if(!!this._payments.getValue()) {
      return this._defaultPayment.asObservable();
    } else {
      return this._getPaymentList()
        .pipe(
          switchMap(() => this._defaultPayment.asObservable())
        );
    }
  }

  get paymentsListObservable(): Observable<Payment[]> {
    if(!!this._payments.getValue()) {
      return this._payments.asObservable();
    } else {
      return this._getPaymentList()
        .pipe(
          switchMap(() => this._payments.asObservable())
        );
    }
  }



  async addPayment(token: Token): Promise<Payment> {
    const body = new AddCardRequest(token.id);

    return this._http
      .post<Payment>(this._URL, body)
      .pipe(
        tap(() => this._updatePaymentsList()),
        tap(() => this._vendorStatusService.updateVendorStatus())
      )
      .toPromise();
  }

  async deletePayment(paymentId: Id): Promise<void> {
    const url = `${this._URL}/${paymentId}`;

    return this._http
      .delete<void>(url)
      .pipe(
        tap(() => this._updatePaymentsList()),
        tap(() => this._vendorStatusService.updateVendorStatus())
      )
      .toPromise();
  }

  async setPaymentAsDefault(paymentId: Id): Promise<void> {
    const url = `${this._URL}/${paymentId}`;
    const body = new SetPaymentDefaultRequestDto(paymentId);

    await this._http
      .patch<Payment>(url, body)
      .pipe(
        tap(() => this._updatePaymentsList()),
        tap(() => this._vendorStatusService.updateVendorStatus())
      )
      .toPromise();
  }



  private async _updateDefaultCreditCard(payments: Payment[]): Promise<void> {
    this._defaultPayment.next(payments.find(payment => payment.is_default));
  }

  private _getPaymentList(): Observable<Payment[]> {
    return this._http
      .get<Payment[]>(this._URL)
      .pipe(
        map(cards => plainToClass(Payment, cards)),
        tap(cards => this._payments.next(cards)),
        tap(cards => this._updateDefaultCreditCard(cards))
      );
  }

  private async _updatePaymentsList(): Promise<Payment[]> {
    return this._getPaymentList().toPromise();
  }
}
