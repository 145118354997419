import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputWrapperComponent } from './input-wrapper.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormValidationModule} from '../../form-validation/form-validation.module';
import {MatIconModule, MatProgressSpinnerModule} from '@angular/material';

@NgModule({
  declarations: [InputWrapperComponent],
  exports: [InputWrapperComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormValidationModule,
    MatIconModule,
    MatProgressSpinnerModule
  ]
})
export class InputWrapperModule { }
