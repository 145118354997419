<button *ngIf="data.closeButton" class="close mat-button" mat-button mat-dialog-close>X</button>

<h3 class="title">
  {{data.msg}}
</h3>

<div class="buttons">
  <button
    *ngFor="let button of data.buttons"
    (click)="dialogRef.close(button.toLowerCase())"
  >
    {{button}}
  </button>

</div>
