import {EmailForm} from '../../../../../forms/email-form/models/email.form';
import {MyIsNotEmpty, MyMaxLength} from '../../../../../form-validation/decorators';

export class SharedReceiverForm extends EmailForm {

  @MyIsNotEmpty()
  @MyMaxLength(100)
  contact_name = '';


  get isFormEmpty(): boolean {
    return !this.contact_name && !this.email;
  }
}
