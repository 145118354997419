import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-are-you-sure-hint',
  templateUrl: './are-you-sure-hint.component.html',
  styleUrls: ['./are-you-sure-hint.component.scss']
})
export class AreYouSureHintComponent {

  @Input()
  showHint = false;

}
