<h3 class="title">
  {{data.msg}}
</h3>

<div *ngIf="!data.activate" class="form-item">
  <mat-select
    placeholder="Select a Reason"
    class="dropdown-up"
    [(ngModel)]='deactivate_reason'
  >
    <mat-option
      *ngFor="let deactivate of DeactivateReasons | keyvalue"
      [value]="deactivate.value"
    >
      {{deactivate.value}}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="validationErrors">Please select a reason.</mat-error>
</div>

<div class="buttons">
  <button
    class="primary"
    (click)="confirm()"

  >
    Confirm
  </button>

  <button
    (click)="decline()"
  >
    Cancel
  </button>
</div>
