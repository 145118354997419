import {AfterViewInit, Directive, Input, OnDestroy} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {DynamicFormGroup} from 'ngx-dynamic-form-builder';
import {Colors} from '../../../constants/colors';
import {takeWhile} from 'rxjs/internal/operators';

@Directive({
  selector: '[appMissedField]'
})
export class MissedFieldDirective implements AfterViewInit, OnDestroy {
  @Input('appMissedField')
  set fields(fields: string[]) {
    this._fields = fields;
    this._updateMissedFields();
  }

  private _form: DynamicFormGroup<any>;
  private _fields: string[] = [];
  private _directiveAlive = true;


  constructor(
    private _parent: FormGroupDirective
  ) {}


  ngAfterViewInit() {
    if (!this._parent) {
      throw new Error('appForm directive should be applied to element with FormGroupDirective');
    }

    if (this._fields === null) {
      throw new Error('Pass string[] into appMissedField');
    }

    this._form = this._parent.form as DynamicFormGroup<any>;

    this._updateMissedFields();
    this._subscribeOnFormChanges();
  }

  ngOnDestroy() {
    this._directiveAlive = false;
  }


  private _updateMissedFields(form = this._form): void {
    if(form) {
      const timeoutTime = 0;

      setTimeout(() => {
        Object
          .keys(form.controls)
          .forEach((key) => {
            const control = form.controls[key];
            const isControlEmpty = control.value === null ||
              control.value === undefined ||
              control.value === '' ||
              (Array.isArray(control.value) && control.value.length === 0);
            const isControlDisabled = control.disabled;

            if(control instanceof DynamicFormGroup) {
              this._updateMissedFields(control);
            } else if(this._fields.includes(key) && control.hasOwnProperty('parentElementRef')) {
              const parent = control['parentElementRef'];
              parent.style.backgroundColor = (isControlEmpty && !isControlDisabled) ? Colors.LIGHT_RED : Colors.TRANSPARENT;
            }
          });
      }, timeoutTime);
    }
  }

  private _subscribeOnFormChanges(): void {
    this._form
      .valueChanges
      .pipe(
        takeWhile(() => this._directiveAlive)
      )
      .subscribe(() => this._updateMissedFields());
  }
}
