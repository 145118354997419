import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {PopupService} from '../../../shared/services/popup.service';
import {LoginService} from '../../../shared/services/login.service';
import {map, take} from 'rxjs/internal/operators';
import {UserPermission} from '../../../shared/models/user/user-permissions.enum';
import {VendorStatus} from '../../../shared/models/status/vendor-status';
import {VendorStatusService} from '../../../shared/services/vendor-status/vendor-status.service';

@Injectable()
export class VendorStatusGuardService implements CanActivate {
  private permittedRoutes: UserPermission[] = [
    UserPermission.PRODUCTS,
    UserPermission.PROFILE,
    UserPermission.ORDERS,
    UserPermission.USERS,
    UserPermission.SERVICES,
  ];



  constructor(
    private _vendorStatusService: VendorStatusService,
    private _router: Router,
    private _popupService: PopupService,
    private _loginService: LoginService
  ) { }

  // 1) on first load make request to vendor status and cache it
  // 2) in all components use cached status
  // 3) on 403 response: empty vendor status cache and redirect to empty path


  canActivate(route: ActivatedRouteSnapshot) {
    return this._vendorStatusService
      .vendorStatusObservable
      .pipe(
        take(1),
        map(status => {
          // check user's login
          if(!status.is_authenticated) {
            window.location.href = environment.customerUrl;
            return false;
          }
          // check if user is vendor
          if(!status.is_vendor) {
            window.location.href = environment.customerUrl;
            return false;
          }
          // redirect from empty path
          if(route.url.length === 0) {
            return this.redirectFromEmptyRoute(status);
          }
          // check user's permissions
          const permittedRoute = this.permittedRoutes
            .find(perRoute => !!route.url[0].path.match(new RegExp('$' + perRoute.toLowerCase())));

          if(status.permissions.length === 0) {
            this._popupService
              .showConfirmPopup('Your account doesn\'t have any permissions. Please contact your admin user.')
              .then(() => this._loginService.logout())
              .catch(() => this._loginService.logout());
            return false;
          }

          if(!!permittedRoute) {
            return this.checkPermissions(status, permittedRoute);
          }
          return true;
        })
      );
  }



  private redirectFromEmptyRoute(user: VendorStatus): boolean {
    const redirectRoute = user.permissions.includes(UserPermission.DASHBOARD)
      ? UserPermission.DASHBOARD
      : user.permissions[0];
    this._router.navigate([redirectRoute.toLowerCase()]);
    return true;
  }

  private checkPermissions(user: VendorStatus, permission: UserPermission): boolean {
    const permittedRoute = user.permissions[0].toLowerCase();

    if(user.permissions.includes(permission)) {
      return true;
    }

    if(permittedRoute) {
      this._router.navigate(['/' + permittedRoute]);
      return false;
    }

    this._popupService.showConfirmPopup('You don’t have permission to this page!');
    return false;
  }
}
