import {Injectable} from '@angular/core';
import {ApiService} from '../api';
import {HttpClient} from '@angular/common/http';
import {Product} from '../../models/product/product';
import {Id} from '../../models/types';
import {map, tap} from 'rxjs/internal/operators';
import {plainToClassSetDefaults} from '../../helpers/plain-to-class-defaults';
import {ProductStoreService} from '../../../add-product/shared/services/product-store.service';
import {StringifyCircularObject} from '../../helpers/StringifyCircularObject';

@Injectable()
export class AddProductService {

  private _URL: string;



  constructor(
    private _api: ApiService,
    private _http: HttpClient,
    private productStoreService: ProductStoreService
  ) {
    this._URL = `${_api.API}vendor/products`;
  }



  async getProductById(productId: Id): Promise<Product> {
    const url = `${this._URL}/${productId}`;

    return this._http
      .get<Product>(url)
      .pipe(
        map(product => plainToClassSetDefaults(Product, product))
      )
      .toPromise();
  }

  async addNewProduct(product: Product): Promise<Product> {
    return this._http
      .post<Product>(this._URL, product)
      .pipe(
        map(newProduct => plainToClassSetDefaults(Product, newProduct))
      )
      .toPromise();
  }

  async editProduct(product: Product | any): Promise<Product> {
    const url = `${this._URL}/${product.id}`;

    const productProcessed = JSON.parse( StringifyCircularObject(product) );

    return this._http
      .patch<Product>(url, productProcessed)
      .pipe(
        map(newProduct => plainToClassSetDefaults(Product, newProduct)),
        tap(newProduct => {
          this.productStoreService.updateProduct(newProduct);
        })
      )
      .toPromise();
  }

  async getCalculatedFields(product: Partial<Product>): Promise<Product> {
    const url = `${this._URL}/${product.id}/preview`;

    return this._http
      .post<Product>(url, product)
      .toPromise();
  }



}
