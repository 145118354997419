import {Injectable} from '@angular/core';
import {ApiService} from '../../../../shared/services/api';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Coupon} from '../models/coupon';
import {Id} from '../../../../shared/models/types';
import {plainToClass} from 'class-transformer';
import {map} from 'rxjs/internal/operators';
import {CouponRequestForm} from '../models/coupon-request.form';
import {CouponList} from '../models/coupon-list';
import {CouponsOrdering} from '../models/coupons-ordering.enum';


@Injectable()
export class CouponsService {

  private _URL: string;

  constructor(
    private _http: HttpClient,
    private _api: ApiService
  ) {
    this._URL = `${_api.API}vendor/coupons`;
  }



  async getCoupons(page: number, ordering: CouponsOrdering[]): Promise<CouponList> {
    let params = new HttpParams().set('page', page.toString());

    if (!!ordering) {
      params = params.append('ordering', ordering.toString());
    }

    return this._http
      .get<CouponList>(this._URL, {params})
      .pipe(
        map(coupons => plainToClass(CouponList, coupons))
      )
      .toPromise();
  }

  async createNewCoupon(couponRequestForm: CouponRequestForm): Promise<Coupon> {
    return this._http
      .post<Coupon>(this._URL, couponRequestForm)
      .pipe(
        map(coupon => plainToClass(Coupon, coupon))
      )
      .toPromise();
  }

  async deleteCoupon(couponId: Id): Promise<void> {
    const url = `${this._URL}/${couponId}`;

    return this._http
      .delete<void>(url)
      .toPromise();
  }

}
