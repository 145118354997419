import {Component, OnInit} from '@angular/core';
import {MainNavigationService} from '../../services/main-navigation.service';
import {SideMenuItem} from '../../models/navigation/side-menu-item';
import {SideMenuService} from '../../services/side-menu.service';
import {VendorStatusService} from '../../services/vendor-status/vendor-status.service';
import {TourImplementedComponent} from '../../../site-tour/shared/tour-implemented.component';
import {TourService} from '../../../site-tour/services/tour.service';
import {TourTypes} from '../../../site-tour/models/tour-types';
import {applyMixins, ObservableComponent} from 'lib-shared';
import {MonoTypeOperatorFunction} from 'rxjs';
import { VendorStatus } from '../../models/status/vendor-status';

@Component({
  selector: 'app-main-navigation',
  templateUrl: 'main-navigation.component.html',
  styleUrls: ['main-navigation.component.scss']
})
class MainNavigationComponent extends TourImplementedComponent implements OnInit {

  isMenuOpened: boolean;

  isShowMenu: boolean;

  menu: SideMenuItem[];
  vendorStatus: VendorStatus;

  _tourTypes = [
    TourTypes.ADD_PAYMENT_CARD,
    TourTypes.ADD_USER
  ];

  _tourItemIndex = 0;

  // ObservableComponent
  _isComponentAlive = true;
  _takeWhileComponentAlive: () => MonoTypeOperatorFunction<any>;

  constructor(
    private _mainNavService: MainNavigationService,
    private _vendorStatusService: VendorStatusService,
    private _sideMenuService: SideMenuService,
    protected _tourService: TourService
  ) {
    super(_tourService);
  }



  ngOnInit() {
    this.createMenu();
    this._subscribeOnSideMenuStatus();
    this._subscribeOnSideMenuOpened();
  }

  createMenu(): void {
    this._vendorStatusService
      .vendorStatusObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(status => {
        this.menu = this._mainNavService.createMenuForCurrentUser(status.permissions || []);
        this.vendorStatus = status;
        this._subscribeOnCurrentTour();
      });
  }

  toggleMenu(): void {
    this._sideMenuService.sideMenuOpened = !this.isMenuOpened;
  }

  private _subscribeOnSideMenuStatus(): void {
    this._sideMenuService
      .sideMenuShowObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(status => this.isShowMenu = status);
  }

  private _subscribeOnSideMenuOpened(): void {
    this._sideMenuService
      .sideMenuOpenedObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(opened => this.isMenuOpened = opened);
  }
}


applyMixins(MainNavigationComponent, [ObservableComponent]);

export {MainNavigationComponent};
