import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {MainNavigationService} from '../../../../shared/services/main-navigation.service';
import {map} from 'rxjs/internal/operators';
import {VendorStatusService} from '../../../../shared/services/vendor-status/vendor-status.service';

@Injectable()
export class PasswordGuard implements CanActivate {

  constructor(
    private _vendorStatusService: VendorStatusService,
    private _mainNav: MainNavigationService,
    private _router: Router
  ) {}


  canActivate() {
    return this._vendorStatusService
      .vendorStatusObservable
      .pipe(
        map(status => {
          if (!status.is_authenticated) {
            this._mainNav.closeMenu();
            return true;
          } else if (status.permissions && !status.permissions.length) {
            return true;
          } else {
            this._router.navigate(['registration/fob_locations']);
          }
        })
      );
  }
}
