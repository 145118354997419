import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../../../../shared/services/api';
import {DiscountGroup} from '../models/discount-group';
import {map, tap} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {Id} from '../../../../shared/models/types';
import {AddDiscountGroupForm} from '../models/add-discount-group.form';
import {DiscountGroupUserList} from '../models/discount-group-user-list';
import {BehaviorSubject, Observable} from 'rxjs';
import {SharedServiceInterface, ShareForm} from '../../../../shared/modules/blocks/sharing-items';
import {NameEditServiceInterface} from '../../../../shared/modules/ux/name-editor/shared/name-edit-service.interface';
import {DiscountUsersQueryParams} from '../models/discount-users.query-params';

@Injectable()
export class DiscountGroupsService implements SharedServiceInterface, NameEditServiceInterface {

  private readonly _URL: string;

  private _updateDiscountGroupsTracker = new BehaviorSubject<void>(null);


  constructor(
    private _http: HttpClient,
    private _api: ApiService
  ) {
    this._URL = `${_api.API}vendor/customer_groups`;
  }


  get updateDiscountGroupsListObservable(): Observable<void> {
    return this._updateDiscountGroupsTracker.asObservable();
  }


  async getVendorDiscountGroups(): Promise<DiscountGroup[]> {
    return this._http
      .get<DiscountGroup[]>(this._URL)
      .pipe(
        map(groups => plainToClass(DiscountGroup, groups))
      )
      .toPromise();
  }

  async getAllDiscountGroups(): Promise<DiscountGroup[]> {
    const url = `${this._URL}/all`;

    return this._http
      .get<DiscountGroup[]>(url)
      .pipe(
        map(groups => plainToClass(DiscountGroup, groups))
      )
      .toPromise();
  }

  async createNewVendorDiscountGroup(form: AddDiscountGroupForm): Promise<void> {
    return this._http
      .post<void>(this._URL, form)
      .pipe(
        tap(() => this._emitGroupsUpdate())
      )
      .toPromise();
  }

  async getVendorDiscountGroup(uid: string): Promise<DiscountGroup> {
    const url = `${this._URL}/${uid}`;

    return this._http
      .get<DiscountGroup>(url)
      .pipe(
        map(groups => plainToClass(DiscountGroup, groups))
      )
      .toPromise();
  }

  async deleteVendorDiscountGroup(uid: string): Promise<void> {
    const url = `${this._URL}/${uid}`;

    return this._http
      .delete<void>(url)
      .pipe(
        tap(() => this._emitGroupsUpdate())
      )
      .toPromise();
  }

  async editName(uid: string, name: string): Promise<void> {
    const url = `${this._URL}/${uid}`;
    const body = {
      name
    };

    return this._http
      .patch<void>(url, body)
      .pipe(
        tap(() => this._emitGroupsUpdate())
      )
      .toPromise();
  }

  async updateVendorDiscountGroupRegistrationStatus(uid: string, availableForRegistration: boolean): Promise<void> {
    const url = `${this._URL}/${uid}`;
    const body = new DiscountGroup();
    body.available_for_registration = availableForRegistration;

    return this._http
      .patch<void>(url, body)
      .pipe(
        tap(() => this._emitGroupsUpdate())
      )
      .toPromise();
  }

  async getVendorDiscountGroupUsers(uid: string, queryParams: DiscountUsersQueryParams): Promise<DiscountGroupUserList> {
    const url = `${this._URL}/${uid}/users`;
    const params = queryParams.getFlatParams();

    return this._http
      .get(url, {params})
      .pipe(
        map(list => plainToClass(DiscountGroupUserList, list))
      )
      .toPromise();
  }

  async removeVendorDiscountGroupUser(discountUid: string, userId: Id): Promise<void> {
    const url = `${this._URL}/${discountUid}/users/${userId}/remove`;

    return this._http
      .post<void>(url, null)
      .toPromise();
  }

  sendSharedLinkViaEmail(uid: string, form: ShareForm): Promise<void> {
    const url = `${this._URL}/${uid}/send_email`;

    return this._http
      .post<void>(url, form)
      .toPromise();
  }


  private _emitGroupsUpdate(): void {
    this._updateDiscountGroupsTracker.next(null);
  }


}
