<ng-template #input>
  <mat-icon
    *ngIf="iconBefore"
    class="icon icon__before"
    [svgIcon]="iconBefore"
    (click)="beforeIconClick()"
  ></mat-icon>
  <ng-content></ng-content>
  <mat-icon
    *ngIf="iconAfter && !showSpinner"
    class="icon icon__after"
    [svgIcon]="iconAfter"
    (click)="afterIconClick()"
  ></mat-icon>
  <mat-spinner
    *ngIf="showSpinner"
    class="icon icon__after"
    [diameter]="18"
    [strokeWidth]="3"
  ></mat-spinner>
</ng-template>

<div
  *ngIf="title"
  class="input-title"
>
  {{ title }}
</div>

<form
  *ngIf="!!form && !noForm"
  class="input-wrapper"
  [class.icon-before]="iconBefore"
  [class.icon-after]="iconAfter"
  [class.invalid]="isInputInvalid"
  [formGroup]="form"
>
  <ng-container *ngTemplateOutlet="input"></ng-container>
</form>

<div
  *ngIf="noForm"
  class="input-wrapper"
>
  <ng-container *ngTemplateOutlet="input"></ng-container>
</div>
