import {MyIsNotEmpty} from '../../../form-validation/decorators';


export class BillingAddressForm {
  @MyIsNotEmpty()
  billing_company_name = '';

  @MyIsNotEmpty()
  billing_country = '';

  billing_state = '';

  @MyIsNotEmpty()
  billing_city = '';

  @MyIsNotEmpty()
  billing_address = '';

  @MyIsNotEmpty()
  billing_post_zip = '';
}
