import {UrlPath} from '../../types';
import {Type} from 'class-transformer';
import {decoratorType} from 'lib-shared';
import {FilterObject} from '../../../modules/blocks/filters/shared/models/filter-object';

export class PaginationList<T> {
  page_size: number;
  count: number;
  next: UrlPath;
  previous: UrlPath;
  page: number;
  results: T[];

  @Type(decoratorType(FilterObject))
  filters: FilterObject[];

  constructor(results: T[]) {
    this.count = !!results ? results.length : 0;
    this.page = 1;
    this.results = results;
  }
}
