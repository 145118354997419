import {Injectable} from '@angular/core';
import {ApiService} from '../../shared/services/api';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {CapitalizeFirstPipe} from '../../shared/pipes/capitalize-first.pipe';
import {Country} from '../../shared/models/common/country';
import {State} from '../../shared/models/common/state';
import {CommonProductCategory} from '../../shared/models/common/common-category';
import {map, switchMap, tap} from 'rxjs/internal/operators';


@Injectable()
export class StaticDataService {
  private _countries: Country[];
  private _shortListCountries: Country[];
  private _countriesWithVariousCountry: Country[];
  private _states: State[];

  private _categories: CommonProductCategory[];

  private readonly _API: string;



  constructor(
    private _http: HttpClient,
    private _api: ApiService,
    private _capitalizeFirstPipe: CapitalizeFirstPipe
  ) {
    this._API = _api.API;
  }


  get shortListcountriesObservable(): Observable<Country[]> {
    if(!!this._shortListCountries) {
      return of(this._shortListCountries);
    } else {
      return this._getShortListCountriesFromApi()
        .pipe(
          switchMap(() => of(this._shortListCountries))
        );
    }
  }

  get countriesObservable(): Observable<Country[]> {
    if(!!this._countries) {
      return of(this._countries);
    } else {
      return this._getCountriesFromApi()
        .pipe(
          switchMap(() => of(this._countries))
        );
    }
  }

  get countriesWithVariousCountry(): Observable<Country[]> {
    if(!!this._countriesWithVariousCountry) {
      return of(this._countriesWithVariousCountry);
    } else {
      return this._getCountriesFromApi()
        .pipe(
          switchMap(() => of(this._countriesWithVariousCountry))
        );
    }
  }

  get statesObservable(): Observable<State[]> {
    if(!!this._states) {
      return of(this._states);
    } else {
      const url = `${this._API}common_states`;

      return this._http
        .get<State[]>(url)
        .pipe(
          tap(resp => this._states = resp),
          switchMap(() => of(this._states))
        );
    }
  }

  get categoriesObservable(): Observable<CommonProductCategory[]> {
    if(!!this._categories) {
      return of(this._categories);
    } else {
      const url = `${this._API}common_vendor_categories`;
      this._categories = [];

      return this._http
        .get<CommonProductCategory[]>(url)
        .pipe(
          tap(categories => this._categories = categories),
          switchMap(() => of(this._categories))
        );
    }
  }

  async getAddressByPostZip(
    postZipField: string,
    countryField: string,
    cityField: string,
    stateField: string,
    form: FormGroup
  ): Promise<void> {
    const post_zip = form.controls[postZipField].value;
    const country_code = form.controls[countryField].value;

    if(post_zip && country_code) {
      try {
        const data = await this.getCityAndStateByPostZip(country_code, post_zip);

        form.controls[cityField].setValue(this._capitalizeFirstPipe.transform(data.city));

        if(data.state) {
          form.controls[stateField].setValue(data.state);
        }
      } catch(err) {
        console.log(err);
      }
    }
  }

  getCityAndStateByPostZip(country: string, post_zip: string): Promise<{state: string, city: string}> {
    const url = `${this._API}common_zip_code`;
    const data = {country, post_zip};

    return this._http
      .post<{state: string, city: string}>(url, data)
      .toPromise();
  }



  private _getCountriesFromApi(): Observable<Country[]> {
    const url = `${this._API}common_countries`;

    return this._http
      .get(url)
      .pipe(
        map(countries => Object.keys(countries)
          .map(country_code => ({
            country_code,
            country_name: countries[country_code]
          }))),
        tap(countries => {
          const variousCountry = new Country('Various', 'VARIOUS');

          this._countries = countries;
          this._countriesWithVariousCountry = [variousCountry].concat(this._countries);
        })
      );
  }

  private _getShortListCountriesFromApi(): Observable<Country[]> {
    const url = `${this._API}common_countries?short_list=1`;

    return this._http
      .get(url)
      .pipe(
        map(countries => Object.keys(countries)
          .map(country_code => ({
            country_code,
            country_name: countries[country_code]
          }))),
        tap(countries => {
          this._shortListCountries = countries;
        })
      );
  }
}
