export enum TourTypes {
  ADD_PAYMENT_CARD = 'T_ADD_PC',
  ADD_USER = 'T_ADD_USER',
  ADD_COUPON = 'T_ADD_COUPON',
  ADD_FOB_LOCATION = 'T_ADD_FOB_LOCATION',
  ADD_PRODUCT = 'T_ADD_PRODUCT',
  EMBED_CODE = 'T_EMBED_CODE',
  ARCHIVE_RESTORE_PRODUCT = 'T_ARCHIVE_RESTORE_PRODUCT',
  GO_LIVE = 'T_GO_LIVE',
  GO_LIVE_WITH_PAYMENT = 'T_GO_LIVE_WITH_PAYMENT',
  NUTRITIONAL_LABEL = 'T_NUTRITIONAL_LABEL',
  ACTIVATE_PRODUCTS = 'T_ACTIVATE_PRODUCTS',
  ADD_PRODUCT_RESTRICTIONS = 'T_ADD_PRODUCT_RESTRICTIONS',
  BULK_EDIT = 'T_BULK_EDIT',
  EDIT_NUTRITIONAL = 'T_EDIT_NUTRITIONAL'
}
