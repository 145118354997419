import {StatusLevel} from './status-level.enum';
import {UserPermission} from '../user/user-permissions.enum';
import {SubscriptionPlan} from '../../../profile/account/shared/models/subscription-plan.enum';
import {Type} from 'class-transformer';
import {decoratorType} from 'lib-shared';

export class VendorStatus {
  is_authenticated: boolean;
  is_vendor: boolean;
  is_customer: boolean;
  is_factoring: boolean;
  email_verified: boolean;
  status: StatusLevel;
  company_name: string;
  email: string;
  contact_name: string;
  products_link : string;
  permissions: UserPermission[];
  is_vendor_admin: boolean;
  term_of_use: boolean;
  profile_completed: boolean;
  can_go_live: boolean;
  has_products: boolean;
  has_fob_locations: boolean;
  has_payment: boolean;
  can_create_prototype_products: boolean;
  vendor_term_and_condition: string;
  agreement_url: string;

  @Type(decoratorType(Date))
  subscription_paid_until: Date;
  subscription_plan: SubscriptionPlan;
  show_subscription_plan_selection: boolean;
  should_approve_inventory_review: boolean;

  subscription_overdue: boolean;
  has_default_payment_method: boolean;

  show_price_update_popup: boolean;

  login_as: boolean;

  static emptyStatus() {
    return new this();
  }


  get isUserNew(): boolean {
    return this.status === StatusLevel.NEW;
  }

  get isUserWantToGoLive(): boolean {
    return this.status === StatusLevel.WANT_GO_LIVE;
  }

  get allowedToGoToProfile(): boolean {
    return !!this.permissions && this.permissions.includes(UserPermission.PROFILE);
  }

  get allowedToProducts(): boolean {
    return !!this.permissions && this.permissions.includes(UserPermission.PRODUCTS);
  }

  get allowedToUsers(): boolean {
    return !!this.permissions && this.permissions.includes(UserPermission.USERS);
  }

  get allowedToDashboard(): boolean {
    return !!this.permissions && this.permissions.includes(UserPermission.DASHBOARD);
  }

  get isRegistrationFinished(): boolean {
    return this.has_fob_locations && this.profile_completed;
  }

  get hasFreeSubscriptionPure(): boolean {
    return this.subscription_plan === SubscriptionPlan.FREE && this.subscription_paid_until !== null;
  }

  get hasFreeSubscriptionUntilFirstOrder(): boolean {
    return this.subscription_plan === SubscriptionPlan.FREE && this.subscription_paid_until === null;
  }

  get hasPaidSubscription(): boolean {
    return this.subscription_plan !== SubscriptionPlan.FREE;
  }

  get showApproveInventoryReviewButton(): boolean {
    return this.should_approve_inventory_review && this.allowedToProducts && this.allowedToDashboard;
  }

  get subscriptionPlan(): Boolean {
    if (this.subscription_plan == SubscriptionPlan.FREE)
    {
      return false
    }
    else{
      return true
    }
  }

}