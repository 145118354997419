import {Type} from 'class-transformer';
import {decoratorType} from 'lib-shared';
import {TransactionStatus} from './transaction-status.enum';

export class Transaction {
  charge_number: number;
  description: string;

  @Type(decoratorType(Date))
  created: Date;

  payment_method: string;
  amount: number;

  status: TransactionStatus;
}
