import {Injectable} from '@angular/core';
import {ApiService} from './api';
import {BehaviorSubject, Observable, timer} from 'rxjs';
import {Notification} from '../models/notification';
import {HttpClient} from '@angular/common/http';
import {plainToClass} from 'class-transformer';
import {concatMap, expand, map, tap} from 'rxjs/internal/operators';
import {ObservableComponent} from 'lib-shared';

@Injectable()
export class NotificationService extends ObservableComponent {

  private readonly _TIMEOUT_BETWEEN_REQUESTS = 1000 * 60 * 10;
  private readonly _URL: string;
  private readonly _notifications = new BehaviorSubject<Notification[]>([]);



  constructor(
    private http: HttpClient,
    private api: ApiService
  ) {
    super();
    this._URL = `${api.API}common_notifications`;
    this._setupNotificationsUpdateWorker();
  }



  get notificationsObservable(): Observable<Notification[]> {
    return this._notifications.asObservable();
  }

  get notificationsAmountObservable(): Observable<number> {
    return this._notifications
      .asObservable()
      .pipe(
        map(notifications => notifications.filter(notification => !notification.seen).length)
      );
  }



  requestNotifications(): Observable<Notification[]> {
    return this.http
      .get<Notification[]>(this._URL)
      .pipe(
        map(notifications => plainToClass(Notification, notifications)),
        tap(notifications => this._notifications.next(notifications))
      );
  }

  async setNotificationAsViewed(notification: Notification): Promise<void> {
    const url = `${this._URL}/${notification.id}/mark_seen`;

    return this.http
      .post<void>(url, {})
      .pipe(
        tap(() => {
          notification.seen = true;
          this._getNotificationAndUpdateAll(notification);
        })
      )
      .toPromise();
  }


  private _setupNotificationsUpdateWorker(): void {
    this.requestNotifications()
      .pipe(
        this._takeWhileComponentAlive(),
        expand(() => timer(this._TIMEOUT_BETWEEN_REQUESTS).pipe(concatMap(() => this.requestNotifications())))
      )
      .subscribe();
  }

  private _getNotificationAndUpdateAll(notification: Notification): void {
    const updatedNotifications = this._notifications
      .getValue()
      .map(notif => notif.id === notification.id ? notification : notif);

    this._notifications.next(updatedNotifications);
  }
}
