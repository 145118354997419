import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../api';
import {ProductsSettingsForm} from '../../../product/product-settings/models/products-settings.form';

@Injectable()
export class BusinessRulesService {

  private _url: string;

  constructor(
    private api: ApiService,
    private http: HttpClient
  ) {
    this._url = `${this.api.API}vendor/settings`;
  }


  getBusinessRules(): Observable<ProductsSettingsForm> {
    return this.http.get<ProductsSettingsForm>(this._url);
  }

  setBusinessRules(rules: ProductsSettingsForm): Observable<ProductsSettingsForm> {
    return this.http.post<ProductsSettingsForm>(this._url, rules);
  }

}
