import {Injectable} from '@angular/core';
import {HttpServiceAbstract} from '../models/components/http-service.abstract';
import { EmailVerification } from './email-verification';

@Injectable()
export class EmailVerificationService extends HttpServiceAbstract {

	protected _apiUrl = '/email_verification';


	async verifyEmail(uid: string, token: string): Promise<void> {
		const data = new EmailVerification(uid, token);

		return this._post<void>(this._url, data);
	}
}
