import {ProductNutritionElementSign} from './enums/product-nutrition-element-sign.enum';
import {ProductNutritionElementUom} from './enums/product-nutrition-element-uom.enum';
import {ProductNutritionElementWithDv} from './product-nutrition-element-with-dv';
import {plainToClassFromExist} from 'class-transformer';
import {MaxLength, ValidateIf} from 'class-validator';
import {MyIsOptional, MyIsNumberString, MyIsNotEmpty} from '../../../modules/form-validation/decorators';

export class ProductNutritionElementCustom extends ProductNutritionElementWithDv {
  nutrient_name: string;

  constructor(dv: number, nutrient_name: string, value: string, sign?: ProductNutritionElementSign, uom?: ProductNutritionElementUom) {
    super(dv, value, sign, uom);
    this.nutrient_name = nutrient_name;
  }
}

export class ProductNutritionElementCustomForm {
  @MyIsNotEmpty()
  @MaxLength(50)
  nutrient_name: string;

  @ValidateIf(o => !((!!o.value && !!o.uom)) && !o.dv)
  @MyIsOptional()
  @MyIsNumberString()
  dv: string;

  @ValidateIf(o => !(!!o.dv && !o.value))
  @MyIsNotEmpty()
  @MyIsNumberString()
  value: string;

  @MyIsNotEmpty()
  sign: ProductNutritionElementSign;

  uom: ProductNutritionElementUom;

  constructor(nutrition: any) {
    plainToClassFromExist(this, nutrition);
  }

  get nutritionErrors(): string[] {
    const errors = [];

    /*if(!(!!this.dv || (!!this.value && !!this.uom))) {
      errors.push('Please, fill value+uom or DV');
    } else if(!!this.value && !this.uom) {
      errors.push('Please, fill uom');
    } else if(!!this.uom && !this.value) {
      errors.push('Please, fill value');
    }*/

    return errors;
  }
}
