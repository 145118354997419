import {AbstractControl} from '@angular/forms';
import {Component, Input} from '@angular/core';
import {ErrorMessage, ErrorsList} from '../models/form-errors.interface';

@Component({
  selector: 'app-errors',
  template: `
    <mat-error *ngIf="!!control && control.invalid && control.touched">
      {{error}}
    </mat-error>
  `,
  styles: [`
    :host {
      position: absolute;
      width: 100%;
      left: 0;
      text-align: left;
      margin-top: 2px;
      top: 100%;
    }

    mat-error {
      font-size: 0.8em;
    }
  `],
})

export class AppErrorsComponent {
  @Input()
  control: AbstractControl;

  @Input()
  controlErrors: ErrorsList;

  get error(): ErrorMessage {
    return this.controlErrors ? this.controlErrors[0] : null;
  }
}
