<app-card
  class="widget-product-list"
>
  <div class="inner-container">
    <div
      *ngIf="showSpinner"
      class="spinner-container"
    >
      <mat-spinner></mat-spinner>
    </div>

    <div class="payments-list">
      <mat-radio-group
        *ngIf="!!payments && payments.length > 0"
        [(ngModel)]="selectedPaymentId"
      >
        <mat-radio-button
          *ngFor="let payment of payments"
          [class.selected]="selectedPaymentId === payment.id"
          [value]="payment.id"
        >
          <div class="payment-item">
            <div class="digits">
              <span>****</span>
              <span>****</span>
              <span>****</span>
              <span>{{ payment.four_digits }}</span>
            </div>
            <img [src]="payment.logoImagePath" alt="logo" class="logo">
          </div>
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="buttons">
      <app-button
        [color]="ButtonColorScheme.WHITE"
        (click)="addNewCard()"
      >
        Add card
      </app-button>
      <app-button
        [color]="ButtonColorScheme.WHITE"
        (click)="choosePayment()"
      >
        Choose
      </app-button>
    </div>
  </div>
</app-card>
