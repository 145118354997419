import {FilterObjectType} from './filter-object-type.enum';
import {plainToClass, Transform} from 'class-transformer';
import {FilterChoice} from './filter-choice';

export class FilterObject {
  title: string;
  query_param: string;
  type: FilterObjectType;

  // use Transform here, because @Type(Map) transforms all objects in a map including FilterChoice
  @Transform(object => new Map(Object.entries(object).map(([key, value]) => [key, plainToClass(FilterChoice, value)] as [string, FilterChoice])))
  choices: Map<string, FilterChoice>;


  constructor(title: string, query_param: string, type: FilterObjectType, choices: Map<string, FilterChoice> = new Map()) {
    this.title = title;
    this.query_param = query_param;
    this.type = type;
    this.choices = choices;
  }


  get filterChoicesAmount(): number {
    return this.choices.size;
  }

  get isSelectFilter(): boolean {
    return this.type === FilterObjectType.SELECT || this.type === FilterObjectType.SELECT_FIXED;
  }

  get isSelectFilterFixed(): boolean {
    return this.type === FilterObjectType.SELECT_FIXED;
  }

  get isRangeFilter(): boolean {
    return this.type === FilterObjectType.RANGE;
  }


  getChoiceNameById(id: number): string {
    return Array
      .from(this.choices.entries())
      .find(([key, choice]) => choice.value === id)
      [0];
  }
}
