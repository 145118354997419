import {Component, OnInit} from '@angular/core';
import {ObservableComponent} from 'lib-shared';
import {SubscriptionsModalService} from '../shared/services/subscriptions-modal.service';
import {SubscriptionsService} from '../shared/services/subscriptions.service';
import {VendorStatusService} from '../../../shared/services/vendor-status/vendor-status.service';
import {VendorStatus} from '../../../shared/models/status/vendor-status';
import {SubscriptionPlanDto} from '../shared/models/subscription-plan.dto';
import {ButtonType} from '../../../shared/modules/ux/button/models/button-type.enum';
import {ButtonColorScheme} from '../../../shared/modules/ux/button/models/button-color-scheme.enum';
import {SubscriptionPlan} from '../shared/models/subscription-plan.enum';
import {takeWhile} from 'rxjs/operators';

@Component({
  selector: 'app-subscription-change-modal',
  templateUrl: './subscription-change-modal.component.html',
  styleUrls: ['./subscription-change-modal.component.scss']
})
export class SubscriptionChangeModalComponent extends ObservableComponent implements OnInit {

  showModal = false;
  showSuccessModal = false;

  showButtonSpinner = false;

  vendorStatus: VendorStatus;
  currentSubscriptionPlan: SubscriptionPlan;

  readonly SubscriptionPlan = SubscriptionPlan;


  constructor(
    private _subscriptionsModalService: SubscriptionsModalService,
    private _subscriptionsService: SubscriptionsService,
    private _vendorStatusService: VendorStatusService,
  ) {
    super();
  }


  ngOnInit() {
    this._subscribeOnVendorStatus();
    this._subscribeOnModalStatus();
  }


  get isSubscriptionFree(): boolean {
    return !!this.currentSubscriptionPlan && this.currentSubscriptionPlan === SubscriptionPlan.FREE;
  }

  get currentSubscriptionPlanDto(): SubscriptionPlanDto {
    return !!this.currentSubscriptionPlan && this._subscriptionsService.subscriptionPlansTable[this.currentSubscriptionPlan];
  }

  get totalAmount(): number {
    return this.isSubscriptionFree
      ? 0
      : +this.currentSubscriptionPlanDto.cost;
  }

  get modalTitle(): string {
    return this.vendorStatus.show_subscription_plan_selection
      ? 'Congratulation, you have your first order!'
      : 'Epallet platform fee(s)';
  }

  get successSubtitle(): string {
    if(!this.vendorStatus.show_subscription_plan_selection) {
      return 'You have changed subscription plan successfully';
    }
    return this.isSubscriptionFree
      ? 'You have activated Unlimited Epallet Platform Service Fee using promo code'
      : 'We will charge Epallet Platform Service Fee on the 1st day of the next month';
  }



  closeModal(): void {
    this._subscriptionsService.trackSubscriptionModalShow();
    this._subscriptionsModalService.closeModal();
  }

  async confirmModal(): Promise<void> {
    if(!this.isSubscriptionFree) {
      await this._changeSubscriptionPlan();
    }
    this.closeModal();
    this.showSuccessModal = true;
  }

  closeSuccessModal(): void {
    this.showSuccessModal = false;
  }

  setSubscription(plan: SubscriptionPlan) {
    if(!this.isSubscriptionFree) {
      this.currentSubscriptionPlan = plan;
    }
  }



  private _subscribeOnModalStatus(): void {
    this._subscriptionsModalService
      .showModalObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(showModal => this.showModal = showModal);
  }

  private _subscribeOnVendorStatus(): void {
    this._vendorStatusService
      .vendorStatusObservable
      .pipe(
        this._takeWhileComponentAlive(),
        takeWhile(() => !this.showSuccessModal)
      )
      .subscribe(status => this.vendorStatus = status);
  }

  private async _changeSubscriptionPlan(): Promise<void> {
    this.showButtonSpinner = true;

    try {
      await this._subscriptionsService.changeSubscriptionPlan(this.currentSubscriptionPlan);
    } catch(err) {
      console.error(err);
    }

    this.showButtonSpinner = false;
  }

}
