import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs/Rx';
import {VendorStatus} from '../../models/status/vendor-status';
import {filter} from 'rxjs/internal/operators';
import {ShowTermsOfUseModalService} from '../show-terms-of-use-modal.service';
import {SubscriptionsModalService} from '../../../profile/account/shared/services/subscriptions-modal.service';


@Injectable()
export class VendorStatusCacheService {

  isCached = false;

  private readonly _vendorStatus = new BehaviorSubject<VendorStatus>(null);


  constructor(
    private _showTermsOfUseModal: ShowTermsOfUseModalService,
    private _subscriptionsModalService: SubscriptionsModalService
  ) {}


  get vendorStatusObservable(): Observable<VendorStatus> {
    return this._vendorStatus
      .asObservable()
      .pipe(
        filter(stat => !!stat)
      );
  }


  clearCache(): void {
    this._vendorStatus.next(VendorStatus.emptyStatus());
    this.isCached = false;
  }

  set vendorStatus(status: VendorStatus) {
    this._vendorStatus.next(status);

    if(status.term_of_use) {
      this._showTermsOfUseModal.closeModal();
    } else {
      this._showTermsOfUseModal.openModal();
    }

    if(status.show_subscription_plan_selection) {
      this._subscriptionsModalService.openModal();
    }

    this.isCached = true;
  }

}
