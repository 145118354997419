import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {NotificationService} from '../../../services/notification.service';
import {LoginService} from '../../../services/login.service';
import {environment} from '../../../../../environments/environment';
import {SideMenuService} from '../../../services/side-menu.service';
import {VendorStatus} from '../../../models/status/vendor-status';
import {VendorStatusService} from '../../../services/vendor-status/vendor-status.service';
import {MatIconEnum} from '../../../models/common/mat-icon.enum';
import {ObservableComponent} from 'lib-shared';
import {TourService} from '../../../../site-tour/services/tour.service';
import {TourModel} from '../../../../site-tour/models/tour.model';
import {PopupService} from '../../../services/popup.service';
import {InventoryService} from '../../../services/inventory.service';
import { VendorHidePopUpService } from '../../../services/vendor-status/vendor-hide-pop-up.service';
import { VendorHidePopUp } from '../../../models/status/vendor-hide-popup';
import { ProfileQueryParams } from '../../../../profile/shared/profile.query-params';
import { ProfileTab } from '../../../../profile/shared/profile-tab.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends ObservableComponent implements OnInit {

  @Input()
  searchInputForm: FormGroup;

  @Output()
  searchInputFormChange = new EventEmitter<FormGroup>();

  @Input()
  controlName: string;

  @Input()
  searchHintsArray: string[];

  @Input()
  showInputSpinner = false;

  @Input()
  displayParameter: string;

  @Input()
  valueParameter: string;

  @Output()
  onElementClick = new EventEmitter<any>();

  isShowNotificationPopup = false;
  activeNotificationsAmount: number;

  showHints = false;
  toursLoading = false;
  inventoryLoading = false;

  showPriceUpdatePopup = false;
  vendorHidePopup: VendorHidePopUp;

  userClosedMessage = false;

  vendorStatus: VendorStatus;
  showUserDropdown = false;
  showTourListDropdown = false;

  isSideMenuShown = false;

  // customerUrl = environment.customerUrl;

 
  apiUrl = environment.apiUrl;
  showTours = environment.showTours;

  readonly MatIconEnum = MatIconEnum;
  readonly ProfileQueryParams = ProfileQueryParams;
  readonly ProfileTab = ProfileTab;
  
  availableTours: TourModel[];

  constructor(
    private _notificationsService: NotificationService,
    private _vendorStatusService: VendorStatusService,
    private _loginService: LoginService,
    private _sideMenuService: SideMenuService,
    private _tourService: TourService,
    private _popupService: PopupService,
    private _inventoryService: InventoryService,
    private _vendorHidePopUp: VendorHidePopUpService
  ) {
    super();
  }


  ngOnInit() {
    this._getCurrentUserInfo();
    this._subscribeOnSideMenuStatus();
    this._subscribeOnNotificationsAmount();
    this._subscribeOnToursList();
    this._subscribeOnVendorStatus();
  }


  get searchQuery(): string {
    return this.searchInputForm.controls[this.controlName].value;
  }

  set searchQuery(value: string ) {
    this.searchInputForm.controls[this.controlName].setValue(value);
  }

  get profileName(): string {
    return !!this.vendorStatus ? (this.vendorStatus.contact_name || this.vendorStatus.email) : '';
  }

  get showValidationMessage(): boolean {
    return !!this.vendorStatus && this.vendorStatus.isUserWantToGoLive;
  }

  get showPaymentFailure(): boolean {
    return !!this.vendorStatus && this.vendorStatus.subscription_overdue;
  }

  get showMessage(): boolean {
    return this.showValidationMessage || this.vendorStatus.show_price_update_popup;
  }

  get companyName(): string {
    return !!this.vendorStatus
      ? this.vendorStatus.company_name
      : 'your company';
  }

 get customerUrl():string {
  return environment.customerUrl;
 }

 async closeMessage(): Promise<void> {
    if(this.showPriceUpdatePopup) {
      await this._vendorHidePopUp.changeCloseDate(this.vendorHidePopup);
      this.showPriceUpdatePopup = false;
      this.vendorStatus = await this._vendorStatusService.checkVendorStatus();
    }
    this.userClosedMessage = true;
  }

  emitElementClick(data: string): void {
    this.searchQuery = data[this.displayParameter] || data;
    this.upEvent(this.searchQuery);
  }

  upEvent(search: string = ''): void {
    this.onElementClick.emit(search);
  }

  openNotification(): void {
    this.isShowNotificationPopup = true;
    this._notificationsService.requestNotifications().toPromise();
  }

  closeNotification(): void {
    this.isShowNotificationPopup = false;
  }

  toggleUserDropDown(): void {
    this.showUserDropdown = !this.showUserDropdown;
  }

  closeUserDropDown(): void {
    this.showUserDropdown = false;
  }

  // async closePopUP(): Promise<void>{
  //   await this._vendorHidePopUp.changeCloseDate(this.vendorHidePopup);
  //   this.showPriceUpdatePopup = false;
  //   this.vendorStatus = await this._vendorStatusService.checkVendorStatus();
  // }

  async showTourListDropDown(): Promise<void> {
    this.showTourListDropdown = true;
    if(this.toursLoading) return;
    this.toursLoading = true;
    await this._tourService.loadToursStatuses();
    this.toursLoading = false;
  }

  closeTourListDropDown(): void {
    this.showTourListDropdown = false;
    this.toursLoading = false;
  }

  async logout(): Promise<void> {
    await this._loginService.logout();
  }

  openHints(): void {
    this.showHints = true;
  }

  closeHints(): void {
    setTimeout(() => this.showHints = false, 200);
  }

  clearQuery(): void {
    this.searchQuery = '';
    this.upEvent();
  }


  private _getCurrentUserInfo(): void {
    this._vendorStatusService
      .vendorStatusObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(status => {
        this.vendorStatus = status;
        // this.showPriceUpdatePopup = this.vendorStatus.show_price_update_popup;
      });
  }

  private _subscribeOnSideMenuStatus(): void {
    this._sideMenuService
      .sideMenuOpenedObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(showMenu => this.isSideMenuShown = showMenu);
  }

  private _subscribeOnNotificationsAmount(): void {
    this._notificationsService
      .notificationsAmountObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(amount => this.activeNotificationsAmount = amount);
  }

  private _subscribeOnToursList(): void {
    this._tourService
      .toursListAsObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(list => this.availableTours = list);
  }

  onTourSelect(tourId): void {
    this._tourService.startNewTour(tourId);
  }

  async showConfirmInventoryModal(): Promise<void> {
    if( this.inventoryLoading ) return;
    const approve = await this._popupService
      .showDeletePopup('I confirm that my products listing and pricing are currently actual at Epallet.');
    if(approve) {
      this.inventoryLoading = true;
      try {
        await this._inventoryService.approveReview();
        await this._vendorStatusService.updateVendorStatus();
        this.inventoryLoading = false;
      } catch (e) {
        this.inventoryLoading = false;
      }
    }
  }

  private _subscribeOnVendorStatus(): void {
    this._vendorStatusService
      .vendorStatusObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(async (status: VendorStatus) => {
        this.vendorStatus = status;
        this.showPriceUpdatePopup = this.vendorStatus.show_price_update_popup;
      });
  }
}