<div
  class="message"
  [class.block]="isStyleBlock"
  [class.text]="isStyleText"
>
  <div
    class="message-header"
    [ngClass]="{
    'error': message.type === GeneralMessageType.ERROR,
    'notice': message.type === GeneralMessageType.NOTICE,
    'confirmation': message.type === GeneralMessageType.CONFIRMATION,
    'with-body': !isBodyEmpty
  }"
  >
    <span class="message-title">
      {{ message.title }}
    </span>

    <img
      *ngIf="isStyleBlock"
      class="close-icon"
      src="../../../../../assets/images/messages/cross.svg"
      alt="close-icon"
      (click)="onRemoveMessage()"
    >
  </div>

  <div
    *ngIf="isStyleBlock"
    class="body-wrapper"
    #bodyWrapper
  >
    <ng-content
      class="body"
    ></ng-content>
  </div>
</div>
