import {AppComponent} from './app.component';
import { CommonModule } from '@angular/common';
import {CertificationRequestComponent} from './certification-request/certification-request.component';
import {VideoConstructorComponent} from './video-constructor/video-constructor.component';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import { Router } from "@angular/router";
import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {MatRadioModule} from '@angular/material/radio';
import {DatePipe} from '@angular/common';
import {CashPipe} from 'lib-shared';
import {MatCheckboxModule,MatIconModule, MatInputModule, MatProgressSpinnerModule, MatSliderModule, MatSlideToggleModule, MatTabsModule, MatTooltipModule} from '@angular/material';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PasswordStrengthBarModule} from 'ng2-password-strength-bar';
import {AllowCredentialsInterceptorService} from './core/services/allow-credentials-interceptor.service';
import {ResponseStatusWatcherService} from './core/services/response-status-watcher.service';
import {PasswordService} from './profile/shared/password.service';
import {GoogleAnalyticsEventsService} from './shared/services/google-analytics-events.service';
import {ProfileService} from './registration/shared/services/profile.service';
import {RegistrationNavService} from './registration/shared/services/registration-navigation.service';
import {MainNavigationService} from './shared/services/main-navigation.service';
import {VideoConverterService} from './video-constructor/services/video-constructor.service';
import {RegistrationGuard} from './registration/shared/services/guards/registration-guard.service';
import {PasswordGuard} from './registration/shared/services/guards/password-guard.service';
import {DownloadLinkService} from './shared/services/download-link.service';
import {NotificationService} from './shared/services/notification.service';
import {CapitalizeFirstPipe} from './shared/pipes/capitalize-first.pipe';
import {ProductStoreService} from './add-product/shared/services/product-store.service';
import {ProductVideosService} from './add-product/shared/services/product-videos.service';
import {ProductListService} from './shared/services/product/product-list.service';
import {ProductImagesService} from './add-product/shared/modules/image-uploader/services/product-images.service';
import {AddProductService} from './shared/services/product/add-product.service';
import {BusinessRulesService} from './shared/services/product/business-rules.service';
import {StockRecordsService} from './shared/services/product/stock-records.service';
import {RegisterIconService} from './shared/services/register-icon.service';
import {CertificatesService} from './shared/services/certificates.service';
import {FobLocationsService} from './registration/fob-locations/shared/services/fob-locations.service';
import {ButtonModule} from './shared/modules/ux/button/button.module';
import {SideMenuService} from './shared/services/side-menu.service';
import {BulkDownloadService} from './shared/services/product/bulk-download.service';
import { FobLocationBulkDownloadService } from './shared/services/fob-location-bulk-downaload.service';
import {CouponsService} from './promotions/coupons/shared/services/coupons.service';
import {TermsOfUseModule} from './shared/modules/blocks/terms-of-use/terms-of-use.module';
import {ShowTermsOfUseModalService} from './shared/services/show-terms-of-use-modal.service';
import {VendorStatusService} from './shared/services/vendor-status/vendor-status.service';
import {VendorStatusCacheService} from './shared/services/vendor-status/vendor-status-cache.service';
import {GoLiveService} from './shared/services/vendor-status/go-live.service';
import {RegistrationModalsService} from './shared/services/registration-modals.service';
import {RegistrationFinishModalModule} from './shared/modules/blocks/registration-finish-modal/registration-finish-modal.module';
import {RoutingHistoryService} from './shared/services/routing-history/routing-history.service';
import {SubscriptionsModalService} from './profile/account/shared/services/subscriptions-modal.service';
import {AccountModule} from './profile/account/account.module';
import {GoLiveModalService} from './profile/account/shared/services/go-live-modal.service';
// import {TopMessageModule} from './shared/modules/blocks/top-message/top-message.module';
import {PaymentsService} from './shared/modules/blocks/payments/shared/services/payments.service';
import {TransactionsUpdateService} from './profile/account/shared/services/transactions-update.service';
import {DiscountGroupsService} from './promotions/discount-groups/shared/services/discount-groups.service';
import {SiteTourModule} from './site-tour/site-tour.module';
import {TourService} from './site-tour/services/tour.service';
import { ProductSettingsComponent } from './product/product-settings/product-settings.component';
import { MessageModule } from './shared/modules/ux/message/message.module';
import { MessageListModule } from './shared/modules/ux/message-list/message-list.module';
import {AreYouSureInputModule} from './shared/directives/are-you-sure-input/are-you-sure-input.module';
import { FormValidationModule } from './shared/modules/form-validation/form-validation.module';
import { HeaderModule } from './shared/modules/blocks/header/header.module';
import { InputWrapperModule } from './shared/modules/inputs/input-wrapper/input-wrapper.module';
import { MatSelectModule } from '@angular/material';
import { OrderMinimumQuantityComponent } from './order-minimum-quantity/order-minimum-quantity.component';
import { OrderMinimumWeightComponent } from './order-minimum-weight/order-minimum-weight.component';
import { ChargePerPalletComponent } from './charge-per-pallat/charge-per-pallet.component';
import { OrderMinimumCaseQuantityComponent } from './order-minimum-case-quantity/order-minimum-case-quantity.component';
import { OrderWeightService } from './shared/services/product/order-weight.service';
import { OrderQuantityService } from './shared/services/product/order-quantity.service';
import { ChargePerPalletService } from './shared/services/product/charge-per-pallet.service';
import { OrderCaseQuantityService } from './shared/services/product/order-quantity-case.service';
import {IconButtonModule} from './shared/modules/ux/icon-button/icon-button.module';
import { MixedPalletSettingComponent } from './mixed-pallet-setting/mixed-pallet-setting.component';
import { MixedPalletService } from './shared/services/product/mixed-pallet.service';
import { EmailVerificationService } from './shared/services/email-verification.service';
import { EmailVerificationModule } from './email-verification/email-verification.module';
import { RequestLinkService } from './shared/services/request-link.service';
import * as Sentry from "@sentry/angular";

@NgModule({
  declarations: [
    AppComponent,
    CertificationRequestComponent,
    VideoConstructorComponent,
    ProductSettingsComponent,
    OrderMinimumQuantityComponent,
    ChargePerPalletComponent,
    OrderMinimumWeightComponent,
    OrderMinimumCaseQuantityComponent,
    MixedPalletSettingComponent,
  ],
  imports: [
    CoreModule.forRoot(),
    CommonModule,
    SharedModule,
    BrowserModule,
    FormsModule,
    FormValidationModule,
    ReactiveFormsModule,
    HeaderModule,
    HttpClientModule,
    MatRadioModule,
    MatCheckboxModule,
    MatInputModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatIconModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    PasswordStrengthBarModule,
    ButtonModule,
    AppRoutingModule,
    TermsOfUseModule,
    EmailVerificationModule,
    RegistrationFinishModalModule,
    AccountModule,
    // TopMessageModule,
    AreYouSureInputModule,
    SiteTourModule,
    MessageModule,
    MessageListModule,
    InputWrapperModule,
    MatSelectModule,
    IconButtonModule,
    MatTooltipModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AllowCredentialsInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseStatusWatcherService, multi: true },
    PasswordService,
    MainNavigationService,
    GoogleAnalyticsEventsService,
    ProfileService,
    FobLocationsService,
    CertificatesService,
    VideoConverterService,
    RegistrationNavService,
    RegistrationGuard,
    PasswordGuard,
    DownloadLinkService,
    NotificationService,
    CapitalizeFirstPipe,
    DatePipe,
    CashPipe,
    ProductListService,
    ProductVideosService,
    ProductStoreService,
    ProductImagesService,
    BusinessRulesService,
    StockRecordsService,
    AddProductService,
    RegisterIconService,
    SideMenuService,
    BulkDownloadService,
    FobLocationBulkDownloadService,
    CouponsService,
    ShowTermsOfUseModalService,
    EmailVerificationService,
    RequestLinkService,
    VendorStatusService,
    VendorStatusCacheService,
    GoLiveService,
    RegistrationModalsService,
    RoutingHistoryService,
    SubscriptionsModalService,
    GoLiveModalService,
    PaymentsService,
    TransactionsUpdateService,
    DiscountGroupsService,
    TourService,
    OrderWeightService,
    OrderQuantityService,
    ChargePerPalletService,
    OrderCaseQuantityService,
    MixedPalletService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }