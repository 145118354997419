import {Injectable} from '@angular/core';
import {VendorStatusService} from './vendor-status.service';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../api';
import {PromoCodeForm} from '../../../profile/account/shared/components/promo-code-form-container/models/promo-code.form';
import {TransactionsUpdateService} from '../../../profile/account/shared/services/transactions-update.service';

@Injectable()
export class GoLiveService {

  private _URL: string;


  constructor(
    private _api: ApiService,
    private _http: HttpClient,
    private _vendorStatusService: VendorStatusService,
    private _transactionsUpdateService: TransactionsUpdateService
  ) {
    this._URL = `${_api.API}vendor/go_live`;
  }



  async goLive(promoCodeForm: PromoCodeForm): Promise<void> {
    await this._http
      .post<void>(this._URL, promoCodeForm)
      .toPromise();
    await this._vendorStatusService.updateVendorStatus();
    await this._transactionsUpdateService.emitTransactionsUpdate();
  }

  async checkGoLivePromoCode(promoCodeForm: PromoCodeForm): Promise<void> {
    const url = `${this._URL}/validate_promo_code`;

    return this._http
      .post<void>(url, promoCodeForm)
      .toPromise();
  }

  async updateStatus(): Promise<void> {
    const url = `${this._URL}/update_status`;
    return this._http
      .post<void>(url, null)
      .toPromise();
  }

}
