import {Params} from '@angular/router';
import { TokenRestorationType } from './token-restoration-type.enum';

export class TokenRestorationQueryParams {
	type: TokenRestorationType;
	token: string;
	uid: string;


	constructor(params: Params) {
		this.type = params['type'];
		this.token = params['token'];
		this.uid = params['uid'];
	}


	get validForPassword(): boolean {
		return this._valid && (this.type === TokenRestorationType.RESTORE_PASS || this.type === TokenRestorationType.ADDITIONAL_USER);
	}

	get validForEmailVerification(): boolean {
		return this._valid && (this.type === TokenRestorationType.EMAIL_VERIFICATION);
	}


	private get _valid(): boolean {
		return !!this.token &&
		!!this.uid &&
		!!this.type;
	}
}
