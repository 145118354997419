<div class="popup_container">
  <h3 class="title">
    {{data[1]}} {{data[0]}}?
  </h3>

  <div class="confirm-input">
    <input type="text" [(ngModel)]="input">
    <div class="confirm-input__hint">
      please type '{{REQUIRED_INPUT_TEXT}}' to confirm this action
    </div>
  </div>

  <div class="form-item" *ngIf="isArchive">
    <mat-select
      placeholder="Select a Reason"
      class="dropdown-up"
      [(ngModel)]='archived_reason'
    >
      <mat-option
        *ngFor="let archive of ArchiveReasons |keyvalue"
        [value]="archive.value"
      >
        {{archive.value}}
      </mat-option>
    </mat-select>
    <!-- <mat-error *ngIf="!archived_reason">Please select any reason.</mat-error> -->
  </div>

  <div class="buttons">
    <button
      class="primary"
      [disabled]="!isInputCorrect"
      (click)="confirmDelete()"
    >
      {{data[1]}}
    </button>

    <button
      (click)="declineDelete()"
    >
      Cancel
    </button>
  </div>
</div>