import {ActiveTourPointer} from '../../../site-tour/models/active-tour-pointer.model';

export class NavigationStep<T extends string = string> {
  readonly title: T;
  readonly percentage: number;
  current: boolean;
  completed: boolean;
  error: boolean;
  dataAttributesArray: ActiveTourPointer[] = null;

  constructor(title: T, percentage: number, current = false, completed = false, error = false, dataAttributesArray = null) {
    this.title = title;
    this.percentage = percentage;
    this.current = current;
    this.completed = completed;
    this.error = error;
    this.dataAttributesArray = dataAttributesArray;
  }

  get isStepActive(): boolean {
    return this.current;
  }

  get dataAttr(): string {
    return this.dataAttributesArray ?
      this.dataAttributesArray.map( item => item.type + item.index ).join(' ') : null;
  }

  get isStepCompleted(): boolean {
    return this.completed && !this.current && !this.error;
  }

  get hasError(): boolean {
    return this.error;
  }
}
