import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconButtonComponent } from './icon-button.component';
import {MatButtonModule, MatIconModule} from '@angular/material';

@NgModule({
  declarations: [IconButtonComponent],
  exports: [IconButtonComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class IconButtonModule { }
