import { AmountPercents } from '../../../shared/models/product/enums/amount-percents.enum';
import {MinimumPalletLoadPercents} from '../../../shared/models/product/enums/minimum-pallet-load-percents.enum';
import {MyIsNotEmpty, MyMin} from '../../../shared/modules/form-validation/decorators';

export class ProductsSettingsForm {
  // @MyIsNotEmpty()
  // minimum_pallet_load_percents: MinimumPalletLoadPercents = MinimumPalletLoadPercents.PER_50;

  // specsheets_access_by_request = false;
  all_products_for_mixed_pallet = false;
  charge_per_pallet = 0;
  // for_all_products =  false;
  // for_selected_products = [];


  @MyIsNotEmpty()
  mixed_pallet_charge_per_case = 0;

  @MyIsNotEmpty()
  mixed_pallet_charge_per_case_type: AmountPercents = AmountPercents.AMOUNT;
  
  // @MyIsNotEmpty()
  // @MyMin(1)
  // min_order_quantity = 1;

  // @MyIsNotEmpty()
  // @MyMin(1)
  // mixed_pallet_max_weight = 2700;

  // @MyIsNotEmpty()
  // @MyMin(1)
  // mixed_pallet_max_height = 78;

  // min_order_weight = 0;
}
