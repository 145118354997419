import {TourActionType} from '../models/tour-action.enum';
import {TourTypes} from '../models/tour-types';
import {plainToClass} from 'class-transformer';
import {TourModel} from '../models/tour.model';
import {TourStatusesEnum} from '../models/tour-statuses.enum';

export const addProductRestrictionsTour = plainToClass(TourModel, {
  type: TourTypes.ADD_PRODUCT_RESTRICTIONS,
  status: TourStatusesEnum.BLOCKED,
  title: 'How to restrict a Product',
  unlockConditions: 'Please add at least one Product',
  list: [
    {
      action: TourActionType.HIGHLIGHT,
      description: 'To start adding the product go to Products tab',
      element: TourTypes.ADD_PRODUCT_RESTRICTIONS + '0',
      title: 'How to restrict a Product',
      isElementFixed: true
    },
    {
      action: TourActionType.HIGHLIGHT,
      description: 'Edit one of your added products',
      element: TourTypes.ADD_PRODUCT_RESTRICTIONS + '1',
      title: 'How to restrict a Product',
      isElementFixed: false
    },
    {
      action: TourActionType.TIP,
      description: 'Here you can change the Group restriction type',
      element: TourTypes.ADD_PRODUCT_RESTRICTIONS + '2',
      title: 'How to restrict a Product',
      isElementFixed: false,
      isProbablyFixed: true
    },
    {
      action: TourActionType.TIP,
      description: 'Set user groups for restriction',
      element: TourTypes.ADD_PRODUCT_RESTRICTIONS + '3',
      title: 'How to restrict a Product',
      isElementFixed: false,
      isProbablyFixed: true
    },
    {
      action: TourActionType.TIP,
      description: 'Set restricted states',
      element: TourTypes.ADD_PRODUCT_RESTRICTIONS + '4',
      title: 'How to restrict a Product',
      isElementFixed: false,
      isProbablyFixed: true
    },
    {
      action: TourActionType.TIP,
      description: 'Or restrict product by zip-codes range',
      element: TourTypes.ADD_PRODUCT_RESTRICTIONS + '5',
      title: 'How to restrict a Product',
      isElementFixed: false,
      isProbablyFixed: true
    }
  ]
});
