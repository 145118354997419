import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SiteTourComponent} from './site-tour.component';
import {TourTooltipComponent} from './tour-tooltip/tour-tooltip.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    SiteTourComponent,
    TourTooltipComponent
  ],
  exports: [
    SiteTourComponent
  ]
})
export class SiteTourModule { }
