import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {ApiService} from '../../../../shared/services/api';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {TransactionList} from '../models/transaction-list';

@Injectable()
export class TransactionsService {

  private readonly _URL: string;


  constructor(
    private _http: HttpClient,
    private _api: ApiService
  ) {
    this._URL = `${_api.API}vendor/transactions`;
  }


  async getTransactionsList(page = 1): Promise<TransactionList> {
    const params = new HttpParams().set('page', page.toString());

    return this._http
      .get<TransactionList>(this._URL, {params})
      .pipe(
        map(transactions => plainToClass(TransactionList, transactions))
      )
      .toPromise();
  }

  async downloadTransactionPdfBlob(chargeNumber: number): Promise<HttpResponse<Blob>> {
    const url = `${this._URL}/${chargeNumber}/pdf`;

    return this._http
      .get<Blob>(url, {
        responseType: 'blob' as 'json',
        observe: 'response'
      })
      .toPromise();
  }
}
