import {Id} from '../../../../shared/models/types';
import {Type} from 'class-transformer';
import {decoratorType} from 'lib-shared';
import {CouponRequestForm} from './coupon-request.form';
import {thresholdTypes} from '../threshold-types';
import {ProductListItem} from '../../../../shared/models/product/product-list/product-list-item';

export class Coupon extends CouponRequestForm {
  id: Id;

  code: string;

  @Type(decoratorType(Date))
  created: Date;

  get productList(): ProductListItem[] {
    return this.products as any;
  }

  get isApplicableForAllProducts(): boolean {
    return this.products && this.products.length === 0;
  }

  get threshold(): string {
    if(!!this.quantity_threshold && !!this.quantity_threshold_type) {
      const type = Array.from(thresholdTypes.entries()).find(([key, value]) => value === this.quantity_threshold_type)[0];
      return `${this.quantity_threshold} ${type}`;
    } else {
      return 'No threshold';
    }
  }
}
