<div
  class="add-payment-block"
>
  <app-card>
    <div
      class="inner-container"
      [attr.data-tour]="TourTypes.ADD_PAYMENT_CARD + '3 ' + TourTypes.GO_LIVE + '3'"
    >
      <div
        *ngIf="showSpinner"
        class="spinner-container"
      >
        <mat-spinner></mat-spinner>
      </div>

      <div class="form">
        <div
          class="card-number"
          [id]="cardIds[StripeElementType.CARD_NUMBER]"
          [class.invalid]="cardErrors[StripeElementType.CARD_NUMBER]"
        ></div>

        <div class="inputs-row">
          <div
            class="card-expiration"
            [id]="cardIds[StripeElementType.CARD_EXPIRY]"
            [class.invalid]="cardErrors[StripeElementType.CARD_EXPIRY]"
          ></div>
          <div
            class="card-cvc"
            [id]="cardIds[StripeElementType.CARD_CVC]"
            [class.invalid]="cardErrors[StripeElementType.CARD_CVC]"
          ></div>
        </div>
      </div>

      <div class="buttons">
        <app-button
          [color]="ButtonColorScheme.WHITE"
          (click)="closeAdding()"
          [attr.data-tour]="'CANCEL'"
        >
          Cancel
        </app-button>
        <app-button
          [color]="ButtonColorScheme.WHITE"
          (click)="addCard()"
        >
          Add card
        </app-button>
      </div>
    </div>
  </app-card>

  <div
    *ngIf="messages.hasMessages"
    class="messages"
  >
    <app-message-list
      [messages]="messages"
      [style]="messagesStyle"
    ></app-message-list>
  </div>
</div>
