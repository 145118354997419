import {Component, EventEmitter, Output} from '@angular/core';
import {ButtonColorScheme} from '../../../ux/button/models/button-color-scheme.enum';
import {Router} from '@angular/router';

@Component({
  selector: 'app-terms-of-use-finish',
  templateUrl: './terms-of-use-finish.component.html',
  styleUrls: ['./terms-of-use-finish.component.scss']
})
export class TermsOfUseFinishComponent {

  @Output()
  onCloseModal = new EventEmitter();


  readonly ButtonColorScheme = ButtonColorScheme;


  constructor(
    private _router: Router
  ) {}


  closeModal(): void {
    this.onCloseModal.emit();
  }

  navigateToRegistration(): void {
    this._router.navigate(['registration']);
    this.closeModal();
  }
}
