import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CorrectTickComponent } from './correct-tick.component';
import {MatIconModule} from '@angular/material';

@NgModule({
  declarations: [CorrectTickComponent],
  exports: [CorrectTickComponent],
  imports: [
    CommonModule,
    MatIconModule
  ]
})
export class CorrectTickModule { }
