<h3 class="title">
  {{data.msg}}
</h3>

<div class="buttons">
  <button

    (click)="dialogRef.close(true)"
  >
    {{data.buttons[0]}}
  </button>
  <button

    (click)="dialogRef.close(false)"
  >
    {{data.buttons[1]}}
  </button>
</div>
