import {Type} from 'class-transformer';
import {decoratorType} from 'lib-shared';
import {PaginationList} from './pagination-list';
import {ProductListItem} from './product-list-item';


export class ProductList extends PaginationList<ProductListItem> {
  @Type(decoratorType(ProductListItem))
  results: ProductListItem[];
}
