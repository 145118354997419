import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {environment} from '../../../environments/environment';

declare let ga: Function;

@Injectable()
export class GoogleAnalyticsEventsService {

  private GAAccoubtCode = 'UA-104225365-1';

  constructor(
    private router: Router
  ) {
    if(environment.enableGA) {
      this.initGA();
      this.setupGoogleAnalyticsPageTracker();
    }
  }



  public sendEvent(
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null
  ): void {
    if(environment.enableGA) {
      ga('send', {
        hitType: 'event',
        eventCategory,
        eventAction,
        eventLabel,
        eventValue
      });
    }
  }

  public trackPage(pageUrl: string): void {
    if(environment.enableGA) {
      ga('set', 'page', pageUrl);
      ga('send', 'pageview');
    }
  }



  private initGA(): void {
    ga('create', this.GAAccoubtCode, 'auto');
  }

  private setupGoogleAnalyticsPageTracker() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.trackPage('/vendor/' + event.urlAfterRedirects);
      }
    });
  }
}
