import {ExtendedIValidationErrors, IValidationErrors} from './models/form-errors.interface';
import {BehaviorSubject} from 'rxjs';
import {DynamicFormGroup} from 'ngx-dynamic-form-builder';
import {ObservableComponent} from 'lib-shared';

export class FormErrors extends ObservableComponent {
  errors: BehaviorSubject<IValidationErrors>;

  private readonly _form: DynamicFormGroup<any>;


  constructor(form: DynamicFormGroup<any>) {
    super();
    this.errors = new BehaviorSubject<IValidationErrors>({});
    this._form = form;
    this._subscribeOnFormErrors();
  }


  setErrorsFromBackend(externalErrors: ExtendedIValidationErrors) {
    const errors: IValidationErrors = {};

    if (externalErrors && typeof externalErrors === 'object') {
      Object
        .keys(externalErrors)
        .forEach(key => {
          let keyError = externalErrors[key];

          if(typeof keyError === 'string') {
            keyError = [keyError];
          }

          if(typeof keyError === 'object' && !Array.isArray(keyError)) {
            this.setErrorsFromBackend(keyError);
          }

          if(this._form.controls.hasOwnProperty(key)) {
            this._form.controls[key].markAsTouched();
          }
          errors[key] = keyError;
        });
    }

    this.errors.next(errors);
  }


  private _subscribeOnFormErrors(): void {
    this._form
      .customValidateErrors
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(errors => this.errors.next(errors));
  }

}
