import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Payment} from '../../shared/models/payment';
import {MatIconEnum} from '../../../../../models/common/mat-icon.enum';

@Component({
  selector: 'app-payment-item',
  templateUrl: './payment-item.component.html',
  styleUrls: ['./payment-item.component.scss']
})
export class PaymentItemComponent {

  @Input()
  payment: Payment;

  @Input()
  widgetMode = false;

  @Output()
  onSetDefault = new EventEmitter<void>();

  @Output()
  onDelete = new EventEmitter<void>();

  @Output()
  onChange = new EventEmitter<void>();

  readonly MatIconEnum = MatIconEnum;


  setPaymentAsDefault(): void {
    this.onSetDefault.emit();
  }

  deletePayment(): void {
    this.onDelete.emit();
  }

  changePayment(): void {
    this.onChange.emit();
  }

}
