import { Component, OnInit } from '@angular/core';
import {FormGroup, FormBuilder, Validators} from "@angular/forms";
import {VideoConverterService} from "./services/video-constructor.service";

@Component({
  selector: 'app-video-constructor',
  templateUrl: './video-constructor.component.html',
  styleUrls: ['./video-constructor.component.scss']
})
export class VideoConstructorComponent implements OnInit {

  form: FormGroup | any;
  picture_3_visible: boolean;
  picture_4_visible: boolean;
  picture_5_visible: boolean;
  add_picture_button_visible: boolean;

  video_3_visible: boolean;
  video_4_visible: boolean;
  video_5_visible: boolean;
  add_video_button_visible: boolean;
  error: string;
  progress: string;

  submit_disabled: boolean;

  constructor(
      private fb: FormBuilder,
      private uploadServise: VideoConverterService
  ) { }

  ngOnInit() {
    this.createForm();
    this.resetButtons();
  }

  private resetButtons() {
    this.picture_3_visible = false;
    this.picture_4_visible = false;
    this.picture_5_visible = false;
    this.add_picture_button_visible = true;
    this.video_3_visible = false;
    this.video_4_visible = false;
    this.video_5_visible = false;
    this.add_video_button_visible = true;
  }

  private createForm(): void {
    this.form = this.fb.group(
        {
          title_screen: ['', Validators.compose([Validators.required])],
          picture_1: ['', Validators.compose([Validators.required])],
          picture_2: [''],
          picture_3: [''],
          picture_4: [''],
          picture_5: [''],
          pictures_audio: [''],
          video_1: ['', Validators.compose([Validators.required])],
          audio_1: [''],
          video_2: [''],
          audio_2: [''],
          video_3: [''],
          audio_3: [''],
          video_4: [''],
          audio_4: [''],
          video_5: [''],
          audio_5: [''],
          background_music: [''],
        });
  }

  addPicture() {
      console.log(this.form.get('picture_1').value);
    if (!this.picture_3_visible) {
        this.picture_3_visible = true;
        return;
    }
    if (!this.picture_4_visible) {
        this.picture_4_visible = true;
        return;
    }
    if (!this.picture_5_visible) {
        this.picture_5_visible = true;
        this.add_picture_button_visible = false;
        return;
    }
  }

  addVideo() {
      if (!this.video_3_visible) {
          this.video_3_visible = true;
          return;
      }
      if (!this.video_4_visible) {
          this.video_4_visible = true;
          return;
      }
      if (!this.video_5_visible) {
          this.video_5_visible = true;
          this.add_video_button_visible = false;
          return;
      }
  }

    fileUploaded(event: any) {
        this.form.get(event.srcElement.id).patchValue(event.srcElement.files[0]);
    }

    uploadVideos() {
        this.error = '';
        if (!this.progress) {
            this.progress = 'Please wait...';
            this.submit_disabled = true;
            let formData: FormData = new FormData();
            formData.append('title_screen', this.form.get('title_screen').value);
            if (this.form.get('picture_1').value)
                formData.append('picture_1', this.form.get('picture_1').value, this.form.get('picture_1').value.name);
            if (this.form.get('picture_2').value)
                formData.append('picture_2', this.form.get('picture_2').value, this.form.get('picture_2').value.name);
            if (this.form.get('picture_3').value)
                formData.append('picture_1', this.form.get('picture_3').value, this.form.get('picture_3').value.name);
            if (this.form.get('picture_4').value)
                formData.append('picture_4', this.form.get('picture_4').value, this.form.get('picture_4').value.name);
            if (this.form.get('picture_5').value)
                formData.append('picture_5', this.form.get('picture_5').value, this.form.get('picture_5').value.name);
            if (this.form.get('audio_1').value)
                formData.append('audio_1', this.form.get('audio_1').value, this.form.get('audio_1').value.name);
            if (this.form.get('audio_2').value)
                formData.append('audio_2', this.form.get('audio_2').value, this.form.get('audio_2').value.name);
            if (this.form.get('audio_3').value)
                formData.append('audio_3', this.form.get('audio_3').value, this.form.get('audio_3').value.name);
            if (this.form.get('audio_4').value)
                formData.append('audio_4', this.form.get('audio_4').value, this.form.get('audio_4').value.name);
            if (this.form.get('audio_5').value)
                formData.append('audio_5', this.form.get('audio_5').value, this.form.get('audio_5').value.name);
            if (this.form.get('video_1').value)
                formData.append('video_1', this.form.get('video_1').value, this.form.get('video_1').value.name);
            if (this.form.get('video_2').value)
                formData.append('video_2', this.form.get('video_2').value, this.form.get('video_2').value.name);
            if (this.form.get('video_3').value)
                formData.append('video_3', this.form.get('video_3').value, this.form.get('video_3').value.name);
            if (this.form.get('video_4').value)
                formData.append('video_4', this.form.get('video_4').value, this.form.get('video_4').value.name);
            if (this.form.get('video_5').value)
                formData.append('video_5', this.form.get('video_5').value, this.form.get('video_5').value.name);
            if (this.form.get('pictures_audio').value)
                formData.append('pictures_audio', this.form.get('pictures_audio').value, this.form.get('pictures_audio').value.name);
            if (this.form.get('background_music').value)
                formData.append('background_music', this.form.get('background_music').value, this.form.get('background_music').value.name);

            let upVideoRequest = new XMLHttpRequest();
            upVideoRequest.open('POST', this.uploadServise.getUploadVideoURL(), true);
            upVideoRequest.withCredentials = true;
            upVideoRequest.responseType = 'blob';
            upVideoRequest.onload = upVideoEvent => {
                //console.log(upVideoEvent);
                //console.log(upVideoRequest);
                //if (upVideoRequest.status === 200) {
                    let blob = new Blob([upVideoRequest.response], {type: "video/mp4"});
                    let a = document.createElement("a");
                    document.body.appendChild(a);
                    let url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = 'video.mp4';
                    a.click();
                    window.URL.revokeObjectURL(url);
                //} else {
                //   this.error = 'Error ' + upVideoEvent.status;
                //}
                this.progress = null;
                this.submit_disabled = false;
                this.resetButtons();
            };
            upVideoRequest.onerror = (error: any) => {
                //console.log('Error', error);
                this.progress = null;
                this.submit_disabled = false;
                this.error = !!error.message ? error.message : error;
            };
            upVideoRequest.upload.onprogress = event => {
                //console.log('Progress', event);
                let percent = Math.round(100/(event.total/event.loaded));
                this.progress = `Upload in progress! ${ percent }% loaded, please wait for conversion...`;
            };
            upVideoRequest.send(formData);
        }
    }

}
