import {UrlPath} from '../models/types';

export function fileNameDisplay(file: UrlPath | File, maxNameLength = 25): string {
  const i = file.toString().lastIndexOf('/');
  const name = file.toString().slice(i + 1);

  if (name.length > maxNameLength) {
    const fileExtensionLength = 4;
    const ellipsis = '...';

    return name.substr(0, maxNameLength - (fileExtensionLength + ellipsis.length)) +
      ellipsis +
      name.substr(name.length - fileExtensionLength, name.length);
  }

  return name;
}
