export enum TourActionType {
  HIGHLIGHT = 'HIGHLIGHT',
  FORM = 'FORM',
  CLICK = 'CLICK',
  TIP = 'TIP'
}

// HIGHLIGHT, CLICK - highlight element area and add click listener

// FORM - highlight form area and wait for submit

// TIP - tooltip, that can bee skipped by Next button
