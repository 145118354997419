<app-card
  class="payment-card"
  [blueBck]="payment.is_default && !widgetMode"
  [class.default]="payment.is_default && !widgetMode"
>
  <div class="inner-container">
    <div class="header">
      <img
        class="card-logo"
        [src]="payment.logoImagePath"
        alt="logo"
      >

      <div class="default-block">
        <a
          *ngIf="!payment.is_default && !widgetMode"
          class="link"
          (click)="setPaymentAsDefault()"
        >
          Set as default
        </a>
        <span
          class="default"
          *ngIf="payment.is_default && !widgetMode"
        >
          DEFAULT
        </span>
        <a
          *ngIf="widgetMode"
          class="link"
          (click)="changePayment()"
        >
          Change
        </a>
      </div>
    </div>
    <div class="body">
      <div class="digits">
        <span>****</span>
        <span>****</span>
        <span>****</span>
        <span>{{ payment.four_digits }}</span>
      </div>
      <div class="expires">
        Expired on
        <span class="date">{{ payment.month }}/{{ payment.year }}</span>
      </div>
    </div>
    <div
      *ngIf="!widgetMode"
      class="delete-icon"
    >
      <app-icon-button
        [width]="14"
        [height]="19"
        [matIcon]="MatIconEnum.BIN"
        (click)="deletePayment()"
      ></app-icon-button>
    </div>
  </div>
</app-card>
