import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import {NotificationModule} from '../notification/notification.module';
import {ClickOutsideModule} from 'ng-click-outside';
import {
  MatBadgeModule,
  MatIconModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatSnackBarModule
} from '@angular/material';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../../shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatTooltipModule} from '@angular/material/tooltip';
import {InventoryService} from '../../../services/inventory.service';
import { CopyToClipboardModule } from '../../ux/copy-to-clipboard/copy-to-clipboard.module';
import { IconButtonModule } from '../../ux/icon-button/icon-button.module';

@NgModule({
  imports: [
    CommonModule,
    NotificationModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatMenuModule,
    MatRippleModule,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    MatIconModule,
    RouterModule,
    SharedModule,
    MatTooltipModule,
    MatSnackBarModule,
    CopyToClipboardModule,
    IconButtonModule
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
  providers: [InventoryService]
})
export class HeaderModule { }
