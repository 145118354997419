import {Id} from '../../../types';
import { FilterQuery } from '../../../../modules/blocks/filters/shared/models/filter-query';

export class FilteredCheckedProductsRequest {
  category: string[];
  date_from: string[];
  date_to: string[];
  fob: string[];
  status: string[];
  special_price: boolean;
  promo: boolean;
  haggle_rewards: boolean;
  search: string;

  constructor(filtersQuery: FilterQuery = new FilterQuery(), search: string) {
    if(filtersQuery!=null) {
      if(filtersQuery.query.category != null && filtersQuery.query.category.filter(item => item).length > 0){
        this.category = filtersQuery.query.category.filter(item=>item);
      }
      if(filtersQuery.query.date_from != null && filtersQuery.query.date_from.filter(item => item).length > 0){
        this.date_from = filtersQuery.query.date_from.filter(item=>item);
      }
      if(filtersQuery.query.date_to != null && filtersQuery.query.date_to.filter(item => item).length > 0){
        this.date_to = filtersQuery.query.date_to.filter(item=>item);
      }
      if(filtersQuery.query.fob != null && filtersQuery.query.fob.filter(item => item).length > 0){
        this.fob = filtersQuery.query.fob.filter(item=>item);
      }
      if(filtersQuery.query.status != null && filtersQuery.query.status.filter(item => item).length > 0){
        this.status = filtersQuery.query.status.filter(item=>item);
      }
      if(filtersQuery.query.special_price != null && filtersQuery.query.special_price.filter(item => item).length > 0){
        this.special_price = Boolean(Number(filtersQuery.query.special_price.filter(item=>item)[0]));
      }
      if(filtersQuery.query.promo != null && filtersQuery.query.promo.filter(item => item).length > 0){
        this.promo = Boolean(Number(filtersQuery.query.promo.filter(item=>item)[0]));
      }
      if(filtersQuery.query.haggle_rewards != null && filtersQuery.query.haggle_rewards.filter(item => item).length > 0){
        this.haggle_rewards = Boolean(Number(filtersQuery.query.haggle_rewards.filter(item=>item)[0]))
      }
    }
    if (search != null){
      this.search = search;
    }
  }
}
