import {VendorCertificate} from './certificate';
import {Type} from 'class-transformer';
import {decoratorType} from 'lib-shared';
import {CertificateTab} from './certificate-tab.enum';
import {CertificateGroup} from './certificates-group.class';

export class CertificatesList {
  @Type(decoratorType(VendorCertificate))
  COMPANY: VendorCertificate[];

  @Type(decoratorType(VendorCertificate))
  PRODUCT: VendorCertificate[];

  @Type(decoratorType(VendorCertificate))
  K12: VendorCertificate[];

  @Type(decoratorType(VendorCertificate))
  CUSTOM: VendorCertificate[];


  getCertificateGroups(tab: CertificateTab): CertificateGroup[] {
    const groups: CertificateGroup[] = [];

    this[tab].forEach((certificate: VendorCertificate) => {
      const groupIndex = groups.findIndex(group => group.groupName === certificate.group);

      if(groupIndex >= 0) {
        groups[groupIndex].items.push(certificate);
      } else {
        groups.push(new CertificateGroup(certificate.group, [certificate]));
      }
    });

    return groups;
  }
}
