export enum DateCodeType {
  BEST_BY = 'BEST BY',
  USE_BY = 'USE BY',
  SELL_BY = 'SELL BY',
  FREEZE_BY = 'FREEZE BY',
  SELL_BY_FREEZE_BY = 'SELL BY/FREEZE BY',
  USE_BY_FREEZE_BY = 'USE BY/FREEZE BY',
  JULIAN_CODE = 'JULIAN CODE',
  OTHER = 'OTHER',
  BOX_CARTON_CODE = 'BOX/CARTON CODE',
  BAG_CODING_CASE_USE_BY = 'BAG CODING/CASE USE BY',
  EXP = 'EXP',
  BEST_IS_USED_BY_BEFORE = 'BEST IS USED BY/BEFORE',
  MFG_DATE = 'MFG DATE',
  USE_BY_CALIFORNIA_ONLY_SELL_BY = 'USE BY/CALIFORNIA ONLY:SELL BY',
  BEST_BEFORE = 'BEST BEFORE',
  BEST_IF_USED_BY_BEFORE = 'BEST IF USED BY/BEFORE',
}
