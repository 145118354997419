import {HoleParamsModel} from './hole-params.model';

export class DamperModel extends HoleParamsModel {

  background = 'rgba(0,0,0,0.7)';

  constructor(width, height, top, left) {
    super(width, height, top, left);
  }

}
