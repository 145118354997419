<h3 class="title">
  {{data[0]}}
</h3>

<div class="buttons">
  <button
    (click)="dialogRef.close()"
  >
    Ok
  </button>
</div>
