import {TourActionType} from '../models/tour-action.enum';
import {TourTypes} from '../models/tour-types';
import {plainToClass} from 'class-transformer';
import {TourModel} from '../models/tour.model';
import {TourStatusesEnum} from '../models/tour-statuses.enum';

export const addPaymentCardTour = plainToClass(TourModel, {
  type: TourTypes.ADD_PAYMENT_CARD,
  status: TourStatusesEnum.BLOCKED,
  title: 'How to add Payment Card',
  unlockConditions: 'Please ensure to have Company Profile permission',
  list: [
    {
      action: TourActionType.HIGHLIGHT,
      description: 'To start adding the payment cards go to Company Profile',
      element: TourTypes.ADD_PAYMENT_CARD + '0',
      title: 'How to add payment card',
      isElementFixed: true
    },
    {
      action: TourActionType.HIGHLIGHT,
      description: 'Then go to Payments tab',
      element: TourTypes.ADD_PAYMENT_CARD + '1',
      title: 'How to add payment card',
      isElementFixed: false,
      isChild: true,
      childLevel: 2
    },
    {
      action: TourActionType.HIGHLIGHT,
      description: 'Lets create new payment card',
      element: TourTypes.ADD_PAYMENT_CARD + '2',
      title: 'How to add payment card',
      isElementFixed: false
    },
    {
      action: TourActionType.FORM,
      description: 'Then fill the form and press the "Add card" button',
      element: TourTypes.ADD_PAYMENT_CARD + '3',
      title: 'How to add payment card',
      isElementFixed: false,
      cancelElement: 'CANCEL'
    },
    {
      action: TourActionType.HIGHLIGHT,
      description: 'Here is displaying all added payment cards',
      element: TourTypes.ADD_PAYMENT_CARD + '4',
      title: 'How to add payment card',
      isElementFixed: false
    }
  ]
});
