import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddPaymentComponent } from './add-payment/add-payment.component';
import {NgxStripeModule} from 'ngx-stripe';
import {environment} from '../../../../../environments/environment';
import {CardModule} from '../../ux/card/card.module';
import {ButtonModule} from '../../ux/button/button.module';
import {PaymentsService} from './shared/services/payments.service';
import { PaymentsListComponent } from './payments-list/payments-list.component';
import { PaymentItemComponent } from './payments-list/payment-item/payment-item.component';
import {IconButtonModule} from '../../ux/icon-button/icon-button.module';
import {MatIconModule, MatProgressSpinnerModule, MatRadioModule} from '@angular/material';
import { PaymentsWidgetComponent } from './payments-widget/payments-widget.component';
import { WidgetPaymentsListComponent } from './payments-widget/widget-payments-list/widget-payments-list.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MessageListModule} from '../../ux/message-list/message-list.module';

@NgModule({
  declarations: [AddPaymentComponent, PaymentsListComponent, PaymentItemComponent, PaymentsWidgetComponent, WidgetPaymentsListComponent],
  exports: [PaymentsListComponent, PaymentsWidgetComponent],
  imports: [
    CommonModule,
    NgxStripeModule.forRoot(environment.stripeKey),
    CardModule,
    ButtonModule,
    IconButtonModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MessageListModule
  ],
})
export class PaymentsModule { }
