import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-total-amount',
  templateUrl: './total-amount.component.html',
  styleUrls: ['./total-amount.component.scss']
})
export class TotalAmountComponent {

  @Input()
  amount: number;

  @Input()
  title: string;

  get totalAmount(): string {
    return !!this.amount
      ? `$${this.amount}.00`
      : '$0.00';
  }
}
