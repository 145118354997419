import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';

@Injectable()
export class SpinnerService {
  spinnerStatus = new BehaviorSubject(false);

  showSpinner(): void {
    this.spinnerStatus.next(true);
  }

  hideSpinner(): void {
    this.spinnerStatus.next(false);
  }

  get spinnerStatusObservable(): Observable<boolean> {
    return this.spinnerStatus.asObservable();
  }

}
