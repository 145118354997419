import {Injectable} from '@angular/core';
import {ApiService} from './api';
import {HttpClient} from '@angular/common/http';
import {AccessStatus} from '../../certification-request/models/access-status.enum';
import { CertificateRequestForm } from '../../certification-request/models/certificate-request.form';

@Injectable()
export class CertificationRequestService {

  private _URL: string;


  constructor(
    private _http: HttpClient,
    private _api: ApiService
  ) {
    this._URL = `${_api.API}vendor/access_request`;
  }

  async getCertificateName(documene_access_id: string): Promise<string>{
    const url = `${this._URL}/${documene_access_id}/certificate_name`;
    const response = this._http.get<{ certificate_name: string; }>(url).toPromise();
    return (await response).certificate_name;
  }

  async sendCertificateRequestStatus(token: string, status: AccessStatus): Promise<void> {
    const url = `${this._URL}/${token}/${status}`;

    return this._http
      .post<void>(url, null)
      .toPromise();
  }

  async saveVendorDeclinedReason(token: string, certificateRequest: CertificateRequestForm): Promise<void>{
    const url = `${this._URL}/${token}/decline`
    return this._http
        .post<void>(url, certificateRequest).toPromise();
    }

}
