import { MatDialog } from "@angular/material";
import { Id } from "lib-shared/shared/models/types";
import { ApiHttpService } from "../../../core/ApiHttp/api-http.service";
import { plainToClassSetDefaults } from "../../helpers/plain-to-class-defaults";
import { StringifyCircularObject } from "../../helpers/StringifyCircularObject";
import { ApiService} from '../api';
import {map} from 'rxjs/internal/operators';
import { OrderCaseQuantityForm } from "../../models/product/product-list/order-case-quantity.form";

export class OrderCaseQuantityService  {
      
    private readonly _URL: string;

    constructor(
        private api: ApiService,
        private http: ApiHttpService,
        private dialog: MatDialog

    ) {
        this._URL ='vendor/order_case_quantity_setting';
    }
   
    getOrderCaseQuantity(): Promise<OrderCaseQuantityForm[]> {
        return this.http.get<OrderCaseQuantityForm[]>(this._URL).toPromise();
    }

    async editOrderCaseQuantity(ordercasequantity: OrderCaseQuantityForm | any): Promise<OrderCaseQuantityForm> {
        const url = `${this._URL}/${ordercasequantity.id}`;
    
        const productProcessed = JSON.parse( StringifyCircularObject(ordercasequantity) );
        
        return this.http
            .put<OrderCaseQuantityForm>(url, productProcessed)
            .pipe(
                map(newOrder => plainToClassSetDefaults(OrderCaseQuantityForm, newOrder))
                )
            .toPromise();
    }
    
        
    async addOrderCaseQuantity(ordercasequantity: OrderCaseQuantityForm): Promise<OrderCaseQuantityForm> {
    return this.http
        .post<OrderCaseQuantityForm>(this._URL, ordercasequantity)
        .pipe(
            map(newOrderCaseQuantity => plainToClassSetDefaults(OrderCaseQuantityForm, newOrderCaseQuantity))).
            toPromise();
    }

    async deleteOrderCaseQuantity(id: Id): Promise<void> {
        const url = `${this._URL}/${id}`;

        return this.http
            .delete<void>(url).toPromise();
    }   
}