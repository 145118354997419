import {Injectable} from '@angular/core';
import {ApiService} from '../../shared/services/api';
import {HttpClient} from '@angular/common/http';
import {ChangePassword} from '../../shared/models/change-password';


@Injectable()
export class PasswordService {

  private readonly _URL: string;

  constructor(
    private _api: ApiService,
    private _http: HttpClient
  ) {
    this._URL = `${_api.API}change_password`;
  }


  async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    const data = new ChangePassword(oldPassword, newPassword);

    return this._http
      .post<void>(this._URL, data)
      .toPromise();
  }

}
