export class RoutingHistoryPage {
  url: string;
  queryParams: Object;

  private _fullUrl: string;


  constructor(fullUrl: string) {
    this._fullUrl = fullUrl;

    const QUERY_PARAMS_REGEX = /\?.*$/;
    const urlWithoutQueryParams = fullUrl.replace(QUERY_PARAMS_REGEX, '');
    const queryParams = !!fullUrl.match(QUERY_PARAMS_REGEX)
      ? fullUrl.match(QUERY_PARAMS_REGEX)[0]
      : null;

    if(queryParams) {
      const params = queryParams.substring(1);
      const jsonParams = '{"' + params.replace(/&/g, '","').replace(/=/g,'":"') + '"}';
      this.queryParams = JSON.parse(jsonParams.replace( ',""', '' ), (key, value) => key === '' ? value : decodeURIComponent(value));
    } else {
      this.queryParams = null;
    }

    this.url = urlWithoutQueryParams;
  }


  get fullUrl(): string {
    return this._fullUrl;
  }
}
