import {
  AfterViewInit,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  Optional,
  Self,
  ViewContainerRef
} from '@angular/core';
import {NgControl} from '@angular/forms';
import {AreYouSureHintComponent} from './are-you-sure-hint/are-you-sure-hint.component';
import {ObservableComponent} from 'lib-shared';

@Directive({
  selector: '[appAreYouSure]'
})
export class AreYouSureInputDirective extends ObservableComponent implements AfterViewInit {

  @Input()
  minQty: number;

  private _hintComponent: ComponentRef<AreYouSureHintComponent>;

  constructor(
    @Optional() @Self() private _control: NgControl,
    private _vcr: ViewContainerRef,
    private _resolver: ComponentFactoryResolver,
  ) {
    super();
  }


  ngAfterViewInit(): void {
    this._init();
  }


  private _init(): void {
    this._addHintElement();
    this._subscribeOnControlValueChanges();
  }

  private _addHintElement(): void {
    const factory = this._resolver.resolveComponentFactory(AreYouSureHintComponent);
    this._hintComponent = this._vcr.createComponent(factory);
  }

  private _subscribeOnControlValueChanges(): void {
    this._control
      .valueChanges
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(value => this._hintComponent.instance.showHint = (+value < this.minQty) && this._control.valid);
  }

}
