import { Injectable } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs/Rx';
import {filter, map, mergeMap} from 'rxjs/internal/operators';

@Injectable()
export class SideMenuService {

  private _isMenuOpened = new BehaviorSubject<boolean>(true);



  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
  ) {}



  get sideMenuShowObservable(): Observable<boolean> {
    return this._router
      .events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this._activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
        map(event => event.isShowMenu)
      );
  }

  get sideMenuOpenedObservable(): Observable<boolean> {
    return this._isMenuOpened.asObservable();
  }

  set sideMenuOpened(opened: boolean) {
    this._isMenuOpened.next(opened);
  }
}
