import {UserPermission} from '../user/user-permissions.enum';
import {MatIconEnum} from '../common/mat-icon.enum';

export class SideMenuItem {
  items: UserPermission[];
  display: string;
  link: string;
  image: MatIconEnum;
  dataTourAttr?: string;

  constructor(items: UserPermission[], display: string, link: string, image: MatIconEnum, dataTourAttr?) {
    this.items = items;
    this.display = display;
    this.link = link;
    this.image = image;
    this.dataTourAttr = dataTourAttr;
  }
}
