import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ApiService} from '../api';
import {DownloadLinkService} from '../download-link.service';
import {Id} from '../../models/types';
import {CheckedProductsDownloadRequest} from '../../models/product/product-list/checked-products/checked-products-download-request';
import { FilterQuery } from '../../modules/blocks/filters/shared/models/filter-query';

@Injectable()
export class BulkDownloadService {

  private readonly _URL: string;

  constructor(
    private http: HttpClient,
    private api: ApiService,
    private downloadService: DownloadLinkService
  ) {
    this._URL = `${api.API}vendor/bulk_download`;
  }



  async downloadProducts(productIds: Id[], checkedAllProducts: boolean, type: string, filtersQuery: FilterQuery = new FilterQuery(), search: string): Promise<void> {
    const body = new CheckedProductsDownloadRequest(productIds, checkedAllProducts, type, filtersQuery, search);

    const blob = await this.http
      .post(this._URL, body, {
        responseType: 'blob',
        observe: 'response'
      })
      .toPromise();

    this.downloadService.saveBlobFromResponse(blob);
  }

  async downloadTemplate(): Promise<void> {
    const url = `${this._URL}/template`;

    const blob = await this.http
      .get(url, {
        responseType: 'blob',
        observe: 'response'
      })
      .toPromise();

    this.downloadService.saveBlobFromResponse(blob);
  }

}
