<div
  class="tour__overlay"
  *ngFor="let dump of overlayDumpers"
  [style.width]="dump.width"
  [style.height]="dump.height"
  [style.top]="dump.top"
  [style.left]="dump.left"
  [style.position]="activeTourSlide.isElementFixed ? 'fixed' : 'absolute'"
  [style.backgroundColor]="dump.background"
  [style.zIndex]="currentZindex"
></div>

<div
  class="tour__overlay-absolute"
  [style.height]="currentScrollHeight"
  [style.top]="topOffsetOfOverlay ? topOffsetOfOverlay + 'px' : '100vh'"
  *ngIf="activeTourSlide && !activeTourSlide.isElementFixed"
  [style.zIndex]="currentZindex"
></div>

<div
  class="tour__container"
>
  <app-tour-tooltip
    [tourItem]="activeTourSlide"
    [holePosition]="holeParams"
    [index]="currentSlideIndex"
    [length]="currentTourSlidesCount"
    (onTourFinish)="tourOverlayClick()"
    (onNextClick)="nextClickHandler()"
  ></app-tour-tooltip>
</div>
