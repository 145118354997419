<app-modal
  *ngIf="vendorStatus && showModal"
  [allowClosing]="false"
>
  <div class="terms-wrapper">
    <div
      *ngIf="isShowSpinner"
      class="spinner-container"
    >
      <mat-spinner></mat-spinner>
    </div>

    <div class="terms-title">Epallet Vendor Agreement</div>
    <div class="terms-subtitle">Last updated August  17, 2022</div>
    <div class="terms-content">
      <p>
        This Vendor Partnership Agreement (“Agreement”) is made and entered into by and 
        between Epallet, Inc. (Epallet or “Buyer”) and the vendor (“Vendor”) agreeing to 
        these terms (each a “Party” or, collectively, the “Parties”) and is effect of the 
        date (“Effective Date”) the Vendor agrees to these updated terms. 
      </p>
      <p>
        WHEREAS, Vendor desires to participate in Epallet’s business-to-business (B2B) 
        eCommerce (or “Marketplace”) on the terms and conditions described herein; and
      </p>
      <p>
        WHEREAS, Epallet is willing to allow Vendor to participate on the Marketplace based 
        on the terms and conditions described herein.
      </p>
      <h3>1. Basic Terms</h3>

      <div class="margin20">
        <p>
          1.1
          <b>Products.</b> 
          Vendor agrees to sell products (“Products”) on Epallet’s Marketplace. 
          The terms, conditions and policies mentioned in this Agreement shall apply to all products sold by 
          Vendor through the Marketplace.
        </p>

        <p>
          1.2
          <b>Term of Agreement.</b>
          This Agreement shall commence on the Effective Date and shall continue until terminated by either 
          Party upon at least thirty (30) days advance written notice to the other Party.  
          All obligations undertaken respecting any confidential information already provided hereunder shall 
          survive any termination or cancellation of this Agreement.
        </p>

        <p>
          1.3
          <b>Business Terms, Conditions and Policies.</b>
          Vendor hereby expressly agrees to be bound by the terms, conditions and policies of this Agreement, 
          as well as the terms, conditions and policies of the following documents (the terms, conditions and 
          policies of which are hereby expressly incorporated herein by reference and shall be referred to 
          collectively hereinafter as “Supporting Documents”):
        </p>
        <div class="margin40">
          <ol style="list-style-type: lower-roman;">
            <li>
              Epallet Marketplace Vendor Participant Policies (“Policies”)
            </li>
            <li>
              Epallet Vendor Purchase Order Terms (“Terms”)
            </li>
          </ol>
        </div>
        <p>
          Epallet reserves the right to amend the above Supporting Documents from time-to-time at its discretion; 
          provided, however, that the most recent version of the above Supporting Documents are readily available 
          to the Vendor.  In the event a change is required to the agreed upon “Terms”, Epallet will work with the 
          Vendor to amended terms.  A new “Terms” document will be updated and agreed to in writing by both “Parties”. 
          Furthermore, Epallet agrees to provide written notice reasonably in advance of changes to the Policies. 
        </p>

        <p>
          1.4
          <b>Complete Agreement.</b>
          This Agreement, the Epallet merchandise purchase order (PO) and the Supporting Documents shall constitute 
          the complete agreement of the Parties and shall supersede all previous agreements, previous communications 
          between the Parties for Products, and/or third party agreements referencing policies or terms of 
          partnership. No amendments or modifications of this Agreement shall be valid unless in writing and signed 
          by an authorized Epallet representative.  Vendor hereby expressly acknowledges and agrees that it has 
          received copies of, or has been provided access to, the Supporting Documents above. Vendor's acceptance of 
          a purchase order is limited to the terms and conditions contained in this Agreement and Supporting 
          Documents and Vendor agrees that any additional or different terms or conditions contained in Vendor's 
          acceptance of purchase order or invoice shall have no force or effect, and the purchase order will be 
          deemed accepted by Vendor without such additional or different terms and conditions.
        </p>
      </div>

      <h3>2. Insurance Coverage and Limits</h3>
      <p>
        Vendor shall provide to Epallet a Certificate of Insurance (COI) issued by its insurance company or 
        agent giving evidence of Product Liability and Complete Operations insurance and Contractual Liability 
        insurance for liabilities assumed in the Agreement, including indemnification of Epallet. Vendor should 
        carry insurance with a minimum of $1,000,000 per occurrence.
      </p>
      <p>
        An umbrella policy is not required. However, if Vendor has an umbrella policy with a limit equal to or in 
        excess of the stipulated primary insurance limits for liability, it shall be permissible to reduce the 
        limits for such primary insurance to whatever limits are required by the umbrella policy. Notwithstanding 
        anything herein to the contrary, in no event shall Vendor’s liability be limited to the minimum required 
        insurance limits.
      </p>
      <p class="margin20">2.1 
        <b>Other Insurance Requirements</b>
      </p>
      <div class="margin40">
        <ol style="list-style-type: lower-roman;">
          <li>
            If any aspect of the required insurance is written on a "claims made" form, the Vendor agrees to 
            maintain such insurance for a period of three years immediately following any severance of the supplying 
            relationship between the Vendor and Epallet. If "claims made" coverage is used in meeting the minimum 
            insurance requirements, the required Certificate of Insurance must specifically identify such coverage.
          </li>
          
          <li>
            The Certificate of Insurance shall indicate the types of insurance provided, policy numbers, 
            inception and expiration dates, limits of liability, and insurance carriers.
          </li>
        
          <li>
            The Certificate of Insurance shall include the following clause: "In the event of policy cancellation,
            non-renewal or material reduction of coverage, thirty days advance written notice will be given to Epallet."
          </li>

          <li>
            The Certificate of Insurance shall specify Epallet and all parent, subsidiary, associated and 
            affiliated companies are included as additional insured on a primary and non-contributing basis.
          </li>
        
          <li>
            The required insurance shall be primary and shall not contribute with any insurance maintained by 
            such additional insured.
          </li>
       
          <li>
            The completed Certificate of Insurance should be updated and provided annually to Epallet via the 
            Vendor Portal or sent to :
          </li>
        </ol>
        <pre class="margin20">
          Epallet, Inc. 
          5115 Clareton Drive Suite 200 
          Agoura Hills CA 91301
        
          Email: vendorsuccess@epallet.com</pre>
        <ol style="list-style-type: lower-roman;" start="7">
          <li>Notice of claims or suits should be addressed to Vendor as follows:</li>
        </ol>
        <pre class="margin20">
          Epallet 
          Attn:  Legal Team
          5115 Clareton Drive Suite 200 
          Agoura Hills CA 91301		

          Email: legal@epallet.com</pre>
      </div>

      <h3>3. Guarantee and Indemnification</h3>
      <p class="margin20">
        3.1 <b>Compliance with Laws.</b>
        Vendor hereby warrants, covenants and guarantees that as of the Effective Date and continuing through the 
        term of this Agreement: (a) that in connection with the manufacture, processing, storing and shipping of 
        the Products it has complied with all applicable Federal, State and local laws, rules and regulations, 
        including, and without limitation to those applicable to (b) wages and hours and that no person was 
        employed in the production or processing of Products who is under the age limits prescribed by State or 
        Federal laws, rules or regulations, or who is an illegal immigrant or undocumented worker such as those 
        described in the Fair Labor Standards Act and Federal Child Labor Act (c) that no article hereafter packed, 
        shipped, delivered or consigned by or on behalf of Vendor to, or on the order of Epallet, is, when packed, 
        shipped, delivered or consigned by the Vendor, adulterated, misbranded or otherwise in violation of the 
        Tariff Act of 1930 or Federal Food, Drug, and Cosmetic Act, as amended, the Fair Packaging and Labeling Act, 
        as amended, the Federal Meat Inspection Act, as amended, or any other Federal or State consumer fraud, 
        consumer protection, food, drug or cosmetic statute, rule or regulation applicable to the Product; (d) that 
        each pack, shipment, delivery or consignment of Products made to, or on the order of Epallet, will not bear 
        or contain any food additive, pesticide or other substance which, as of the date of such pack, shipment, 
        delivery or consignment is unsafe within the meaning of the Federal Food, Drug and Cosmetic Act, as amended 
        or is inconsistent with the labeling or packaging of the Product; (e) that the Product is manufactured and 
        labeled in accordance with the requirements of the Consumer Products Safety Act, as amended, and the Federal 
        Hazardous Substances Act, as amended, Flammable Fabrics Act, as amended, and the applicable standards and r
        ules promulgated there under, and that no such article is a "banned hazardous product," an "imminently 
        hazardous product", a “misbranded hazardous substance” or a "misbranded package" within the meaning of 
        such Acts; (f) that the Product is not prohibited under any applicable laws, statutes, rules or regulations 
        from being introduced into interstate commerce; (g) that the Product, its packaging and labeling are in 
        compliance with all other Federal and State laws, rules and regulations applicable to the Product, its 
        packaging, or its labeling;  (h) that Vendor will provide specific guarantees of Product compliance with 
        pplicable federal or state laws, rules or regulations as requested by Epallet; (i) that, when applicable, 
        Vendor will provide Material Safety Data Sheets in compliance with the Occupational Safety and Health 
        Administration Hazard Communication Standard and (j) that Vendor has complied with all Federal Food and Drug 
        Administration and United States Department of Agriculture rules and regulations applicable to the 
        Products, maintains all records required to evidence such compliance and has supplied or will supply all 
        information and materials to Epallet necessary for their compliance with country of origin labeling 
        requirements where applicable; (k) that all packaging label copy is accurate, complete, and in compliance 
        with all applicable government laws and regulations and Epallet design standards; (l) the Vendor will not 
        engage in any acts of foreign corruption as is addressed in the Foreign Corrupt Practices Act.  Vendor will 
        promptly notify Epallet of any action by a governmental agency concerning or affecting any Products 
        purchased under this Agreement or the breach by Vendor, or existence of any event or circumstance which 
        could reasonably give rise to Vendor’s breach, of this Section.  In the event of any breach of this Section, 
        Vendor shall promptly provide to Epallet a written description of the nature of Vendor’s noncompliance and 
        the procedures taken by Vendor to remedy such noncompliance.  Vendor shall at all times keep Epallet informed 
        of the status of any noncompliance by Vendor with this Section.
      </p>
      <p class="margin20">
        3.2 <b>Indemnification.</b>
      </p>
      <div class="margin40">
        <ol style="list-style-type: lower-roman;">
          <li>
            The failure of any Products or lot of Products to comply with the guarantee set forth in the 
            previous section;
          </li>
        
          <li>
            Breach by Vendor of any of the warranties or other obligations of this Agreement;
          </li> 
        
          <li>
            Vendor’s failure to timely deliver Products ordered hereunder;
          </li>
        
          <li>
            The seizure, detention or destruction of Products by or under the authority of any governmental 
            authority, whether by court order or with the commercially reasonable concurrence of Epallet or any 
            subsequent purchaser; 
          </li>
        
          <li>
            Any claim of violation or infringement of any trademark (except with respect to brands or 
            trademarks owned by Epallet), copyright, trade dress, patent, trade secrets or similar intellectual 
            property of any third parties arising from use, distribution or sale of Products;
          </li>
        
          <li>
            Any property damage and/or personal injury arising from the purchase, use, or ingestion of Products;
          </li> 
        
          <li>
            Any costs incurred in the investigation, remediation, or defense of any third party claim 
            resulting from any alleged breach by Vendor of any of the warranties or other obligations of this 
            Agreement. Inspection or the opportunity for inspection of Products by Epallet shall not relieve Vendor 
            of any obligations under this Agreement.
          </li>
        </ol>
      </div>
      
      <h3>4. Confidentiality and Security</h3>
      <p class="margin20">4.1 <b>Confidentiality.</b>
        Epallet and Vendor each agrees that it will not, without the prior written consent of the other Party, 
        disclose to any third party (other than to its own employees, agents or contractors on a “need to know” 
        basis) any Confidential Information (as hereinafter defined) which relates to the Vendor’s business with 
        Epallet or Epallet customers.  Epallet and Vendor each further agrees that it will not use for its own 
        account or for the benefit of any third party any Confidential Information which relates to the Vendor’s 
        business with Epallet or Epallet customers.  Vendor and Epallet each will take all appropriate steps to 
        safeguard such Confidential Information and to protect it against disclosure, misuse, loss and theft.  As 
        used in this Agreement, the term “Confidential Information” means information relating to (i) products, 
        (ii) packaging, (iii) costs, prices, allowances, rebates, pricing structures or strategies, (iv) sales or 
        purchase data, (v) new products, developments, methods and processes, whether patentable or not patentable 
        and whether or not reduced to practice, (vi) members and member lists, (vii) technology and trade secrets, 
        (viii) financial condition and results of operations, (ix) general corporate information, (x) operating 
        data, (xi) personal data such as names, addresses, phone and email of employees, consultants and agents, 
        and customers (xii) credit cardholder data, (xiii) information contained in this Agreement, and (xiv) all 
        similar or related information in whatever form.  Confidential Information does not include information 
        that is generally available to the public or information that becomes known to a Party to this Agreement 
        other than through disclosure by the other Party under this Agreement or as a result of a breach of this 
        Agreement or another confidentiality agreement.  Parties agree to hold in strict confidence and not to use 
        or disclose or commercialize any Confidential Information except upon the prior written consent of the 
        other Party, which consent shall be within the other Party's sole discretion.  Parties acknowledge that any 
        and all of each Party’s Confidential Information is and shall remain the proprietary information and 
        property of each respective Party.  Parties agree to use the degree of care necessary to maintain and 
        protect any and all Confidential Information as confidential, and in no event less than reasonable care, 
        and not to disclose or allow the disclosure of any Confidential Information to any person or entity other 
        than their employees or agents that have a need to know such information to perform services hereunder.  
        Parties shall remain liable for any breach of this provision by their employees, agents, contractors and 
        authorized third parties.
      </p>
      <div class="margin40">
        <ol style="list-style-type: lower-roman;">
          <li>
            Nothing herein shall be deemed to prevent either Party from disclosing the Confidential Information of 
            the other Party in response to legal process under applicable law; provided that the disclosing Party 
            (i) takes reasonable steps available to keep the Confidential Information from the public record, (ii) to 
            the extent permitted by law, promptly notifies the other Party of such process prior to disclosure so that 
            the other Party may seek an appropriate protective order and/or waive compliance with the terms hereof and 
            requests the Party requiring disclosure to provide the other Party with a reasonable amount of time to 
            object to such production; and (iii) discloses only that portion of the Confidential Information which 
            legal counsel advises is legally required to be disclosed, provided that the disclosing Party delivers to 
            the other Party written notice together with a description of any Confidential Information to be disclosed 
            (including copies of the relevant portions of the relevant documents) as far in advance of its disclosure 
            as is practicable.
          </li>
        </ol>
      </div>
      <div class="margin20">
        <p>4.2 <b>Breach of Confidentiality.</b>
          Parties acknowledge that an adequate remedy at law in the event of breach of this provision may not be 
          available and that either Party may suffer irreparable damage and injury in the event of a breach of this 
          provision.  In such event each Party agrees that the non-breaching Party shall be entitled to seek 
          specific performance and injunctive relief to enforce this provision in addition to any other remedies 
          that non-breaching Party may have at law.  In the event of a security breach or an unauthorized 
          disclosure, Vendor hereby agrees to cooperate with Epallet in every reasonable way to help Epallet regain 
          possession of Epallet’s Confidential Information and prevent its further use or disclosure.  Without 
          limiting the foregoing, in the event of a known or suspected security breach or an unauthorized disclosure, 
          Epallet or its designated third party representative shall be permitted to access Vendor’s facilities, files 
          and data to conduct a security review, assessment and audit, and Vendor hereby agrees to provide all 
          reasonably requested assistance and cooperation in conjunction with any such security review, assessment 
          and audit.  Vendor is responsible for taking appropriate precautions against damage to its operations, 
          information systems and data that could be caused by defects, interruptions or malfunctions of 
          communications or equipment, and shall maintain any necessary means for the storage, reconstruction, and 
          transmission of any lost or damaged data.  All measures undertaken by Vendor for security pursuant to 
          this agreement while Epallet’s Confidential Information is in Vendor’s control or possession will be at 
          Vendor’s sole cost and expense.
        </p>
        <p>4.3 <b>Return of Information.</b>
          Confidential Information, including permitted copies, is and at all times shall remain the property of the 
          disclosing party. Each recipient shall, upon the termination of its business relationship with the other 
          or upon a written request by the other party, whichever is earlier, promptly return to the disclosing 
          party all Confidential Information, including all copies, reproductions or summaries thereof and all 
          records, notes and other written, printed or tangible materials in its possession pertaining thereto, or, 
          if so directed by the disclosing party, provide written certification that all of the aforementioned has been 
          destroyed in a manner which preserves its confidentiality.  The return of Confidential Information, copies 
          thereof and other written materials pertaining thereto shall in no event relieve the recipient of any obligation 
          of confidentiality and non-use contained herein with respect to the Confidential Information.
        </p>
        <p>4.4 <b>Term.</b>
          The foregoing commitments of each party shall survive any termination of the Relationship between the parties, 
          and shall continue in perpetuity or, until such time as all Confidential Information of the other party 
          disclosed hereunder becomes publicly known and made generally available through no action or inaction of the 
          receiving party or breach of this Agreement.
        </p>
        <p>4.5 <b>Publicity.</b>
          Vendor and Epallet must obtain express, written consent from the other Party prior to releasing any publicity 
          regarding the existence or content of this Agreement.  
        </p>
      </div>

      <h3>5. Product Content</h3>
      <div class="margin20">
        <p>5.1 <b>Product Content Compliance.</b>
          In addition to agreeing to comply with all of the requirements specified in the Supporting Documents, 
          Vendor hereby covenants, represents and warrants that the product descriptions, knowledge sheets, 
          feature/benefit information, photography, images, video animation or interactive media, graphics, audio, 
          website addresses and other content (collectively, “Product Content”) furnished by Vendor to Epallet’s 
          for any Products displayed on Epallet’s Marketplace shall be accurate and comply with all relevant 
          laws – including, but not be limited to: (i) all existing laws, regulations, standards, orders, rulings, 
          rules and guides of all United States federal, state and local governments (and all departments, boards, 
          bureaus and commissions thereof), including, but not limited to the Federal Trade Commission Act, the 
          Consumer Product Safety Act, the Textile Fiber Products Identification Act and regulations relating to 
          statement of fiber content of rayon’s, wool, linens, and other textiles contained in such Products, the 
          Wool Products Labeling Act, the Care Labeling Rule, the Fair Packaging and Labeling Act, the Magnusson-
          Moss Warranty Act, the Federal Hazardous Substances Act, the Poison Prevention Packaging Act, the 
          Radiation Control for Health and Safety Act, the Food, Drug and Cosmetics Act, and the Fur Products 
          Labeling Act; and (ii) the laws, regulations and rules of all countries in which the Product is produced 
          or delivered.
        </p>
        <p>5.2 <b>Right of Use.</b>
          Vendor hereby grants to Epallet a non-exclusive, gratis license to use the Product Content furnished by 
          Vendor to Epallet in any advertisement or display of the Product(s), including, but not limited to, on 
          any of the Epallet’s websites and catalogs.  Vendor hereby covenants, represents and warrants that 
          Vendor is the sole owner and copyright proprietor of the Product Content furnished by Vendor to Epallet, 
          that Vendor is free to grant Epallet all rights hereunder and that Vendor possesses all rights to the 
          Product Content necessary for the grant of the license provided for herein.  Vendor further covenants, 
          represents and warrants that the Product Content furnished by Vendor to Epallet shall not infringe or 
          encroach upon the contractual or proprietary rights of any other person, firm or corporation, including 
          without limitation, the designs, design patents, trademarks, trade names, trade dress, copyrights, rights 
          of privacy and publicity, trade secrets and other proprietary or intellectual property rights of any third 
          party.
        </p>
        <p>5.3 <b>Notices.</b>
          If, at any time during the term of this Agreement, in the event Vendor no longer has the necessary rights 
          with respect to any of the Product Content for use as described herein, Vendor shall provide Epallet with 
          immediate written notice and Epallet will use its commercially reasonable efforts to discontinue any use 
          objected to by Vendor as soon as reasonably practicable.
        </p>
        <p>5.4 <b>No Obligation.</b>
          For purposes of clarification, Epallet shall not be obligated to use all the Product Content provided by 
          Vendor to Epallet.
        </p>
      </div>

      <h3>6. Quality Standards and Warranties</h3>
      <div class="margin20">
        <p>6.1 <b>Quality Assurance and Merchantability.</b>
          Vendor warrants that all Products pursuant to this Agreement will (including all of its related packaging, 
          labeling and printed materials, in addition to any express warranties or guarantees heretofore or hereafter made by Vendor) (i) conform to the description and specifications thereof contained herein and the merchandise samples that were approved by Epallet, (ii) be free from any defects in design, constructions, material, packaging or workmanship, (iii) be merchantable at the time of delivery to us and at time of use by Epallet’s customers, 
          and (iv) be fit and safe for sale and any use by us or Epallet’s customers for which such items are originally intended and any particular intended use of or for which Vendor or Vendor’s agents have knowledge. 
        </p>
        <p>6.2 <b>Vendor Warranties.</b>
          In addition to any express or implied warranties, Vendor warrants that the Products delivered hereunder 
          shall be (a) merchantable; (b) free from defects in workmanship, materials and packaging; (c) free from 
          defects in construction and design; and (d) fit and sufficient for the purpose for which they are 
          intended.
        </p>
      </div>

      <h3>7. Miscellaneous.</h3>
      <div class="margin20">
        <p>7.1 <b>No Purchase Commitment.</b>
          Unless otherwise specified in writing signed by an authorized Epallet representative, this Agreement 
          does not obligate Epallet to purchase any quantities of Products from Vendor.  Purchases are initiated 
          only by purchase orders issued by Epallet.  
        </p>
        <p>7.2 <b>Non-Waiver.</b>
          The failure or delay of Epallet to insist upon Vendor’s strict performance of the provisions of this 
          Agreement or to exercise in any respect any right, power, privilege or remedy provided for under this 
          Agreement shall not operate as a waiver or relinquishment thereof, nor shall any single or partial 
          exercise of any right, power, privilege or remedy preclude other or further exercise thereof, or the 
          exercise of any other right, power, privilege or remedy.  No express waiver shall be valid unless in a 
          prior writing signed by an authorized representative of Epallet.
        </p>
        <p>7.3 <b>Governing Law / Resolution of disputes.</b>
          This Agreement, the transactions contemplated hereby and any claims or disputes, whether in contract, 
          tort or otherwise between the Parties arising under this Agreement or concerning goods or services of 
          the Vendor shall be governed and construed in accordance with the internal laws of California. The 
          parties to this contract further agree to resolve legal disputes through arbitration methods rather than 
          civil lawsuits.  The arbitration shall be administered by JAMS in Santa Monica before one arbitrator 
          pursuant to its Comprehensive Arbitration Rules and Procedures and in accordance with the Expedited 
          Procedures in those Rules/ JAMS’ Streamlined Arbitration Rules and Procedures. Judgment on the award may 
          be entered in any court having jurisdiction. This clause shall not preclude parties from seeking 
          provisional remedies in aid of arbitration from a court of appropriate jurisdiction. However, before 
          arbitration shall commence the parties are required to participate in one all-day session of non-binding 
          mediation under the auspices of JAMS or another mutually agreed upon mediator in a good faith effort to 
          work out the dispute short of an actual hearing. All costs associated with the arbitration shall be 
          borne equally by the parties. 
        </p>
        <p>7.4 <b>Solvency.</b>
          In the event of any proceeding, voluntary or involuntary, in bankruptcy or insolvency by or against the 
          Vendor, including any proceeding under the United States bankruptcy laws, or any bankruptcy, insolvency 
          or receivership laws of any state or any foreign country, or any political subdivision thereof or in the 
          event of the appointment with or without Vendor’s consent of a receiver or an assignee for the benefit of creditors, Epallet may, at its option, cancel any PO as to any undelivered portion of the merchandise.
        </p>
        <p>7.5 <b>Assignment.</b>
          Vendor shall not transfer, delegate or assign any rights or obligations under this Agreement without the 
          prior written consent of Epallet. No permitted assignment shall relieve Vendor of its obligations 
          hereunder.  Any assignment in violation of this Section shall be void.  This Agreement shall be binding 
          upon the Parties and their respective successors and permitted assigns.
        </p>
        <p>7.6 <b>Independent Contractor.</b>
          When work of any description is performed in furtherance of Vendor’s obligations under this agreement on 
          the premises of Epallet, Vendor agrees that such services are to be rendered by Vendor as an independent 
          contractor and Vendor shall provide all safeguards and take all necessary precautions to prevent the 
          occurrence of any accident, injury, death or loss to any person or property and shall be solely 
          responsible therefore. Vendor will indemnify and hold Epallet harmless from and against any and all 
          claims, losses, damage, liability, fines and expenses arising out of, or in any way related to, the 
          performance of this agreement except to the extent caused by the negligence of Epallet, its employees, 
          agents’ indemnities or other independent contractors. Vendor shall not be a representative or agent of 
          Epallet for any purpose, nor shall it act under the authority of Epallet.  Moreover, None of the actions 
          taken by Epallet in connection with this agreement shall be considered to create a Joint Venture, legal 
          Partnership, or other joint business venture with the Vendor, without corresponding documentations 
          asserting such.
        </p>
        <p>7.7 <b>Force Majeure.</b>
          Neither Epallet nor Vendor shall be liable to the other for any failure to perform in accordance with 
          any Epallet orders if such failure arises out of any cause or event beyond its reasonable control and 
          without its fault or negligence including, but not limited to, labor disputes. However, no such failure 
          of Vendor to perform by reason of the default or delay by any of its subcontractors or Vendors shall be 
          excusable unless such default or delay by such subcontractor or Vendor arises out of a cause or event 
          beyond the reasonable control of and without the fault or negligence of both Vendor and such 
          subcontractor or Vendor. In the event Epallet is delayed or restricted in its ability to make use of the 
          goods as originally contemplated by reason of any event beyond its reasonable control and without its 
          fault or negligence, Epallet may postpone the delivery dates or cancel or modify a PO in whole or in 
          part to such extent as is reasonable under circumstances without any obligations or liability to Vendor.
        </p>
        <p>7.8 <b>Remedies.</b>
          All rights and remedies of Epallet under this Agreement are cumulative, nonexclusive and in addition to 
          other rights and remedies provided herein or by law.
        </p>
        <p>7.9 <b>Severability.</b>
          If any provision of this Agreement shall be held invalid, unenforceable or contrary to law by a court of 
          competent jurisdiction, such provision shall be deemed deleted and replaced by a valid and enforceable 
          provision which so far as possible achieves the parties' intent in agreeing to the original provision.  
          The remaining provisions of this Agreement shall continue in full force and effect.
        </p>
        <p>7.10 
          <b> Amounts Due to</b> Epallet.Any amounts due to <b>Epallet</b> under the terms of this Agreement shall 
          be paid by <b>Vendor</b> by the due date. <b>Epallet</b> shall be entitled to recover its costs and 
          expenses incurred in collecting any amounts due from <b>Vendor</b>, including reasonable attorney's fees 
          and expert’s fees. If funds are not remitted to <b>Epallet</b> by the due date, <b>Epallet</b> reserves 
          the right to deduct amount from any outstanding balance owed <b>Vendor</b>
        </p>
        <p>7.11 <b>Discounts and Allowances.</b>
          Vendor warrants, in accepting and filling a purchase order, that the prices of the Products, and any 
          discounts or allowances therefrom, are available to other customers on proportionally equal terms, after 
          making allowance for cost differences and for Vendor’s right to select customers, to change prices in 
          response to changing market conditions, or to meet in good faith the prices of Vendor’s competitors.
        </p>
        <p>7.12 <b>Authorized Representation.</b>
          Vendor covenants, represents and warrants that (i) it has the authority to enter into this Agreement; and 
          (ii) its signatory below is authorized to bind Vendor to this Agreement and its obligations contained 
          herein.  
        </p>
      </div>

      <p>
        Vendor intends to be legally bound and has caused this Agreement to be duly executed as of the month, 
        day and year the Vendor agreed to these terms.
      </p>
    </div>

    <div class="terms-footer">
      <div class="terms-checkbox">
        <mat-checkbox
          [(ngModel)]="isAccepted"
        >
          I have read the Terms and Conditions
        </mat-checkbox>
      </div>

      <div class="terms-buttons">
        <app-button
          [disabled]="!isAccepted"
          (click)="acceptTermsOfUse()"
        >
          Accept
        </app-button>

        <a
          class="link"
          (click)="logout()"

        >
          Logout
        </a>
      </div>
    </div>
  </div>
</app-modal>

<app-terms-of-use-finish
  *ngIf="showFinishModal"
  (onCloseModal)="closeFinishModal()"
></app-terms-of-use-finish>
