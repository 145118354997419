<form
  *ngIf="!!form && !!errors"
  class="promo-code-container"
  [formGroup]="form"
  [appForm]="errors.errors"
>
  <app-promo-code-form
    [form]="form"
    [showSpinner]="showSpinner"
    [promoCodeError]="promoCodeError"
    [promoCodeSucceed]="promoCodeSucceed"
    (onSubmit)="sendPromoCode()"
    (onClearForm)="clearForm()"
    (onResetError)="resetErrors()"
  ></app-promo-code-form>

  <div
    *ngIf="messages.hasMessages"
    class="messages"
  >
    <app-message-list
      [style]="MessageStyle.TEXT"
      [messages]="messages"
    ></app-message-list>
  </div>
</form>
