import { Injectable } from '@angular/core';
import {saveAs as importedSaveAs} from 'file-saver';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {WindowHelper} from 'lib-shared';
import * as $ from 'jquery';

@Injectable()
export class DownloadLinkService {

  constructor(
    private http: HttpClient
  ) {}



  async downloadStaticFile(url: string): Promise<void> {
    const filename = url.match(/\/([^\/]*$)/)[1];

    try {
      const blob = await this.getStaticFile(url);

      importedSaveAs(blob, filename);
    } catch(err) {
      console.log(err);
    }
  }

  async getStaticFile(url: string): Promise<Blob> {
    return this.http
      .get(url, {responseType: 'blob'})
      .toPromise();
  }

  async convertFileToBase64(file: File | Blob): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  blobToFile(blob: Blob, fileName: string, type: string) {
    const timestamp = new Date().getTime();
    return new File([blob], fileName, {lastModified: timestamp, type: type});
  }

  saveBlobFromResponse(response: HttpResponse<Blob>): void {
    const contentDispositionHeader = response.headers.get('content-disposition');
    const filename = contentDispositionHeader
      .split(';')[1]
      .split('=')[1]
      .replace(/\"/g, '');

    importedSaveAs(response.body, filename);
  }

  printPdfFromBlob(blob: Blob): void {
    if(WindowHelper.isBrowserFirefox) {
      this.openBlobInNewWindow(blob);
    } else {
      const printIframeId = 'print_frame';
      let iframe = document.getElementById(printIframeId) as HTMLIFrameElement;

      if(iframe) {
        iframe.remove();
      }

      iframe = document.createElement('iframe');
      iframe.src = URL.createObjectURL(blob);
      iframe.style.visibility = 'hidden';
      iframe.id = printIframeId;
      document.body.appendChild(iframe);

      // wait for the iFrame to fully load and call the print() function afterwards
      $(`#${iframe.id}`).on('load', () => {
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
      });
    }
  }

  openBlobInNewWindow(blob: Blob): void {
    const pdfUrl = URL.createObjectURL(blob);
    this.openLinkInNewWindow(pdfUrl);
  }

  openLinkInNewWindow(link: string): void {
    window.open(link, '_blank');
  }

}
