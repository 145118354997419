export enum FreightClass {
  _50 = '50',
  _55 = '55',
  _60 = '60',
  _65 = '65',
  _70 = '70',
  _77_5 = '77.5',
  _85 = '85',
  _92_5 = '92.5',
  _100 = '100',
  _110 = '110',
  _125 = '125',
  _150 = '150',
  _175 = '175',
  _200 = '200',
  _250 = '250',
  _300 = '300',
  _400 = '400',
  _500 = '500'
}
