import {VendorCertificate} from './certificate';

export class CertificateGroup {
  groupName: string;
  items: VendorCertificate[];

  constructor(groupName: string, items: VendorCertificate[]) {
    this.groupName = groupName;
    this.items = items;
  }
}
