import {LeadTimeUom} from '../models/product/enums/lead-time-uom.enum';
import {ShippingStorageTemp} from '../models/product/enums/shipping-storage-temp.enum';
import {CaseSizeUom} from '../models/product/enums/case-size-uom.enum';
import {CaseWeightUom} from '../models/product/enums/case-weight-uom.enum';
import {UnitSizeUom} from '../models/product/enums/unit-size-uom.enum';
import {TruckSizeRestriction} from '../../registration/fob-locations/shared/models/enums/truck-size-restriction';
import {CertificateAffiliation} from '../../registration/certificates/shared/models/enums/certificate-affiliation.enum';
import {IndustryType} from '../models/product/enums/industry-type.enum';
import {DateCodeType} from '../models/product/enums/date-code-type.enum';
import {FreightClass} from '../models/product/enums/freight-class.enum';
import {AmountPercents} from '../models/product/enums/amount-percents.enum';


const freightClasses = new Map<number, FreightClass>([
  [50, FreightClass._50],
  [55, FreightClass._55],
  [60, FreightClass._60],
  [65, FreightClass._65],
  [70, FreightClass._70],
  [77.5, FreightClass._77_5],
  [85, FreightClass._85],
  [92.5, FreightClass._92_5],
  [100, FreightClass._100],
  [110, FreightClass._110],
  [125, FreightClass._125],
  [150, FreightClass._150],
  [175, FreightClass._175],
  [200, FreightClass._200],
  [250, FreightClass._250],
  [300, FreightClass._300],
  [400, FreightClass._400],
  [500, FreightClass._500]
]);


const packing = new Map<string, string>([
  ['Aluminium Bottle', 'ALUMINIUM BOTTLE'],
  ['Aseptic Carton', 'ASEPTIC CARTON'],
  ['Bag', 'BAG'],
  ['Bag in Box', 'BAG IN BOX'],
  ['Bale', 'BALE'],
  ['Bottle', 'BOTTLE'],
  ['Bowl', 'BOWL'],
  ['Box', 'BOX'],
  ['Brick', 'BRICK'],
  ['Bulk', 'BULK'],
  ['Bundle', 'BUNDLE'],
  ['Can', 'CAN'],
  ['Canister', 'CANISTER'],
  ['Cardboard Tote', 'CARDBOARD TOTE'],
  ['Carton', 'CARTON'],
  ['Chub', 'CHUB'],
  ['Chunk', 'CHUNK'],
  ['Club Pack', 'CLUB PACK'],
  ['Corrugated Carton', 'CORRUGATED CARTON'],
  ['Corrugated Display', 'CORRUGATED DISPLAY'],
  ['Cup', 'CUP'],
  ['Cryovac', 'CRYOVAC'],
  ['Fiber Bags', 'FIBER BAGS'],
  ['Fiber Drums', 'FIBER DRUMS'],
  ['Fiber Cans', 'FIBER CANS'],
  ['Gallon', 'GALLON'],
  ['Glass Bottle', 'GLASS BOTTLE'],
  ['Glass Container', 'GLASS CONTAINER'],
  ['Glass Jars', 'GLASS JARS'],
  ['Jug', 'JUG'],
  ['Jug in a Box', 'JUG IN A BOX'],
  ['Keg', 'KEG'],
  ['K-Pak', 'K-PAK'],
  ['Metal Cans', 'METAL CANS'],
  ['Metal Drum', 'METAL DRUM'],
  ['Microwave Bowl', 'MICROWAVE BOWL'],
  ['Other', 'OTHER'],
  ['Package', 'PACKAGE'],
  ['Packet', 'PACKET'],
  ['Pail', 'PAIL'],
  ['Paper Bags', 'PAPER BAGS'],
  ['PET', 'PET'],
  ['Pet Drum', 'PET DRUM'],
  ['Plastic Bottle', 'PLASTIC BOTTLE'],
  ['Plastic Container', 'PLASTIC CONTAINER'],
  ['Plastic Cups', 'PLASTIC CUPS'],
  ['Plastic Drum', 'PLASTIC DRUM'],
  ['Plastic Jar', 'PLASTIC JAR'],
  ['Plastic Jug', 'PLASTIC JUG'],
  ['Plastic Tote', 'PLASTIC TOTE'],
  ['Plastic Tub', 'PLASTIC TUB'],
  ['Poly Bag', 'POLY BAG'],
  ['Pouch', 'POUCH'],
  ['Pull-Top Can', 'PULL-TOP CAN'],
  ['PVT', 'PVT'],
  ['Resealable Bag', 'RESEALABLE BAG'],
  ['Shipper', 'SHIPPER'],
  ['Squeeze Bottle', 'SQUEEZE BOTTLE'],
  ['Supersack', 'SUPERSACK'],
  ['Tetra pack', 'TETRA PACK'],
  ['Tray', 'TRAY'],
  ['Tube', 'TUBE'],
  ['Vac Bag', 'VAC BAG'],
  ['Zip Pak', 'ZIP PAK']
]);

const dateCodeTypes = new Map<string, DateCodeType>([
  ['Best By', DateCodeType.BEST_BY],
  ['Use By', DateCodeType.USE_BY],
  ['Sell By', DateCodeType.SELL_BY],
  ['Freeze By', DateCodeType.FREEZE_BY],
  ['Sell By/Freeze By', DateCodeType.SELL_BY_FREEZE_BY],
  ['Use By/Freeze By', DateCodeType.USE_BY_FREEZE_BY],
  ['Julian Code', DateCodeType.JULIAN_CODE],
  ['Other', DateCodeType.OTHER],
  ['Box/Carton Code', DateCodeType.BOX_CARTON_CODE],
  ['Bag Coding/Case Use By', DateCodeType.BAG_CODING_CASE_USE_BY],
  ['EXP', DateCodeType.EXP],
  ['Best is Used By/Before', DateCodeType.BEST_IS_USED_BY_BEFORE],
  ['MFG Date', DateCodeType.MFG_DATE],
  ['Use By/California Only:Sell By', DateCodeType.USE_BY_CALIFORNIA_ONLY_SELL_BY],
  ['Best Before', DateCodeType.BEST_BEFORE],
  ['Best IF Used By/Before', DateCodeType.BEST_IF_USED_BY_BEFORE],
]);

const weightUom = new Map<string, CaseWeightUom>([
  ['lbs', CaseWeightUom.LBS],
  ['kg', CaseWeightUom.KG],
]);

const sizeUom = new Map<string, CaseSizeUom>([
  ['inch', CaseSizeUom.INCH],
  ['cm', CaseSizeUom.CM],
  ['mm', CaseSizeUom.MM],
]);

const truckSizeRestrictions = new Map<string, TruckSizeRestriction>([
  ['None', TruckSizeRestriction.NONE],
  ['40\'', TruckSizeRestriction._40],
  ['48\'', TruckSizeRestriction._48],
  ['53\'', TruckSizeRestriction._53]
]);

const industryTypes = new Map<string, IndustryType>([
  ['Foodservice', IndustryType.FOODSERVICE],
  ['Retail', IndustryType.RETAIL],
  ['Industrial', IndustryType.INDUSTRIAL],
  ['Non-food', IndustryType.NONFOOD]
]);

const unitSizeUom = new Map<string, UnitSizeUom>([
  ['lbs', UnitSizeUom.LBS],
  ['kg', UnitSizeUom.KG],
  ['oz', UnitSizeUom.OZ],
  ['g', UnitSizeUom.G],
  ['gallon', UnitSizeUom.GALLON],
  ['pint', UnitSizeUom.PINT],
  ['liter', UnitSizeUom.LITER],
  ['quart', UnitSizeUom.QUART],
  ['ct', UnitSizeUom.CT],
  ['fl oz', UnitSizeUom.FL_OZ],
  ['ml', UnitSizeUom.ML],
  ['various', UnitSizeUom.VARIOUS],
]);

const certificateAffilations = new Map<string, CertificateAffiliation>([
  ['Company', CertificateAffiliation.COMPANY],
  ['Product', CertificateAffiliation.PRODUCT]
]);

const leadTimeUom = new Map<string, LeadTimeUom>([
  ['days', LeadTimeUom.DAYS],
  // ['weeks', LeadTimeUom.WEEKS],
  // ['months', LeadTimeUom.MONTHS]
]);

const amountPercents = new Map<string, AmountPercents>([
  ['%', AmountPercents.PERCENT],
  ['$', AmountPercents.AMOUNT],
]);

const shippingStorageTemp = new Map<ShippingStorageTemp, ShippingStorageTemp>([
  [ShippingStorageTemp.CELSIUS, ShippingStorageTemp.CELSIUS],
  [ShippingStorageTemp.FAHRENHEIT, ShippingStorageTemp.FAHRENHEIT]
]);


const timeRange: Map<string, string> = setTimeRanges();

const months: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const years: string[] = calculateYears();



export {
  freightClasses,
  packing,
  dateCodeTypes,
  weightUom,
  sizeUom,
  truckSizeRestrictions,
  industryTypes,
  unitSizeUom,
  timeRange,
  leadTimeUom,
  shippingStorageTemp,
  certificateAffilations,
  months,
  years,
  amountPercents
}



function setTimeRanges(): Map<string, string> {
  const am = 'a.m.';
  const pm = 'p.m.';
  const hoursInDay = 24;
  const timeRanges = new Map();

  for(let hour = 1; hour < hoursInDay; hour++) {
    const dayHalf = hour < (hoursInDay / 2) ? am : pm;
    const convertTime = hour <= (hoursInDay / 2) ? hour : hour - 12;

    const displayHour = formatHour(convertTime);
    const serverHour = formatHour(hour);

    timeRanges.set(`${displayHour}:00 ${dayHalf}`, `${serverHour}:00:00`);
    timeRanges.set(`${displayHour}:30 ${dayHalf}`, `${serverHour}:30:00`);
  }

  timeRanges.set(`12:00 ${am}`, '00:00:00');
  timeRanges.set(`12:30 ${am}`, '00:30:00');

  return timeRanges;

  function formatHour(hour: number): string {
    return hour.toString().length === 1 ? '0' + hour : hour.toString();
  }
}

function calculateYears(): string[] {
  const yearsArray: string[] = [];
  const currentYear = new Date().getFullYear();
  const maxYear = currentYear + 30;

  for(let year = currentYear; year <= maxYear; year++) {
    yearsArray.push(year.toString());
  }

  return yearsArray;
}
