<div
  class="notification-wrap"
  [ngClass]="{'open': isNotificationOpen}"
  (clickOutside)="closeNotificationPopup()"
  [clickOutsideEvents]="'click touchstart'"
  [exclude]="'.header__info-block__notification'"
  [excludeBeforeClick]="true"
>

  <div class="notification-header">
    <app-icon-button
      [matIcon]="MatIconEnum.ARROW_RIGHT"
      [width]="20"
      [height]="20"
      (click)="closeNotificationPopup()"
    ></app-icon-button>
  </div>

  <div class="main-section" *ngIf="notifications">
    <div class="header">
      <span class="title">
        Notifications
        <span *ngIf="activeNotificationsAmount > 0">
          ({{activeNotificationsAmount}})
        </span>
      </span>
    </div>

    <div class="no-notifications" *ngIf="notifications.length === 0">
      <span>There are no notifications yet</span>
    </div>

    <div class="notifications" *ngIf="notifications.length > 0">
      <app-notification-item
        *ngFor="let notification of notifications"
        [notification]="notification"
      ></app-notification-item>
    </div>
  </div>

  <div class="spinner-container" *ngIf="!notifications">
    <mat-spinner></mat-spinner>
  </div>

</div>
