import {Id, Price} from '../types';
import {StockRecordStatus} from './product-status';
import {StockRecordForm} from './stock-record.form';
import {StockRecordFuturePrice} from './stock-record-future-price';
import {Type} from 'class-transformer';
import {decoratorType} from 'lib-shared';
import {LeadTimeUom} from './enums/lead-time-uom.enum';
import { StockRecordFutureLeadTime } from './stock-record-future-lead-time';

export class StockRecord extends StockRecordForm {
  status: StockRecordStatus;
  id: Id;
  product: Id;
  fob_location: ProductStockRecordFobLocation;

  lead_time: number = null;
  lead_time_uom: LeadTimeUom | string = '';

  @Type(decoratorType(StockRecordFuturePrice))
  scheduled_changes: StockRecordFuturePrice[];

  scheduled_lead_time_changes: StockRecordFutureLeadTime[];

  get locationName(): string {
    return `${this.fob_location.name}, ${this.fob_location.city}`;
  }
}

export class ProductStockRecordFobLocation {
  id: Id;
  name: string;
  city: string;
}

export class AddStockRecordRequest {
  fob_location: Id;
  price: Price;
  has_infinite_quantity: boolean;
  case_quantity: number;
  on_sale: boolean;

  lead_time: number = null;
  lead_time_uom: LeadTimeUom | string = LeadTimeUom.DAYS;

  constructor(
    fob_location: Id,
    price: Price = null,
    has_infinite_quantity: boolean = false,
    case_quantity: number = 0,
    on_sale: boolean = false,
    lead_time = null,
    lead_time_uom: string = LeadTimeUom.DAYS
  ) {
    this.fob_location = fob_location;
    this.price = price || null;
    this.has_infinite_quantity = has_infinite_quantity;
    this.case_quantity = case_quantity || 0;
    this.on_sale = on_sale;
    this.lead_time = lead_time;
    this.lead_time_uom = lead_time_uom;
  }
}
