<form
*ngIf="!isDeleted"
[formGroup]="form"
[appForm]="errors.errors"
(submit)="onSave()"
>
  <div class="settings">
    <div class="option1">
        <h3>Mixed Pallet</h3>
        <app-icon-button
          [matIcon]="MatIconEnum.BIN"
          [width]="12"
          [height]="16"
          (click)="deleteMixedPallet(mixedPallet)">
        </app-icon-button>
    </div>
    <div class="option2">
        <p>For all enabled Mixed Pallet products
          <mat-icon
            [style.height]="'16px'"
            [style.width]="'16px'"
            [style.font-size]="'16px'"
            [style.vertical-align]="'bottom'"
            [style.color]="'#9b9b9b'"
            matTooltip="These products were selected to be mixed pallet eligible via the products tab."
            matTooltipPosition="above"
          >info</mat-icon>
        </p>
        <mat-slide-toggle
          formControlName="for_all_products"
          appFormControl
          (change)="toggle($event)"
        >
        </mat-slide-toggle>
    </div>
      <div class="option"  *ngIf="showSelectedProducts">    
        <app-input-wrapper
          title="For Selected Products"
          [formControlNameString]="'for_selected_products'"
          >
          <mat-select
            multiple
            formControlName="for_selected_products"
            appFormControl
          >
            <mat-option
            *ngFor="let product of productList"
            [value]="product.id">
                {{ product.productName }}
            </mat-option>
          </mat-select>
          </app-input-wrapper>
      </div>
      <div class="option">
        <div class="two-inputs">
          <mat-form-field>
            <input
              type="number"
              matInput
              placeholder="Max Weight (lbs)"
              appAreYouSure
              [minQty]="750"
              formControlName="mixed_pallet_max_weight"
              appFormControl
            >
          </mat-form-field>

          <mat-form-field>
            <input
              type="number"
              matInput
              placeholder="Max Height (inch)"
              appAreYouSure
              [minQty]="39"
              formControlName="mixed_pallet_max_height"
              appFormControl
            >
          </mat-form-field>
        </div>
      </div>
      <div
        class="option"
        [appFormControl]="form.controls['minimum_pallet_load_percents']"
      >
        <div>
          <p style="font-size: 15px;">Minimum available pallet capacity</p>
        </div>

        <div class="choices">
          <div
            *ngFor="let percents of minimumAvailablePalletPercents | keyvalue"
            class="percents-button"
            [ngClass]="{'green': +percents.value === +form.controls['minimum_pallet_load_percents'].value}"
            (click)="setMinimumPalletPercents(percents.value)"
          >
            {{ percents.key }}
          </div>
        </div>
      </div>
      <div class="save_button">
        <input type="submit" style="display: none;" />
        <app-button 
          (click)="onSave()">
          Save
        </app-button>
      </div>
  </div>

  <div class="messages">
      <app-message-list
        [messages]="messages"
      ></app-message-list>
  </div>
</form>