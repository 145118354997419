<div
  class="notification"
  [ngClass]="{'active': !notification.seen}"
  (mouseover)="setNotificationAsViewed()"
>
  <span class="notification__header">
    <span class="type">
      <img src="../../../../../../assets/images/paper-icon.svg" alt="paper-icon">
      <span>{{notification.title}}</span>
    </span>
    <span class="date">
      <img src="../../../../../../assets/images/clock-icon.svg" alt="clock-icon">
      <span>{{DateHelper.getTimeFromNow(notification.date)}}</span>
    </span>
  </span>

  <span class="notification__body" [innerHTML]="notification.message"></span>

  <app-message-list
    [messages]="messages"
  ></app-message-list>
</div>
