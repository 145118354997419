<div class="app">
  <app-main-navigation></app-main-navigation>

  <div class="main-wrapper">
    <div
      class="main-container"
      [ngClass]="{'full-width': !isMenuOpened, 'without-menu': !showMenu}"
    >
      <!-- <app-top-message></app-top-message> -->
      <router-outlet></router-outlet>
    </div>
  </div>

  <app-terms-of-use></app-terms-of-use>
  <app-registration-finish-modal></app-registration-finish-modal>
  <app-subscription-change-modal></app-subscription-change-modal>
  <app-go-live-modal></app-go-live-modal>
</div>

<app-site-tour *ngIf="showTours"></app-site-tour>
