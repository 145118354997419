import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ClipboardModule} from 'ngx-clipboard';
import {MatSnackBarModule} from '@angular/material';
import { CopyToClipboardDirective } from './copy-to-clipboard.directive';

@NgModule({
  declarations: [CopyToClipboardDirective],
  exports: [CopyToClipboardDirective],
  imports: [
    CommonModule,
    ClipboardModule,
    MatSnackBarModule,
  ]
})
export class CopyToClipboardModule { }
