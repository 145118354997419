import {HttpParams} from '@angular/common/http';
import {FilterQueryFilters} from './filter-query-filters';
import {FilterChoice} from './filter-choice';
import { FilterObjectType } from './filter-object-type.enum';

export class FilterQuery {
  query: FilterQueryFilters;

  constructor(query: FilterQueryFilters = new FilterQueryFilters()) {
    this.query = query;
  }


  get hasSelectedFilters(): boolean {
    return Object
      .keys(this.query)
      .some(key => this.queryParamHasSelectedOptions(key));
  }

  get filterCategoriesAmount(): number {
    return Object
      .keys(this.query)
      .filter(key => this.queryParamHasSelectedOptions(key))
      .length;
  }


  queryParamHasSelectedOptions(queryParam: string): boolean {
    return !!this.query[queryParam]  && Array.isArray(this.query[queryParam])
      ? this.query[queryParam].filter(val => !!val).length > 0
      : false;
  }

  queryParamHasValue(queryParam: string, choice: FilterChoice): boolean {
    return this.query[queryParam] && this.query[queryParam].includes(choice.value.toString());
  }

  queryParamNotExist(queryParam: string): boolean {
		return !this.query[queryParam] || !this.query[queryParam].length || !this.query[queryParam].filter(item => !!item).length;
	}

  getFiltersHttpParams(params: HttpParams = new HttpParams()): HttpParams {
    if(this.query && Object.keys(this.query).length) {
      for(const filter in this.query) {
        if(this.query.hasOwnProperty(filter) && !!this.query[filter]) {
          params = params.append(filter, this.query[filter].join(','));
        }
      }
    }

    return params;
  }

  resetQuery(): void {
    this.query = new FilterQueryFilters();
  }

  toggleParameterValue(query_param: string, value: string, type:null): void {

    if (value == '' && this.query[query_param]){
      delete this.query[query_param];
      return; 
    }

    if(!this.query[query_param]) {
      this.query[query_param] = [];
    }

    const indexOfValue = this.query[query_param].indexOf(value);

    if(indexOfValue === -1) {
      if(type == FilterObjectType.SELECT_RADIO ){
        this.query[query_param] = [value];
      }
      else{
        this.query[query_param].push(value);
      }
    } else {
      this.query[query_param].splice(indexOfValue, 1);
    }
  }

  setSingleParameterValue(query_param: string, value: string): void {
    this.query[query_param] = value ? [value] : [];
  }

  setMultipleSelectValue(query_param: string, value: string[]): void {
    this.query[query_param] = value;
  }

  changeRangeParameter(query_param: string, [from, to]): void {
    this.query[query_param] = [];

    if(!!from && !!to) {
      this.query[query_param] = this.query[query_param].concat([from, to]);
    }
  }
}
