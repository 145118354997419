import {UrlPath} from '../../types';
import {BackendFile} from '../../../modules/blocks/file-uploader/models/backend-file';


export class ProductMedia extends BackendFile {
  thumbnail: UrlPath;

  constructor(file: UrlPath, thumbnail: UrlPath) {
    super(file);
    this.thumbnail = thumbnail;
  }
}
