import {BehaviorSubject, Observable} from 'rxjs';

export class ModalService<T> {

  private _showModal = new BehaviorSubject<T>(null);


  get showModalObservable(): Observable<T> {
    return this._showModal.asObservable();
  }


  openModal(data: T): void {
    this._showModal.next(data);
  }

  closeModal(): void {
    this._showModal.next(null);
  }
}
