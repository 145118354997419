import {Id} from '../types';
import {StockRecordStatus} from './product-status';
import {Type} from 'class-transformer';
import {decoratorType} from 'lib-shared';
import {StockRecord} from './stock-record';
import {CommonProductCategory} from '../common/common-category';
import {environment} from '../../../../environments/environment';
import {Restrictive} from './restrictive';

export class ProductItemAbstract extends Restrictive {
  id: Id = null;
  slug = '';
  name = '';
  upc = '';
  status: StockRecordStatus = StockRecordStatus.INCOMPLETE;

  pack_size = '';
  mfg_item = '';

  @Type(decoratorType(StockRecord))
  stock_records: StockRecord[] = [];

  missed_fields: string[] = [];
  catch_weight = false;



  get isProductLoose(): boolean {
    return this.catch_weight;
  }

  get isProductCompleted(): boolean {
    return this.status !== StockRecordStatus.INCOMPLETE;
  }

  get isProductHaveMultipleRecords(): boolean {
    return this.stock_records.length > 0;
  }

  get isProductInSale(): boolean {
    return this.stock_records.some(record => record.status === StockRecordStatus.ON);
  }

  get productErrors(): string {
    const errors: string[] = [];

    if(!this.isProductCompleted) {
      errors.push('is incompleted');
    }

    if(!this.isProductInSale) {
      errors.push('is not active');
    }

//     if(this.hasCustomerGroupsRestrictions) {
//       errors.push('is visible only for certain groups'); // for embed code
//     }

    if(this.hasCustomerTypesRestrictions) {
      errors.push('is visible only for certain types');
    }
    if(this.hasStatesRestrictions || this.hasZipCodeRestrictions) {
      errors.push('is visible only for certain regions'); // for embed code
    }

    return errors.length > 0 ? `This product ${errors.join(', ')}` : null;
  }

  get customerProductPage(): string {
    return `/product/${this.slug}`;
  }


  getCategoryAndSubcategoryBySubcategoryId(subcategoryId: Id, categories: CommonProductCategory[]): CategoryPair {
    let _subcategory: CommonProductCategory;
    const _category = categories.find(category => {
      _subcategory = category.children.find(subcategory => subcategory.id === subcategoryId);
      return !!_subcategory;
    });

    return new CategoryPair(_category, _subcategory);
  }
}


class CategoryPair {
  category: CommonProductCategory;
  subcategory: CommonProductCategory;

  constructor(category: CommonProductCategory, subcategory: CommonProductCategory) {
    this.category = category;
    this.subcategory = subcategory;
  }
}
