import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SubscriptionPlan} from '../shared/models/subscription-plan.enum';
import {VendorStatusService} from '../../../shared/services/vendor-status/vendor-status.service';
import {SubscriptionsService} from '../shared/services/subscriptions.service';
import {first} from 'rxjs/operators';
import {ObservableComponent} from 'lib-shared';
import {SubscriptionPlanDto} from '../shared/models/subscription-plan.dto';

@Component({
  selector: 'app-subscriptions-list',
  templateUrl: './subscriptions-list.component.html',
  styleUrls: ['./subscriptions-list.component.scss']
})
export class SubscriptionsListComponent extends ObservableComponent implements OnInit {

  @Output()
  onPlanChange = new EventEmitter<SubscriptionPlan>();

  plans: SubscriptionPlanDto[];
  selectedPlan: SubscriptionPlan;

  constructor(
    private _vendorStatusService: VendorStatusService,
    private _subscriptionsService: SubscriptionsService
  ) {
    super();
  }

  ngOnInit() {
    this._getSubscriptionPlans();
    this._getInitialPlan();
  }


  changePlan(plan: SubscriptionPlan): void {
    this.selectedPlan = plan;
    this.onPlanChange.emit(plan);
  }


  private _getInitialPlan(): void {
    this._vendorStatusService
      .vendorStatusObservable
      .pipe(
        first(),
        this._takeWhileComponentAlive()
      )
      .subscribe(status => {
        this.selectedPlan = status.subscription_plan;
        this.changePlan(this.selectedPlan);
      });
  }

  private _getSubscriptionPlans(): void {
    this.plans = this._subscriptionsService.subscriptionPlansArrayAvailableToChoose;
  }

}
