import { Id } from "lib-shared/shared/models/types";
import { MyIsNotEmpty, MyMin } from "../../../modules/form-validation/decorators";

export class ChargePerPalletForm {
  id : Id = null;

  @MyIsNotEmpty()
  // @MyMin(0)
  charge_per_pallet = 0;

  for_selected_products: Array<Id> = [];
  for_all_products: boolean = true;
}