import {CardType} from '../models/card-type.enum';

const PAYMENT_CARD_LOGOS_FOLDER = '/assets/images/card-logos';
const logos = {
  [CardType.VISA]: 'visa.svg',
  [CardType.MASTERCARD]: 'mastercard.svg',
  [CardType.JCB]: 'jcb.svg',
  [CardType.DISCOVER]: 'discover.svg',
  [CardType.DINNERS_CLUB]: 'diners-club.svg',
  [CardType.AMERICAN_EXPRESS]: 'american-express.svg',
  [CardType.UNION_PAY]: 'union-pay.svg',
};

export {PAYMENT_CARD_LOGOS_FOLDER, logos};
