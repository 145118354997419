<app-header></app-header>
<div class="main-wrapper-top">

  <form
  *ngIf="!!form && !!errors"
  [formGroup]="form"
  [appForm]="errors.errors"
  (submit)="updateBusinessRules()"
>
    <div
      *ngIf="showSpinner"
      class="spinner-container"
    >
      <mat-spinner></mat-spinner>
    </div>

    <mat-tab-group mat-stretch-tabs>
      <mat-tab label="Order Minimum Settings">
        <ng-template matTabContent>
          <div class="order-settings" style="margin-left: 1%;">
            <app-charge-per-pallet
            *ngFor="let chargeperpallet of chargePerPalletServiceList"
            [chargeperpallet]="chargeperpallet"
            (refreshList)="getChargePerPallet()"
            ></app-charge-per-pallet>

            <div class="message-list">
              <app-message-list
                [messages]="charge_per_pallet_setting_messages"
              ></app-message-list>
            </div>

            <div class="add_button">
              <app-icon-button 
              [matIcon]="MatIconEnum.ADD"
              [height]="15"
              (click)="addChargePerPallet(chargeperpallet)"
              ></app-icon-button>
            </div>

            <app-order-minimum-quantity
            *ngFor="let orderquantity of orderQuantityServiceList"
            [orderquantity]="orderquantity"
            (refreshList)="getOrderQuantity()"
            ></app-order-minimum-quantity>

            <div class="message-list">
              <app-message-list
                [messages]="order_quantity_setting_messages"
              ></app-message-list>
            </div>

            <div class="add_button">
              <app-icon-button 
              [matIcon]="MatIconEnum.ADD"
              [height]="15"
              (click)="addOrderQuantity(orderquantity)"
              ></app-icon-button>
            </div>
          </div>

          <div class="order-settings">

            <app-order-minimum-weight
              *ngFor="let orderweight of orderWeightServiceList"
              [orderweight]="orderweight"
              (refreshList)="getOrderWeight()"
              >
            </app-order-minimum-weight>

            <div class="message-list">
              <app-message-list
                [messages]="order_weight_setting_messages"
              ></app-message-list>
            </div>

            <div class="add_button">
              <app-icon-button 
              [matIcon]="MatIconEnum.ADD"
              [height]="15"
              (click)="addOrderWeight(orderweight)"
              ></app-icon-button>
            </div>

            <app-order-minimum-case-quantity
            *ngFor="let ordercasequantity of orderCaseQuantityServiceList"
            [ordercasequantity]="ordercasequantity"
            (refreshList)="getOrderCaseQuantity()"
            ></app-order-minimum-case-quantity>

            <div class="message-list">
              <app-message-list
                [messages]="order_case_quantity_setting_messages"
              ></app-message-list>
            </div>

            <div class="add_button">
              <app-icon-button 
              [matIcon]="MatIconEnum.ADD"
              [height]="15"
              (click)="addOrderCaseQuantity(ordercasequantity)"
              ></app-icon-button>
            </div>

          </div>
          
        </ng-template>
      </mat-tab>

      <mat-tab label="Mixed Pallet Settings">
        <ng-template matTabContent>
          <div class="order-settings" style="margin-left: 1%;">

            <h1>Mixed Pallet Settings</h1>
              <app-mixed-pallet-setting
                *ngFor="let mixedPallet of mixPalletServiceList"
                [mixedPallet]="mixedPallet"
                (refreshList)="getMixpallet()"
              ></app-mixed-pallet-setting>

              <div class="message-list">
                <app-message-list
                  [messages]="mixed_pallet_setting_messages"
                ></app-message-list>
              </div>

              <div class="add_button">
                <app-icon-button 
                [matIcon]="MatIconEnum.ADD"
                [height]="15"
                (click)="addMixedPallet(mixedPallet)"
                ></app-icon-button>
              </div>
            </div>

          <div class="order-settings">
            <div class="charge-settings1">
              <div class="option1">
                <p>Mixed Pallet OVERRIDE for ALL products
                  <mat-icon
                  [style.height]="'16px'"
                  [style.width]="'16px'"
                  [style.font-size]="'16px'"
                  [style.vertical-align]="'bottom'"
                  [style.color]="'#9b9b9b'"
                  matTooltip="If selected, ALL products will be eligible for mixed pallet."
                  matTooltipPosition="above"
                  >info</mat-icon>
                </p>
                
                <mat-slide-toggle
                  formControlName="all_products_for_mixed_pallet"
                  appFormControl
                  (click)="showPopUp()"
                ></mat-slide-toggle>
              </div>

              <div class="option2">
                <div>
                  <h3>Charge per case in Mixed Pallet</h3>
                </div>

                <div class="amount_dropdown">
                <mat-form-field >
                  <mat-select
                    formControlName="mixed_pallet_charge_per_case_type"
                    appFormControl
                  >
                    <mat-option
                      *ngFor="let amp of amountPercents | keyvalue"
                      [value]="amp.value"
                    >
                      {{amp.key}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

                <mat-form-field>
                  <input
                    type="number"
                    matInput
                    formControlName="mixed_pallet_charge_per_case"
                    appFormControl
                  >
                </mat-form-field>
              </div>
              <div class="save_button">
                <input type="submit" style="display: none;" />
                <app-button
                    class="save-button"
                    (click)="updateBusinessRules()"
                  >
                    Save
                </app-button>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
</form>
</div>