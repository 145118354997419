import { Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'actions-dialog',
  templateUrl: './actions-dialog.component.html',
  styleUrls: ['./actions-dialog.component.scss']
})
export class ActionsDialog {

  constructor(
    public dialogRef: MatDialogRef<ActionsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: {msg: string, buttons: string[], closeButton: boolean}
  ) {}

}
