import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-mark-in-circle-logo',
  templateUrl: './mark-in-circle-logo.component.html',
  styleUrls: ['./mark-in-circle-logo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MarkInCircleLogoComponent {

  @Input()
  mark: string;

}
