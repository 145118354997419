import {Injectable} from '@angular/core';
import {ProductMediaServiceAbstract} from '../../../models/product-media-service.abstract';
import {ProductMedia} from '../../../../../shared/models/product/product-media/product-media';
import {Id} from '../../../../../shared/models/types';
import {MediaPathIds} from '../../../models/media-path-ids';
import {MediaOrderingRequest} from '../../../../../shared/models/product/product-media/media-ordering-request';
import {map} from 'rxjs/internal/operators';
import {plainToClass} from 'class-transformer';


@Injectable()
export class ProductImagesService extends ProductMediaServiceAbstract {


  async addFile(multipartData, ids: MediaPathIds): Promise<ProductMedia> {
    const url = this._getUrl(ids);

    return this._http
      .post<ProductMedia>(url, multipartData)
      .pipe(
        map(media => plainToClass(ProductMedia, media))
      )
      .toPromise();
  }

  async deleteFile(fileId: Id, ids: MediaPathIds): Promise<void> {
    const url = `${this._getUrl(ids)}/${fileId}`;

    return this._http
      .delete<void>(url)
      .toPromise();
  }

  async changeFilesOrder(fileIds: Id[], ids: MediaPathIds): Promise<ProductMedia[]> {
    const url = `${this._getUrl(ids)}/ordering`;
    const body = new MediaOrderingRequest(fileIds);

    return this._http
      .post<ProductMedia[]>(url, body)
      .pipe(
        map(media => plainToClass(ProductMedia, media))
      )
      .toPromise();
  }

  async editFile(ids: MediaPathIds, fileId: Id, multipartData): Promise<ProductMedia> {
    const url = `${this._getUrl(ids)}/${fileId}`;

    return this._http
      .patch<ProductMedia>(url, multipartData)
      .pipe(
        map(media => plainToClass(ProductMedia, media))
      )
      .toPromise();
  }


  protected _getUrl(ids: MediaPathIds): string {
    return `${super._getUrl(ids)}/images`;
  }

}
