import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Element as StripeElement, Elements, StripeService, TokenResult} from 'ngx-stripe';
import {applyMixins, ObservableComponent} from 'lib-shared';
import {Messages} from '../../../../helpers/messages';
import {PaymentsService} from '../shared/services/payments.service';
import {Payment} from '../shared/models/payment';
import {Colors} from '../../../../constants/colors';
import {ButtonColorScheme} from '../../../ux/button/models/button-color-scheme.enum';
import {StripeElementType} from '../shared/models/stripe-element-type.enum';
import {MessageStyle} from '../../../ux/message/models/enums/message-style.enum';
import {TourTypes} from '../../../../../site-tour/models/tour-types';
import {TourImplementedComponent} from '../../../../../site-tour/shared/tour-implemented.component';
import {MonoTypeOperatorFunction} from 'rxjs';
import {TourService} from '../../../../../site-tour/services/tour.service';

@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.scss']
})
class AddPaymentComponent extends ObservableComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output()
  onPaymentAdd = new EventEmitter<Payment>();

  @Output()
  onClose = new EventEmitter<void>();

  @Input()
  insideGoLiveModal: boolean = false;

  readonly TourTypes = TourTypes;

  showSpinner = false;

  cardErrors = {
    [StripeElementType.CARD_NUMBER]: false,
    [StripeElementType.CARD_EXPIRY]: false,
    [StripeElementType.CARD_CVC]: false
  };

  cardIds = {
    [StripeElementType.CARD_NUMBER]: 'card-number',
    [StripeElementType.CARD_EXPIRY]: 'card-expiration',
    [StripeElementType.CARD_CVC]: 'card-cvc'
  };

  readonly messages = new Messages();
  readonly ButtonColorScheme = ButtonColorScheme;
  readonly StripeElementType = StripeElementType;
  readonly messagesStyle = MessageStyle.TEXT;

  private _elements: Elements;

  private _cardNumber: StripeElement;
  private _cardExpiration: StripeElement;
  private _cardCvc: StripeElement;

  private _elementStyles = {
    base: {
      fontFamily: '"DecimaNovaPro", sans-serif',
      fontSize: '14px',
      color: Colors.TEXT_GRAY,
      fontSmoothing: 'antialiased',
      textAlign: 'center',

      ':focus': {
        color: Colors.MAIN_BLACK,
      },

      '::placeholder': {
        color: Colors.TEXT_GRAY,
      },

      ':focus::placeholder': {
        color: '#CFD7DF',
      },
    },
    invalid: {
      color: Colors.MAIN_RED,
      ':focus': {
        color: Colors.MAIN_RED,
      },
      '::placeholder': {
        color: Colors.MAIN_RED,
      },
    },
  };

  // TourImplementedComponent
  private _tourTypes: TourTypes[] = [
    TourTypes.ADD_PAYMENT_CARD,
    TourTypes.GO_LIVE
  ];

  private _tourItemIndex = 3;

  _subscribeOnCurrentTour: () => MonoTypeOperatorFunction<any>;
  _setClickHandler: (type: TourTypes, index, delay? ) => MonoTypeOperatorFunction<any>;

  constructor(
    private _stripeService: StripeService,
    private _paymentsService: PaymentsService,
    protected _tourService: TourService
  ) {
    super();
  }

  ngOnInit() {
    this._setupIds();
  }

  ngAfterViewInit(): void {
    this._generateStripeElements();
    setTimeout(() => {
      this._subscribeOnCurrentTour();
    }, 0);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._unmountStripeElements();
  }


  async addCard(): Promise<void> {
    if(!this.showSpinner) {
      this.messages.clearMessages();
      this.showSpinner = true;
      try {
        const {token, error} = await this._getStripeToken();

        if(error) {
          throw {[Messages.BACKEND_ERROR_KEY]: error.message};
        } else {
          const newPayment = await this._paymentsService.addPayment(token);
          this._clearStripeElements();
          this.onPaymentAdd.emit(newPayment);
          if( this.insideGoLiveModal ) {
            this._tourService.closeAllTours();
          } else {
            this._setClickHandler(this._tourTypes[0], this._tourItemIndex, 500);
          }
        }
      } catch(err) {
        const error = err.error || err;
        this.messages.setBackendErrors(error);
      }

      this.showSpinner = false;
    }
  }

  closeAdding(): void {
    this._clearStripeElements();
    this.messages.clearMessages();
    this.onClose.emit();
  }


  private _generateStripeElements(): void {
    this._stripeService
      .elements({
        fonts: [
          {
            cssSrc: '/assets/fonts/DecimaNovaPro/font-face.css',
          },
        ]
      })
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(elements => {
        this._elements = elements;
        this._generateCardNumber();
        this._generateExpiration();
        this._generateCvc();
      });
  }

  private _generateCardNumber(): void {
    if (!this._cardNumber) {
      const style = JSON.parse(JSON.stringify(this._elementStyles));
      style.base['textAlign'] = 'left';
      this._cardNumber = this._elements.create(StripeElementType.CARD_NUMBER, {
        style,
        placeholder: 'Card number'
      });
      this._cardNumber.on('change', this._stripeFieldChangeHandler(StripeElementType.CARD_NUMBER));
      this._cardNumber.mount(`#${this.cardIds[StripeElementType.CARD_NUMBER]}`);
    }
  }

  private _generateExpiration(): void {
    if (!this._cardExpiration) {
      this._cardExpiration = this._elements.create(StripeElementType.CARD_EXPIRY, {
        style: this._elementStyles
      });
      this._cardExpiration.on('change', this._stripeFieldChangeHandler(StripeElementType.CARD_EXPIRY));
      this._cardExpiration.mount(`#${this.cardIds[StripeElementType.CARD_EXPIRY]}`);
    }
  }

  private _generateCvc(): void {
    if (!this._cardCvc) {
      this._cardCvc = this._elements.create(StripeElementType.CARD_CVC, {
        style: this._elementStyles
      });
      this._cardCvc.on('change', this._stripeFieldChangeHandler(StripeElementType.CARD_CVC));
      this._cardCvc.mount(`#${this.cardIds[StripeElementType.CARD_CVC]}`);
    }
  }

  private _clearStripeElements(): void {
    this._cardNumber.clear();
    this._cardExpiration.clear();
    this._cardCvc.clear();
  }

  private _unmountStripeElements(): void {
    this._cardNumber.unmount();
    this._cardExpiration.unmount();
    this._cardCvc.unmount();
  }

  private _getStripeToken(): Promise<TokenResult> {
    return new Promise<TokenResult>((resolve) => {
      this._stripeService
        .createToken(this._cardNumber, {})
        .pipe(
          this._takeWhileComponentAlive()
        )
        .subscribe(result => resolve(result));
    });
  }

  private _stripeFieldChangeHandler(cardErrorsField: StripeElementType): (event) => void {
    return event => {
      this.messages.clearMessages();
      const err = event.error;
      this.cardErrors[cardErrorsField] = !!err;

      if(err) {
        this.messages.addErrorMessage(err.message);
      }
    };
  }

  private _setupIds(): void {
    Object
      .keys(this.cardIds)
      .forEach(key => this.cardIds[key] += Date.now().toString());
  }

}

applyMixins(AddPaymentComponent, [TourImplementedComponent]);

export {AddPaymentComponent}
