import {TourTypes} from '../models/tour-types';
import {TourService} from '../services/tour.service';
import {ActiveTourPointer} from '../models/active-tour-pointer.model';
import {filter} from 'rxjs/operators';
import {OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import { ObservableComponent } from 'lib-shared';

export class TourImplementedComponent extends ObservableComponent implements OnDestroy {

  protected _tourTypes: TourTypes[];
  protected _tourItemIndex: number | number[];
  protected _activeTourSubscription: Subscription;

  constructor(
    protected _tourService: TourService
  ) { super();}

  private async _checkActiveTours(): Promise<void> {
    // @ts-ignore
    for (const tour: TourTypes of this._tourTypes) {
      if( typeof this._tourItemIndex === 'object' ) {
        this._tourItemIndex.forEach( async index => {
          await this._tourService.checkUnfinishedTour( tour, index );
        } );
      } else {
        await this._tourService.checkUnfinishedTour( tour, this._tourItemIndex );
      }
    }
  }

  private _handleTourAction(type, index): void {
    this._tourService.showNext(type, index + 1);
  }

  // used as mixin
  protected _subscribeOnCurrentTour(): void {
    this._unsubsribeFromCurrentTour();
    this._activeTourSubscription = this._tourService.tourCurrentAsObservable
      .pipe(
        filter(type => !!type)
      )
      .subscribe( ( pointer: ActiveTourPointer ) => {
        this._checkActiveTours();
      } );
  }

  protected _setClickHandler(type, index, delay = 0): void {
    setTimeout(() => {
      this._handleTourAction(type, index);
    }, delay);
  }

  protected _toggleNextTour(type, index): void {
    this._tourService.setActiveTour(type, index);
  }

  protected _setDataAttribute(selector, value): void { // used for setting data-tour attribute for elements with closed sourcecode
    const e = document.querySelector(selector);
    if (e && 'setAttribute' in e) e.setAttribute('data-tour', value);
  }

  protected _unsubsribeFromCurrentTour(): void {
    !!this._activeTourSubscription && ( this._activeTourSubscription.unsubscribe() );
  }

  ngOnDestroy(): void {
   this._unsubsribeFromCurrentTour();
  }

}
