import {NgModule, ModuleWithProviders} from '@angular/core';
import { CommonModule } from '@angular/common';
import {StaticDataService} from './services/static-data.service';
import {SpinnerService} from './services/spinner.service';
import {VendorStatusGuardService} from './services/guards/vendor-status-guard.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: []
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return{
      ngModule: CoreModule,
      providers: [
        VendorStatusGuardService,
        StaticDataService,
        SpinnerService
      ]
    };
  }

}
