export enum ProfileTab {
  // ACCOUNT,
  COMPANY_INFORMATION,
  // PAYMENT_DETAILS,
  // COUPONS,
  LOCATIONS,
  // DISCOUNT_GROUP,
  CERTIFICATES,
  CHANGE_PASSWORD
}
