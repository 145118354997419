import {ClassType} from 'class-transformer/ClassTransformer';
import {ClassTransformOptions} from 'class-transformer/ClassTransformOptions';
import {plainToClass} from 'class-transformer';

function setDefaults<V>(object: V, defaultObject: V) {
  for (const key in defaultObject) {
    if(defaultObject.hasOwnProperty(key) && object.hasOwnProperty(key)) {
      const objValue: any = object[key];
      const defaultValue: any = defaultObject[key];
      const isObjValueFalsy = objValue === '' ||
        objValue === undefined ||
        objValue === null ||
        (Array.isArray(objValue) && objValue.length === 0);

      if(isObjValueFalsy) {
        object[key] = defaultObject[key];
      } else if(typeof objValue === 'object' && typeof defaultValue === 'object') {
        setDefaults(object[key], defaultObject[key]);
      } else if(Array.isArray(objValue) && (Array.isArray(defaultValue) && defaultValue.length > 0)) {
        (object[key] as any).forEach(obj => setDefaults(obj, defaultObject[key][0]));
      }
    }
  }
}


export function plainToClassSetDefaults<T, V extends Array<any>>(cls: ClassType<T>, plain: V, options?: ClassTransformOptions): T[];
export function plainToClassSetDefaults<T, V>(cls: ClassType<T>, plain: V, options?: ClassTransformOptions): T;
export function plainToClassSetDefaults<T, V>(cls: ClassType<T>, plain: V | V[], options?: ClassTransformOptions): T | T[] {
  const object = plainToClass(cls, plain, options);
  const defaultObject = new cls();

  if (object instanceof Array) {
    object.forEach(obj => setDefaults(obj, defaultObject));
  } else {
    setDefaults(object, defaultObject);
  }

  return object;
}
