<app-modal
	*ngIf="showErrorModal"
	@fadeInOut
	class="email-verification"
	(onCloseModal)="closeModal()"
>
	<div *ngIf="!resendLinkEMail" class="container">
		<span class="title">Link is invalid!</span>
		<span class="bottom-messages">
			<a
				class="link bottom-message"
				(click)="closeModal()"
			>
				Return to home page
			</a>
		</span>
	</div>

	<div
		*ngIf="!!resendLinkEMail"
		class="container"
	>
		<span class="title">Your link has expired!</span>
		<span class="subtitle">Generate a new link to verify your account</span>

		<div class="buttons">
			<app-button
				[showSpinner]="showGenerateButtonSpinner"
				(click)="generateNewVerificationLink()"
			>
				Generate a new link
			</app-button>
		</div>

		<div
			*ngIf="messages.hasMessages"
			class="message-list"
		>
			<app-message-list
				[messages]="messages"
			></app-message-list>
		</div>
	</div>
</app-modal>
