import { MatDialog } from "@angular/material";
import { Id } from "lib-shared/shared/models/types";
import { ApiHttpService } from "../../../core/ApiHttp/api-http.service";
import { plainToClassSetDefaults } from "../../helpers/plain-to-class-defaults";
import { StringifyCircularObject } from "../../helpers/StringifyCircularObject";
import { OrderQuantityForm } from "../../models/product/product-list/order-quantity.form";
import { ApiService} from '../api';
import {map} from 'rxjs/internal/operators';

export class OrderQuantityService  {
      
    private readonly _URL: string;

    constructor(
        private api: ApiService,
        private http: ApiHttpService,
        private dialog: MatDialog

    ) {
        this._URL ='vendor/order_quantity_setting';
    }
   
    getOrderQuantity(): Promise<OrderQuantityForm[]> {
        return this.http.get<OrderQuantityForm[]>(this._URL).toPromise();
    }

    async editOrderQuantity(orderquantity: OrderQuantityForm | any): Promise<OrderQuantityForm> {
        const url = `${this._URL}/${orderquantity.id}`;
    
        const productProcessed = JSON.parse( StringifyCircularObject(orderquantity) );
        
        return this.http
            .put<OrderQuantityForm>(url, productProcessed)
            .pipe(
                map(newOrder => plainToClassSetDefaults(OrderQuantityForm, newOrder))
                )
            .toPromise();
        }
    
        
    async addOrderQuantity(orderquantity: OrderQuantityForm): Promise<OrderQuantityForm> {
    return this.http
        .post<OrderQuantityForm>(this._URL, orderquantity)
        .pipe(
            map(newOrderQuantity => plainToClassSetDefaults(OrderQuantityForm, newOrderQuantity))).
            toPromise();
    }

    async deleteOrderQuantity(id: Id): Promise<void> {
        const url = `${this._URL}/${id}`;

        return this.http
            .delete<void>(url).toPromise();
        }   

    }