import { Injectable } from '@angular/core';
import {plainToClass} from 'class-transformer';
import {map} from 'rxjs/internal/operators';
import {AddStockRecordRequest, StockRecord} from '../../models/product/stock-record';
import {Id} from '../../models/types';
import { ApiHttpService } from '../../../core/ApiHttp/api-http.service';
import {StockRecordForm} from '../../models/product/stock-record.form';


@Injectable()
export class StockRecordsService {

  private _url_stock_records: (productId: Id, recordId?: Id) => string;

  constructor(
    private http: ApiHttpService,
  ) {
    this._url_stock_records = (product_id: Id, recordId?: Id) => {
      return `vendor/products/${product_id}/stock_records${!!recordId ? `/${recordId}` : ''}`;
    };
  }



  async addStockRecordToProduct(
    productId: Id,
    fobId: Id
  ): Promise<StockRecord> {
    const url = this._url_stock_records(productId);
    const data = new AddStockRecordRequest(fobId);

    return this.http
      .post<StockRecord>(url, data)
      .pipe(
        map(stock_record => plainToClass(StockRecord, stock_record))
      )
      .toPromise();
  }

  async getStockRecord(productId: Id, recordId: Id): Promise<StockRecord> {
    const url = this._url_stock_records(productId, recordId);

    return this.http
      .get<StockRecord>(url)
      .pipe(
        map(stock_record => plainToClass(StockRecord, stock_record))
      )
      .toPromise();
  }

  async editProductStockRecord(
    productId: Id,
    recordId: Id,
    record: StockRecordForm
  ): Promise<StockRecord> {
    const url = this._url_stock_records(productId, recordId);

    return this.http
      .put<StockRecord>(url, record)
      .pipe(
        map(stock_record => plainToClass(StockRecord, stock_record))
      )
      .toPromise();
  }

  async deleteProductStockRecord(productId: Id, stockRecordId: Id): Promise<void> {
    const url = this._url_stock_records(productId, stockRecordId);

    return this.http
      .delete<void>(url)
      .toPromise();
  }

}
