import {Injectable} from '@angular/core';
import {ApiService} from '../../../../shared/services/api';
import {Observable} from 'rxjs';
import {FacilityForm} from '../models/facility.form';
import {HttpClient} from '@angular/common/http';
import {Id} from '../../../../shared/models/types';
import {plainToClass} from 'class-transformer';
import {map} from 'rxjs/internal/operators';


@Injectable()
export class FobLocationsService {

  private readonly _URL: string;



  constructor(
    private _api: ApiService,
    private _http: HttpClient
  ) {
    this._URL = `${_api.API}vendor/fob_locations`;
  }


  async getAllFobLocation(): Promise<FacilityForm[]> {
    return this._http
      .get<FacilityForm[]>(this._URL)
      .pipe(
        map(facility => plainToClass(FacilityForm, facility))
      )
      .toPromise();
  }

  async addFobLocation(facility: FacilityForm): Promise<FacilityForm> {
    return this._http
      .post<FacilityForm>(this._URL, facility)
      .pipe(
        map(facil => plainToClass(FacilityForm, facil))
      )
      .toPromise();
  }

  async editFobLocation(facilityId: Id, changedFacility: FacilityForm): Promise<FacilityForm> {
    const url = `${this._URL}/${facilityId}`;

    return this._http
      .put<FacilityForm>(url, changedFacility)
      .pipe(
        map(facility => plainToClass(FacilityForm, facility))
      )
      .toPromise();
  }

  async removeFobLocation(id: Id): Promise<void> {
    const url = `${this._URL}/${id}`;

    return this._http
      .delete<void>(url)
      .toPromise();
  }

}
