import {Injectable} from '@angular/core';
import {ApiService} from './api';
import {HttpClient} from '@angular/common/http';
import {VendorCertificate} from '../../registration/certificates/shared/models/certificate';
import {classToPlain, plainToClass} from 'class-transformer';
import {Id} from '../models/types';
import {CertificatesList} from '../../registration/certificates/shared/models/certificates-list';
import {map, tap} from 'rxjs/internal/operators';
import {CertificateAccessRequestDto} from '../../registration/certificates/shared/models/certificate-access-request.dto';


@Injectable()
export class CertificatesService {

  private _certificates: CertificatesList;

  private readonly _URL: string;



  constructor(
    private _api: ApiService,
    private _http: HttpClient
  ) {
    this._URL = `${_api.API}vendor/certificates`;
  }



  async getCertificates(refresh: boolean = false): Promise<CertificatesList> {
    if (this._certificates && !refresh) {
      return this._certificates;
    } else {
      return this._http
        .get<CertificatesList>(this._URL)
        .pipe(
          map(certificates => plainToClass(CertificatesList, certificates)),
          tap(certificates => this._certificates = certificates)
        )
        .toPromise();
    }
  }

  async getCertificateByName(name: string): Promise<VendorCertificate> {
    const url = this._URL + '?name=' + name;
    return this._http
        .get<VendorCertificate>(url)
        .pipe(
          map(cert => plainToClass(VendorCertificate, cert))
        )
        .toPromise();
  }

  async addCertificate(certificate: VendorCertificate, file: File): Promise<VendorCertificate> {
    const formData = new FormData();
    const plainCertificate = classToPlain(certificate);

    for(const i in plainCertificate) {
      if(plainCertificate.hasOwnProperty(i)) {
        formData.append(i, plainCertificate[i]);
      }
    }

    formData.set('file', file);

    return this._http
      .post<VendorCertificate>(this._URL, formData)
      .pipe(
        map(cert => plainToClass(VendorCertificate, cert))
      )
      .toPromise();
  }

  async getProductCertificates(): Promise<VendorCertificate[]> {
    const url = `${this._URL}/product`;

    return this._http
      .get<VendorCertificate[]>(url)
      .pipe(
        map(certificates => plainToClass(VendorCertificate, certificates))
      )
      .toPromise();
  }

  async deleteCertificate(certificateId: Id): Promise<void> {
    const url = `${this._URL}/${certificateId}`;

    return this._http
      .delete<void>(url)
      .toPromise();
  }

  async setCertificateAccessRequest(certificateId: Id, access_request_required: boolean): Promise<void> {
    const url = `${this._URL}/${certificateId}/set_access_request`;
    const body = new CertificateAccessRequestDto(access_request_required);

    return this._http
      .post<void>(url, body)
      .toPromise();
  }
}
