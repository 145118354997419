import { Injectable } from "@angular/core";
import {HttpClient, HttpParams} from '@angular/common/http';
import { ApiService } from "./api";
import { DownloadLinkService } from "./download-link.service";
import { Id } from "lib-shared/shared/models/types";
import { CheckedFobLocationsDownloadRequest } from "../../registration/fob-locations/shared/checked-fob-locations-download-request";

@Injectable()
export class FobLocationBulkDownloadService{
    private readonly _URL: string;

    constructor(
        private http: HttpClient,
        private api: ApiService,
        private downloadService: DownloadLinkService
    ) {
        this._URL = `${api.API}vendor/fob_location_bulk_download`;
    }

    async downloadFobLocations(fobLocationIds: Id[], checkedAllFobLocations: boolean): Promise<void>{
      const body = new CheckedFobLocationsDownloadRequest(fobLocationIds, checkedAllFobLocations);
      const blob = await this.http
        .post(this._URL, body, {
          responseType: 'blob',
          observe: 'response'
        })
        .toPromise();
  
      this.downloadService.saveBlobFromResponse(blob);
    }

    async downloadFOBTemplate(): Promise<void> {
        const url = `${this._URL}/fob_template`;
    
        const blob = await this.http.get(url, {
          responseType: 'blob',
            observe: 'response'
          })
          .toPromise();
    
        this.downloadService.saveBlobFromResponse(blob);
      }
}