import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AppFormDirective} from './directives/app-form.directive';
import {AppFormControlDirective} from './directives/app-form-control.directive';
import {AppErrorsComponent} from './components/form-control-error.component';
import {MatFormFieldModule} from '@angular/material';
import {MissedFieldDirective} from './directives/missed-field.directive';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule
  ],
  declarations: [
    AppFormDirective,
    AppFormControlDirective,
    AppErrorsComponent,
    MissedFieldDirective
  ],
  entryComponents: [
    AppErrorsComponent
  ],
  exports: [
    AppFormDirective,
    AppFormControlDirective,
    AppErrorsComponent,
    MissedFieldDirective
  ]
})
export class FormValidationModule { }
