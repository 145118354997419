import { Component } from '@angular/core';
import {MatIconEnum} from '../../../models/common/mat-icon.enum';

@Component({
  selector: 'app-correct-tick',
  templateUrl: './correct-tick.component.html',
  styleUrls: ['./correct-tick.component.scss']
})
export class CorrectTickComponent {

  readonly MatIconEnum = MatIconEnum;

}
