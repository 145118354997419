import {AfterViewInit, ComponentRef, Directive, Input, OnDestroy} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {AppErrorsComponent} from '../components/form-control-error.component';
import {BehaviorSubject} from 'rxjs';
import {IValidationErrors} from '../models/form-errors.interface';
import {DynamicFormGroup} from 'ngx-dynamic-form-builder';
import {takeWhile} from 'rxjs/internal/operators';


@Directive({
  selector: '[appForm]'
})
export class AppFormDirective implements AfterViewInit, OnDestroy {
  @Input('appForm')
  errors: BehaviorSubject<IValidationErrors>;

  private _form: DynamicFormGroup<any>;
  private _directiveAlive = true;


  constructor(
    private _parent: FormGroupDirective
  ) {}


  ngAfterViewInit() {
    if (!this._parent) {
      throw new Error('appForm directive should be applied to element with FormGroupDirective');
    }

    if (this.errors === null) {
      throw new Error('Pass BehaviorSubject<IValidationErrors> into appForm');
    }

    this._form = this._parent.form as DynamicFormGroup<any>;
    this._subscribeOnErrors();
  }

  ngOnDestroy() {
    this._directiveAlive = false;
  }


  private _updateErrorsBinding(errors, form = this._form): void {
    // need setTimeout because it takes time to generate errorsComponentRef
    setTimeout(() => {
      Object
        .keys(form.controls)
        .forEach((key) => {
          const control = form.controls[key];

          if(control instanceof DynamicFormGroup) {
            this._updateErrorsBinding(errors, control);
          } else if(errors.hasOwnProperty(key) && control.hasOwnProperty('errorsComponentRef')) {
            const component: ComponentRef<AppErrorsComponent> = control['errorsComponentRef'];
            component.instance.controlErrors = <string[]>errors[key];
            control.setErrors(errors[key]);
          }
        });
    });
  }

  private _subscribeOnErrors(): void {
    this.errors
      .pipe(
        takeWhile(() => this._directiveAlive)
      )
      .subscribe(errors => this._updateErrorsBinding(errors));
  }
}
