export const environment = {
  environment: 'staging',
  production: true,
  enableGA: false,
  customerUrl: 'https://customer-staging.epallet.com',
  vendorUrl: 'https://vendor-staging.epallet.com',
  apiUrl: 'https://erp-staging.epallet.com',
  stripeKey: 'pk_test_oBLTJgeLNOMG8d0ZECkSwyBF',
  reCaptchaKey: '6Ld7OM8jAAAAAIkO1GHqcxcsXHAUl5nKtOiPdOT5',
  hmr: false,
  showTours: true,
  enableSentry: true,
  sentryEnv: 'staging',
  sentryDSN: 'https://58c2fdebb024a28d6072a7b2ec9497e5@o4505760727433216.ingest.us.sentry.io/4508419110600704'
};


// Todo remove button show logic
