import {Injectable} from '@angular/core';
import {VendorStatusCacheService} from './vendor-status-cache.service';
import {HttpClient} from '@angular/common/http';
import {map, switchMap, tap} from 'rxjs/internal/operators';
import {Observable} from 'rxjs/Rx';
import {VendorStatus} from '../../models/status/vendor-status';
import {fromPromise} from 'rxjs/internal/observable/fromPromise';
import {plainToClass} from 'class-transformer';
import {ApiService} from '../api';


@Injectable()
export class VendorStatusService {

  private _URL: string;


  constructor(
    private _api: ApiService,
    private _http: HttpClient,
    private _vendorStatusCache: VendorStatusCacheService
  ) {
    this._URL = `${_api.API}vendor/status`;
  }



  get vendorStatusObservable(): Observable<VendorStatus> {
    if(!this._vendorStatusCache.isCached) {
      this._vendorStatusCache.isCached = true;

      return fromPromise(this.checkVendorStatus())
        .pipe(
          switchMap(status => {
            this._vendorStatusCache.vendorStatus = status;
            return this._vendorStatusCache.vendorStatusObservable;
          })
        );
    } else {
      return this._vendorStatusCache.vendorStatusObservable;
    }
  }



  async checkVendorStatus(): Promise<VendorStatus> {
    return this._http
      .get<VendorStatus>(this._URL)
      .pipe(
        map(resp => plainToClass(VendorStatus, resp)),
        tap(status => this._vendorStatusCache.vendorStatus = status)
      )
      .toPromise();
  }

  async updateVendorStatus(): Promise<VendorStatus> {
    const status = await this.checkVendorStatus();
    this._vendorStatusCache.vendorStatus = status;
    return status;
  }


}
