import { MyIsNotEmpty, MyMin } from "../../../modules/form-validation/decorators";
import { Id } from "../../types";

export class OrderWeightForm {
    id: Id = null;

    @MyIsNotEmpty()
    @MyMin(0)
    min_order_weight: number = 0;

    for_selected_products: Array<Id> = [];
    for_all_products: boolean = true;
}