import {Directive, HostListener, Input} from '@angular/core';
import {ClipboardService} from 'ngx-clipboard';
import {MatSnackBar} from '@angular/material';

@Directive({
  selector: '[appCopyToClipboard]'
})
export class CopyToClipboardDirective {

  @Input('appCopyToClipboard')
  contentToCopy: string;


  constructor(
    private _clipboardService: ClipboardService,
    private _snackBar: MatSnackBar
  ) {}


  @HostListener('click', ['$event'])
  onClick() {
    this._clipboardService.copyFromContent(this.contentToCopy);
    this._onClipboardCopyComplete();
  }


  private _onClipboardCopyComplete(): void {
    this._snackBar.open('Copied to clipboard', '', {duration: 1500});
  }
}
