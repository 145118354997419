import {EventEmitter, Input, OnInit, Output} from '@angular/core';

export abstract class NestedArrayComponentAbstract<T> implements OnInit {

  @Input()
  set array(array: T[]) {
    this._array = array;
    this.setupArray();
  }

  @Output()
  updateArray = new EventEmitter<T[]>();

  protected _array: T[];


  ngOnInit() {
    this.setupArray();
  }


  get array(): T[] {
    return this._array;
  }

  get isAbleToDeleteItem(): boolean {
    return this.array.length > 1;
  }



  addNewObject(): void {
    this._array.push(this.getEmptyArrayItem());
    this.emitChanges();
  }

  removeObject(index: number): void {
    this._array.splice(index, 1);
    this.emitChanges();
  }

  updateObject(index: number, value: T): void {
    Object
      .keys(this._array[index])
      .forEach(key => this._array[index][key] = value[key]);
    this.emitChanges();
  }

  emitChanges(): void {
    this.updateArray.emit(this._array);
  }



  abstract getEmptyArrayItem(): T;

  abstract isArrayItemFilled(item: T): boolean;



  protected setupArray(): void {
    this._array = (this._array && this._array.length > 0) ? this._array : [];

    if(this._array && this._array.length === 0) {
      this.addNewObject();
    }
  }
}
