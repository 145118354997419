import 'intersection-observer';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationComponent} from './notification.component';
import {MatProgressSpinnerModule} from '@angular/material';
import {ClickOutsideModule} from 'ng-click-outside';
import {NotificationItemComponent} from './notification-item/notification-item.component';
import {MessageListModule} from '../../ux/message-list/message-list.module';
import {IconButtonModule} from '../../ux/icon-button/icon-button.module';


@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    ClickOutsideModule,
    MessageListModule,
    IconButtonModule
  ],
  exports: [
    NotificationComponent
  ],
  declarations: [NotificationComponent, NotificationItemComponent]
})
export class NotificationModule { }
