import {MatIconEnum} from '../../../../../models/common/mat-icon.enum';

export class FilterChoice {
  value: number | string;
  icon?: MatIconEnum;

  constructor(value: number | string, icon?: MatIconEnum) {
    this.value = value;
    this.icon = icon;
  }
}
