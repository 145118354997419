<div
  class="header-container"
  [ngClass]="{'short': isSideMenuShown}"
>

  <div class="header">

    <div class="header__search"></div>

    <div class="header__info-block">

      <span *ngIf="vendorStatus && vendorStatus.products_link"
        class="header__info-block__notification header__info-block__notification-link"
        [appCopyToClipboard]="vendorStatus.products_link"
        >
          Copy Store URL link 
      </span>

      <!-- <span
        class="header__info-block__notification header__info-block__notification-link inventory"
        matRipple
        *ngIf="vendorStatus && vendorStatus.is_vendor && vendorStatus.showApproveInventoryReviewButton"
        (click)="showConfirmInventoryModal()"
        [ngClass]="{'inventory--loading': inventoryLoading}"
      >
        Approve Inventory Review
      </span> -->

      <div
        *ngIf="vendorStatus && showTours && vendorStatus.is_vendor_admin"
        class="header__info-block__user-dropdown short-dropdown"
        matRipple
        [ngClass]="{'active': showTourListDropdown}"
        [matMenuTriggerFor]="appTourList"
        (click)="showTourListDropDown()"
        (clickOutside)="closeTourListDropDown()"
        [clickOutsideEvents]="'click touchstart'"
      >
        <span
          class="dropdown"
        >
          <span 
            class="user-name"
            [matTooltip]="'Tutorial'"
            matTooltipPosition="below"
          >
            <mat-spinner *ngIf="toursLoading" [diameter]="25" class="spinner"></mat-spinner>
            <mat-icon *ngIf="!toursLoading">video_library</mat-icon>
          </span>
        </span>

        <mat-menu
          class="menu app-tour-menu"
          #appTourList="matMenu"
        >
          <ng-container *ngFor="let item of availableTours">
            <a
              mat-menu-item
              class="menu__item"
              *ngIf="item.visibleAtList"
              (click)="!item.isBlocked && onTourSelect(item.type)"
              [disabled]="item.isBlocked || toursLoading"
              [matTooltip]="item.unlockConditions"
              [matTooltipDisabled]="item.isAvailable"
            >
              <mat-icon
                class="gray-icon"
              >
                {{ item.isAvailable ? 'navigate_next' : item.isFinished ? 'check' : item.isBlocked ? 'lock' : null }}
              </mat-icon>
              <span class="text">{{item.title}}</span>
            </a>
          </ng-container>
        </mat-menu>
      </div>

      <a
        class="header__info-block__notification header__info-block__notification-link"
        matRipple
        target="_blank"
        [href]="apiUrl+'/api/vendor/products/pdf'"
        *ngIf="vendorStatus && vendorStatus.is_vendor && vendorStatus.allowedToProducts && vendorStatus.products_link || vendorStatus.allowedToDashboard"
      >
        <span
          class="notification-bell"
        >
          <img
            src="../../../../../assets/images/qr-code.svg"
            alt="GO to"
          >
        </span>
        Download Epallet Show Sign
      </a>

      <!-- <a
        class="header__info-block__notification"
        matRipple
        [routerLink]="['/help_center']"
      >
        <span
          class="notification-bell"
        >
          <span class="question">?</span>
        </span>
      </a> -->

      <div
        class="header__info-block__notification"
        matRipple
        (click)="openNotification()"
      >
        <div
          class="notification-bell"
          [matBadge]="activeNotificationsAmount"
          [matBadgeHidden]="!activeNotificationsAmount"
          matBadgeColor="warn"
        >
          <img
            src="../../../../../assets/images/bell.png"
            alt="bell"
          >
        </div>
      </div>

      <div
        *ngIf="vendorStatus"
        class="header__info-block__user-dropdown"
        matRipple
        [ngClass]="{'active': showUserDropdown}"
        [matMenuTriggerFor]="appMenu"
        (click)="toggleUserDropDown()"
        (clickOutside)="closeUserDropDown()"
        [clickOutsideEvents]="'click touchstart'"
      >
        <span
          class="dropdown"
        >
          <span class="user-name">
            {{ profileName }}
          </span>
          <span class="arrow-icon">
            <img src="../../../../../assets/images/arrow-down.svg" alt="arrow-icon">
          </span>
        </span>

        <mat-menu
          class="menu"
          #appMenu="matMenu"
        >
          <a
            [routerLink]="['/change_log']"
            mat-menu-item
            class="menu__item"
          >
            <mat-icon
              [svgIcon]="MatIconEnum.CHANGE_LOG"
            ></mat-icon>
            <span class="text">Change Log</span>
          </a>

          <a
            [href]="customerUrl"
            mat-menu-item
            class="menu__item"
          >
            <mat-icon
              [svgIcon]="MatIconEnum.EPALLET_LOGO"
            ></mat-icon>
            <span class="text">Go to Store</span>
          </a>

          <a
            mat-menu-item
            class="menu__item"
            (click)="logout()"
          >
            <mat-icon
              class="gray-icon"
              [svgIcon]="MatIconEnum.LOGOUT"
            ></mat-icon>
            <span class="text">Logout</span>
          </a>
        </mat-menu>
      </div>
    </div>

  </div>

    <div
      class="header-top-banner" *ngIf="showMessage && !userClosedMessage"
    >
    <p class="header-banner">
      <span
        *ngIf="showValidationMessage" class="text"
      >
        {{ companyName }} is all set to go live.
        Epallet will confirm validation shortly.
        You'll receive an email to notify you when {{ companyName }} is live on Epallet.
      </span>  

      <span class="text" *ngIf="showPriceUpdatePopup">
        Please make sure pricing & lead times are accurate.
      </span>
    </p>

      <mat-icon (click)="closeMessage()">close</mat-icon>
    </div>
</div>

<app-notification
  [isNotificationOpen]="isShowNotificationPopup"
  (onCloseBtnClick)="closeNotification()"
></app-notification>