import {NavigationStep} from './navigation-step';

export abstract class NavigationService<T extends NavigationStep> {

  protected _currentStepIndex = 0;

  protected abstract readonly _steps: T[];


  get percentsCompleted(): number {
    return this._steps
      .filter(step => step.completed)
      .reduce((percents, step) => percents += step.percentage, 0);
  }

  get currentStep(): T {
    return this._steps[this._currentStepIndex];
  }

  get currentStepIndex(): number {
    return this._currentStepIndex;
  }

  get steps(): T[] {
    return this._steps;
  }

  get availableToChangeStep(): boolean {
    return this._isCurrentOrNavStepCompleted(this._currentStepIndex);
  }

  get ableToGoToNextStep(): boolean {
    const stepIndex = this._currentStepIndex + 1;
    return stepIndex < this._steps.length && this._isCurrentOrNavStepCompleted(stepIndex);
  }

  get ableToGoToPrevStep(): boolean {
    const stepIndex = this._currentStepIndex - 1;
    return stepIndex >= 0 && this._isCurrentOrNavStepCompleted(stepIndex);
  }

  get isStepFinal(): boolean {
    return this._currentStepIndex === this._steps.length - 1;
  }


  goToStep(stepIndex: number): void {
    if(stepIndex >= 0 && stepIndex < this._steps.length && this._isCurrentOrNavStepCompleted(stepIndex)) {
      this.currentStep.current = false;
      this._currentStepIndex = stepIndex;
      this.currentStep.current = true;
    }
  }

  goToNextStep(): void {
    const nextStepIndex = this._currentStepIndex + 1;
    this.goToStep(nextStepIndex);
  }

  goToPrevStep(): void {
    const prevStepIndex = this._currentStepIndex - 1;
    this.goToStep(prevStepIndex);
  }


  protected _isCurrentOrNavStepCompleted(stepIndex: number): boolean {
    return this.currentStep.completed || this._steps[stepIndex].completed;
  }
}
