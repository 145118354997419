import {CardType} from './card-type.enum';
import {logos, PAYMENT_CARD_LOGOS_FOLDER} from '../constants/payment-logos';
import {Id} from '../../../../../models/types';

export class Payment {
  id: Id;
  four_digits: string;
  month: string;
  year: string;
  card_type: CardType;
  is_default: boolean;

  get logoImagePath(): string {
    return `${PAYMENT_CARD_LOGOS_FOLDER}/${logos[this.card_type]}`;
  }
}
