import {Params} from '@angular/router';
import {AccessStatus} from './access-status.enum';

export class CertificateRequestQueryParams {
  token: string;
  status: AccessStatus;

  constructor(params: Params) {
    this.token = params['token'];
    this.status = params['status'];
  }
}
