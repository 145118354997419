import {TourActionType} from '../models/tour-action.enum';
import {TourTypes} from '../models/tour-types';
import {plainToClass} from 'class-transformer';
import {TourModel} from '../models/tour.model';
import {TourStatusesEnum} from '../models/tour-statuses.enum';

export const addCouponTour = plainToClass(TourModel, {
  type: TourTypes.ADD_COUPON,
  status: TourStatusesEnum.BLOCKED,
  title: 'How to add the coupon',
  unlockConditions: 'Please ensure to have Promotions permission',
  list: [
    {
      action: TourActionType.HIGHLIGHT,
      description: 'To start adding the coupon go to Promotions',
      element: TourTypes.ADD_COUPON + '0',
      title: 'How to add the coupon',
      isElementFixed: true
    },
    {
      action: TourActionType.HIGHLIGHT,
      description: 'Then go to Coupons tab',
      element: TourTypes.ADD_COUPON + '1',
      title: 'How to add the coupon',
      isElementFixed: false,
      isChild: true,
      childLevel: 2
    },
    {
      action: TourActionType.FORM,
      description: 'Fill all fields at the form',
      element: TourTypes.ADD_COUPON + '2',
      title: 'How to add the coupon',
      isElementFixed: false
    },
    {
      action: TourActionType.HIGHLIGHT,
      description: 'All added coupons are displayed here',
      element: TourTypes.ADD_COUPON + '3',
      title: 'How to add the coupon'
    }
  ]
});
