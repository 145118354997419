<div
  class="tour-slide"
  [ngClass]="positionClass"
  *ngIf="!!tourItem && !!holePosition"
  [style.top]="topOffset"
  [style.left]="leftOffset"
  [style.position]="tourItem.isElementFixed ? 'fixed' : 'absolute'"
>
  <div class="tour-slide__container">
    <p
      class="tour-slide__title"
      *ngIf="tourItem.title"
    >
      {{tourItem.title}}
    </p>

    <p
      class="tour-slide__description"
    >
      {{tourItem.description}}
    </p>
  </div>

  <div class="tour-slide__buttons">
    <div
      class="end-tour"
      [ngClass]="{'active': index === length}"
      (click)="finishTour()"
    >
      Finish tour
    </div>

    <div class="step-counter">
      <ng-container *ngIf="length > 1">
        {{index}}/{{length}}
      </ng-container>
    </div>

    <div
      [ngClass]="{'disabled': isNextDisabled}"
      (click)="next()"
    >
      Next
    </div>
  </div>
</div>
