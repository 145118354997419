<div class="main-navigation">
  <div class="icon-bar" *ngIf="isShowMenu"></div>

  <aside
    *ngIf="isShowMenu"
    class="main-nav-wrapper"
    [class.opened]="isMenuOpened"
  >
    <div class="nav-block">
      <div class="logo">
        <!-- <img src="../../../../assets/images/epallet-logo.svg" alt="main-logo"> -->
        <img src="../../../../assets/images/epallet-logo.png" alt="main-logo">

      </div>

      <nav class="main-nav">
        <a
          *ngFor="let item of menu"
          class="main-nav__item"
          [routerLink]="item.link"
          routerLinkActive="active"
          [matTooltip]="item.display"
          matTooltipPosition="right"
          [matTooltipDisabled]="isMenuOpened"
          matRipple
          [attr.data-tour]="item.dataTourAttr || null"
        >
        <span class="main-nav__icon">
          <mat-icon [svgIcon]="item.image"></mat-icon>
        </span>
          <span class="main-nav__text">{{item.display}}</span>
        </a>
      </nav>
    </div>
    <div class="link agreement-link">
      <a href="{{vendorStatus.vendor_term_and_condition}}" target="_blank"  *ngIf="vendorStatus.vendor_term_and_condition">Epallet Vendor Agreement</a>
      <a href="{{vendorStatus.agreement_url}}" target="_blank" *ngIf="!vendorStatus.vendor_term_and_condition">Epallet Vendor Agreement</a>
    </div>
  </aside>

  <div class="burger-btn" *ngIf="isShowMenu">
    <app-burger-button
      [showMenuItems]="isMenuOpened"
      (onBurgerButtonStatusChange)="toggleMenu()"
    ></app-burger-button>
  </div>
</div>