import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../environments/environment';
// import {fadeInOut} from '../../../animations/fade-in-out';
import {ObservableComponent} from 'lib-shared';
import { VendorStatusService } from '../shared/services/vendor-status/vendor-status.service';
import { EmailVerificationService } from '../shared/services/email-verification.service';
import { VendorStatus } from '../shared/models/status/vendor-status';
import { TokenRestorationQueryParams } from '../shared/services/restore-password-query-params';
import { RequestLinkService } from '../shared/services/request-link.service';
import { Messages } from '../shared/helpers/messages';

@Component({
	selector: 'app-email-verification',
	templateUrl: './email-verification.component.html',
	styleUrls: ['./email-verification.component.scss'],
	// animations: fadeInOut
})
export class EmailVerificationComponent extends ObservableComponent implements OnInit {

	queryParams: TokenRestorationQueryParams;

	showErrorModal = false;
	resendLinkEMail: string;

	showGenerateButtonSpinner = false;

	readonly messages = new Messages();


	constructor(
		private _emailVerificationService: EmailVerificationService,
		private _requestLinkService: RequestLinkService,
		private _activatedRoute: ActivatedRoute,
		private _router: Router,
		private _vendorStatusService: VendorStatusService
	) {
		super();
	}



	ngOnInit() {
		console.log("Email verification component");
		this._subscribeOnQueryParams();
	}


	closeModal(): void {
		this.showErrorModal = false;
		this._router.navigate(['/']);
	}

	async generateNewVerificationLink(): Promise<void> {
		this.messages.clearMessages();
		this.showGenerateButtonSpinner = true;

		try {
			await this._requestLinkService.sendLinkRequest(this.resendLinkEMail, this.queryParams.type);
			this.messages.addConfirmationMessage('Verification link has been sent');
		} catch(err) {
			this.messages.setBackendErrors(err);
		}

		this.showGenerateButtonSpinner = false;
	}



	private async _verifyEmail(): Promise<void> {
		try {
			await this._emailVerificationService.verifyEmail(this.queryParams.uid, this.queryParams.token);
			const vendorStatus = await this._vendorStatusService.updateVendorStatus();
			this._redirect(vendorStatus);
		} catch(err) {
			const error = err.error || err;
			this.showErrorModal = true;
			this.resendLinkEMail = !!error.email ? error.email : null;
		}
	}

	private _subscribeOnQueryParams(): void {
		this._activatedRoute
			.queryParams
			.pipe(
				this._takeWhileComponentAlive()
			)
			.subscribe(params => {
				this.queryParams = new TokenRestorationQueryParams(params);
				console.log(this.queryParams);
				if(this.queryParams.validForEmailVerification) {
					this._verifyEmail();
				}
			});
	}

	private _redirect(vendorStatus: VendorStatus): void {
		if(vendorStatus.is_vendor) {
			window.location.href = `${environment.vendorUrl}/dashboard`;
		} else {
			if(vendorStatus.profile_completed){
				this._router.navigate(['/']);
			}
			else {
				this._router.navigate(['/registration-finish']);
			}
		}
	}
}
