import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TotalAmountComponent } from './total-amount.component';

@NgModule({
  declarations: [TotalAmountComponent],
  exports: [TotalAmountComponent],
  imports: [
    CommonModule
  ]
})
export class TotalAmountModule { }
