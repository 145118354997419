import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api';


@Injectable()
export class InventoryService {

  private _URL: string;


  constructor(
    private _api: ApiService,
    private _http: HttpClient,
  ) {
    this._URL = `${_api.API}vendor/inventory_review`;
  }

  async approveReview(): Promise<void> {
    return this._http
      .post<void>(this._URL + '/approve', {})
      .toPromise();
  }

}
