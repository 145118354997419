import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material';
import { classToPlain } from 'class-transformer';
import { FormComponentContainer } from 'lib-shared';
import { DynamicFormGroup } from 'ngx-dynamic-form-builder';
import { MatIconEnum } from '../shared/models/common/mat-icon.enum';
import { ProductListItem } from '../shared/models/product/product-list/product-list-item';
import { PopupService } from '../shared/services/popup.service';
import { ProductListService } from '../shared/services/product/product-list.service';
import { ChargePerPalletForm } from '../shared/models/product/product-list/charge-per-pallet.form';
import { ChargePerPalletService } from '../shared/services/product/charge-per-pallet.service';

@Component({
  selector: 'app-charge-per-pallet',
  templateUrl: './charge-per-pallet.component.html',
  styleUrls: ['./charge-per-pallet.component.scss']
})
export class ChargePerPalletComponent extends FormComponentContainer<ChargePerPalletForm> implements OnInit {
  showSelectedProducts: boolean = false;
  productList: ProductListItem[]= [];
  readonly MatIconEnum = MatIconEnum;
  isDeleted: boolean = false;
  showSpinner = false;


  @Input()
  chargeperpallet: ChargePerPalletForm;

  @Output()
  refreshList = new EventEmitter<void>();

  constructor(
    private _productListService: ProductListService,
    private chargePerPalletService: ChargePerPalletService,
    private showPop: PopupService,
  ) {
    super();
   }

  ngOnInit() {
    super.ngOnInit();
    this._resetForm(this.chargeperpallet);
    this.getAllProducts();
    this.showSelectedProducts = !this.chargeperpallet.for_all_products;

  }


  protected _getFormGroup(): DynamicFormGroup<ChargePerPalletForm> {
    const form = this._fb.group(ChargePerPalletForm, classToPlain(new ChargePerPalletForm()));
    return form;
  }

  private async getAllProducts(): Promise<void> {
    try {
      this.productList = await this._productListService
        .getAllProducts();
    } catch (err) {
      this._handleBackendErrors(err)
    }
  }

  public toggle(event: MatSlideToggleChange) {  
    this.showSelectedProducts = !event.checked;
  }

  async onSave(): Promise<void> {
    this.messages.clearMessages();
    this.showSpinner = true;

    this._validateForm();

    if(this.showSelectedProducts == true && (this.form.object.for_selected_products == null || this.form.object.for_selected_products.length == 0)){ 
      this.errors.setErrorsFromBackend({for_selected_products: 'Please select any product.'});
      this.form.controls['for_selected_products'].markAsTouched();
      return;
    }
    this.form.controls['for_selected_products'].markAsTouched();
    if(this.form.valid){
      if(this.form.object.for_all_products == true){
        this.form.controls['for_selected_products'].setValue([]);
      }

      try{
        await this.chargePerPalletService.editChargePerPallet(this.form.value);
        await this.showPop.showSuccessPopup("Charge per pallet updated successfully.");
        this.refreshList.emit();
      }
      catch(err) {
        this._handleBackendErrors(err);
      }
    }
    this.showSpinner = false;

  }

  async deleteChargePerPallet(chargeperpallet:  ChargePerPalletForm): Promise<void> {
    this.messages.clearMessages();
    this.showSpinner = true;

    const response = await this.showPop.showDeletePopup(`Do you want to delete this setting?`);
    try{

      if(response)
      {
        const chargePerPallet = await this.chargePerPalletService.deleteChargePerPallet(chargeperpallet.id);  
        this.isDeleted = true;
        this.refreshList.emit();
      }
    }
    catch(err){
      this._handleBackendErrors(err)
    }
    this.showSpinner = false;

  }
}
