import {Id} from '../../../types';
import { FilterQuery } from '../../../../modules/blocks/filters/shared/models/filter-query';
import { FilteredCheckedProductsRequest } from './filtered-checked-products-request';

export class CheckedProductsRequest extends FilteredCheckedProductsRequest {
  ids: Id[];
  category: string[];
  date_from: string[];
  date_to: string[];
  fob: string[];
  status: string[];
  special_price: boolean;
  promo: boolean;
  haggle_rewards: boolean;
  search: string;

  constructor(ids: Id[], areAllProductsChecked: boolean, filtersQuery: FilterQuery = new FilterQuery(), search: string) {
    super(filtersQuery, search);
    this.ids = areAllProductsChecked ? null : ids;
  }
}
