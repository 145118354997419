import {ProductMedia} from '../../../shared/models/product/product-media/product-media';
import {Id} from '../../../shared/models/types';
import {MediaPathIds} from './media-path-ids';
import {MediaServiceAbstract} from '../../../shared/modules/blocks/file-uploader/models/media-service.abstract';

export abstract class ProductMediaServiceAbstract extends MediaServiceAbstract {


  abstract async addFile(multipartData: FormData, ids: MediaPathIds): Promise<ProductMedia>;

  abstract async deleteFile(fileId: Id, ids: MediaPathIds): Promise<void>;

  abstract async changeFilesOrder(fileIds: Id[], ids: MediaPathIds): Promise<ProductMedia[]>;


  async deleteMultipleFiles(fileIds: Id[], ids: MediaPathIds): Promise<void> {
    await Promise.all(fileIds.map(id => this.deleteFile(id, ids)));
  }


  protected _getUrl(ids: MediaPathIds): string {
    return `${this._api.API}vendor/products/${ids.productId}`;
  }

}
