import { MatDialog } from "@angular/material";
import { Id } from "lib-shared/shared/models/types";
import { ApiHttpService } from "../../../core/ApiHttp/api-http.service";
import { plainToClassSetDefaults } from "../../helpers/plain-to-class-defaults";
import { StringifyCircularObject } from "../../helpers/StringifyCircularObject";
import { ApiService} from '../api';
import {map} from 'rxjs/internal/operators';
import { ChargePerPalletForm } from "../../models/product/product-list/charge-per-pallet.form";

export class ChargePerPalletService  {
      
    private readonly _URL: string;

    constructor(
        private api: ApiService,
        private http: ApiHttpService,
        private dialog: MatDialog

    ) {
        this._URL ='vendor/charge_per_pallet_setting';
    }
   
    getChargePerPallet(): Promise<ChargePerPalletForm[]> {
        return this.http.get<ChargePerPalletForm[]>(this._URL).toPromise();
    }

    async editChargePerPallet(orderquantity: ChargePerPalletForm | any): Promise<ChargePerPalletForm> {
        const url = `${this._URL}/${orderquantity.id}`;
    
        const productProcessed = JSON.parse( StringifyCircularObject(orderquantity) );
        
        return this.http
            .put<ChargePerPalletForm>(url, productProcessed)
            .pipe(
                map(newOrder => plainToClassSetDefaults(ChargePerPalletForm, newOrder))
                )
            .toPromise();
        }
    
        
    async addChargePerPallet(chargeperpallet: ChargePerPalletForm): Promise<ChargePerPalletForm> {
    return this.http
        .post<ChargePerPalletForm>(this._URL, chargeperpallet)
        .pipe(
            map(newChargePerPallet => plainToClassSetDefaults(ChargePerPalletForm, newChargePerPallet))).
            toPromise();
    }

    async deleteChargePerPallet(id: Id): Promise<void> {
        const url = `${this._URL}/${id}`;

        return this.http
            .delete<void>(url).toPromise();
        }   

    }