import { Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'processing-dialog',
    templateUrl: './processing-dialog.component.html',
    styleUrls: ['./processing-dialog.component.scss']
}) 
export class ProcessingDialog {
    constructor(
        public dialogRef: MatDialogRef<ProcessingDialog>,
        @Inject(MAT_DIALOG_DATA) public data: {msg: string}
      ) {}
}