import {PromoCodeFormContainerComponentAbstract} from '../../shared/components/promo-code-form-container/promo-code-form-container.component';
import {SubscriptionsService} from '../../shared/services/subscriptions.service';
import {Component} from '@angular/core';

@Component({
  selector: 'app-subscription-promo-code',
  templateUrl: '../../shared/components/promo-code-form-container/promo-code-form-container.component.html',
  styleUrls: ['../../shared/components/promo-code-form-container/promo-code-form-container.component.scss']
})
export class SubscriptionPromoCodeComponent extends PromoCodeFormContainerComponentAbstract {

  constructor(
    private _subscriptionService: SubscriptionsService
  ) {
    super();
  }

  protected async _sendPromoCodeRequest(): Promise<void> {
    await this._subscriptionService.enterPromoCode(this.form.object);
    this.messages.addConfirmationMessage('Your promo code is valid!');
  }

}
