import {GeneralMessage} from '../modules/ux/message/models/general-message';
import {ErrorMessage} from '../modules/ux/message/models/error-message';
import {NoticeMessage} from '../modules/ux/message/models/notice-message';
import {GeneralMessageType} from '../modules/ux/message/models/enums/general-message-type.enum';
import {ConfirmationMessage} from '../modules/ux/message/models/confirmation-message';

export class Messages {

  static BACKEND_ERROR_KEY = 'detail';
  static BACKEND_FILE_ERROR_KEY = 'file';

  messages: GeneralMessage[];


  constructor(messages: GeneralMessage[] = []) {
    this.messages = messages;
  }


  get hasErrors(): boolean {
    return this.messages.some(msg => msg.type === GeneralMessageType.ERROR);
  }

  get hasMessages(): boolean {
    return this.messages.length > 0;
  }



  clearMessages(): void {
    this.messages = [];
  }

  setBackendErrors(err: {[key: string]: any}): void {
    console.log(err);
    const error = err.error || err;
    if(error && typeof error === 'object') {
      const errorKeys = Object.keys(error);

      if(errorKeys.length === 0) {
        this.addErrorMessage('Something went wrong :(');
      } else {
        errorKeys.forEach(key => {
          const keyValue = error[key];

          if(key === Messages.BACKEND_ERROR_KEY || key === Messages.BACKEND_FILE_ERROR_KEY) {
            if(typeof keyValue === 'object' && !Array.isArray(keyValue)) {
              this.setBackendErrors(keyValue);
            } else {
              this.addErrorMessage(keyValue);
            }
          }
        });

        if(this.messages.length === 0) {
          this.addErrorMessage('Some errors have been occurred');
        }
      }
    } else {
      this.addErrorMessage('Something went wrong :(');
    }
  }

  addErrorMessage(text: string, payload = {}): void {
    if(!this.messages.find(message => message.title === text)) {
      this.messages.push(new ErrorMessage(text, payload));
    }
  }

  addNoticeMessage(text: string, payload = {}): void {
    this.messages.push(new NoticeMessage(text, payload));
  }

  addConfirmationMessage(text: string, payload = {}): void {
    this.messages.push(new ConfirmationMessage(text, payload));
  }

  removeMessage(index: number): void {
    this.messages.splice(index, 1);
  }
}
