import {TourActionType} from '../models/tour-action.enum';
import {TourTypes} from '../models/tour-types';
import {plainToClass} from 'class-transformer';
import {TourModel} from '../models/tour.model';
import {TourStatusesEnum} from '../models/tour-statuses.enum';

export const bulkEditTour = plainToClass(TourModel, {
  type: TourTypes.BULK_EDIT,
  status: TourStatusesEnum.BLOCKED,
  title: 'How to do a bulk edit of the products',
  unlockConditions: 'Please add at least one Product',
  list: [
    {
      action: TourActionType.HIGHLIGHT,
      description: 'First open the product tab',
      element: TourTypes.BULK_EDIT + '0',
      title: 'How to do a bulk edit of the products',
      isElementFixed: true
    },
    {
      action: TourActionType.FORM,
      description: 'Then select one or more products',
      element: TourTypes.BULK_EDIT + '1',
      title: 'How to do a bulk edit of the products',
      isElementFixed: false
    },
    {
      action: TourActionType.FORM,
      description: 'Download *.XLS table with exported products, open the downloaded file and change necessary fields of each product',
      element: TourTypes.BULK_EDIT + '2',
      title: 'How to do a bulk edit of the products',
      isElementFixed: false
    },
    {
      action: TourActionType.FORM,
      description: 'After all changes open actions menu',
      element: TourTypes.BULK_EDIT + '3',
      title: 'How to do a bulk edit of the products',
      isElementFixed: false
    },
    {
      action: TourActionType.HIGHLIGHT,
      description: 'And upload edited *.XLS file',
      element: TourTypes.BULK_EDIT + '4',
      title: 'How to do a bulk edit of the products',
      isElementFixed: false,
      highLevelElement: true
    }
  ]
});
