import {DynamicFormBuilder, DynamicFormGroup} from 'ngx-dynamic-form-builder';
import {OnInit} from '@angular/core';
import {Messages} from '../../../helpers/messages';
import {HttpErrorResponse} from '@angular/common/http';
import {FormErrors} from '../form-errors-container';
import {FormGroup} from '@angular/forms';

export abstract class FormComponentContainer<T> implements OnInit {

  form: DynamicFormGroup<T>;

  errors: FormErrors;

  readonly messages = new Messages();

  protected readonly _fb = new DynamicFormBuilder();


  ngOnInit() {
    this._buildForm();
  }


  protected abstract _getFormGroup(): DynamicFormGroup<T>;

  protected _handleBackendErrors(err): void {
    const error = err instanceof HttpErrorResponse ? err.error : err;

    this._validateForm();
    this.messages.setBackendErrors(error);
    this.errors.setErrorsFromBackend(error);
  }

  protected _buildForm(): void {
    this.form = this._getFormGroup();
    this.errors = new FormErrors(this.form);
  }

  protected _resetForm(value: T = this._getFormGroup().value): void {
    this.form.setValue(value);
    this.form.markAsUntouched();
  }

  protected _validateForm(): void {
    if(this.form) {
      this.form.validate();
      this._markFormGroupTouched(this.form);
    }
  }

  protected _markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object)
      .values(formGroup.controls)
      .forEach(control => {
        control.markAsTouched();
        control.markAsDirty();

        if (control.controls) {
          this._markFormGroupTouched(control);
        }
      });
  }

}
