import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormComponent} from '../../../../../../shared/modules/form-validation';
import {PromoCodeForm} from '../models/promo-code.form';
import {MatIconEnum} from '../../../../../../shared/models/common/mat-icon.enum';

@Component({
  selector: 'app-promo-code-form',
  templateUrl: './promo-code-form.component.html',
  styleUrls: ['./promo-code-form.component.scss']
})
export class PromoCodeFormComponent extends FormComponent<PromoCodeForm> {

  @Input()
  promoCodeSucceed = false;

  @Input()
  promoCodeError = false;

  @Input()
  showSpinner = false;

  @Output()
  onClearForm = new EventEmitter<void>();

  @Output()
  onResetError = new EventEmitter<void>();

  readonly MatIconEnum = MatIconEnum;


  get canBeSubmitted(): boolean {
    return !this.promoCodeError && !this.promoCodeSucceed && !this.showSpinner;
  }


  clearForm(): void {
    this.onClearForm.emit();
  }

  resetErrors(): void {
    this.onResetError.emit();
  }

  submit(): void {
    if(this.canBeSubmitted && !!this.form.object.promo_code) {
      super.submit();
    }
  }

}
