import {Component, OnDestroy, OnInit} from '@angular/core';
import {HoleParamsModel} from './models/hole-params.model';
import {DamperModel} from './models/damper.model';
import {TourItemModel} from './models/tour-item.model';
import {TourService} from './services/tour.service';
import {TourModel} from './models/tour.model';
import {filter} from 'rxjs/operators';
import {ActiveTourPointer} from './models/active-tour-pointer.model';
import {TourActionType} from './models/tour-action.enum';
import {Subscription} from 'rxjs';
import {ResizeSensor} from 'css-element-queries';
import {ObservableComponent} from 'lib-shared';

@Component({
  selector: 'app-site-tour',
  templateUrl: 'site-tour.component.html',
  styleUrls: ['site-tour.component.scss']
})
export class SiteTourComponent  extends ObservableComponent implements OnInit, OnDestroy {

  overlayDumpers: DamperModel[];

  private _holeParams: HoleParamsModel;
  private _activeTour: TourModel;
  private _activeSlideNumber: number; // starts from 0
  private _activeTourSubscription: Subscription;
  private _scrollEventSubscription: Subscription;

  private _resizeListener: ResizeSensor;

  private _availableTours: TourModel[];

  activeTourSlide: TourItemModel;

  currentScrollHeight: string;
  topOffsetOfOverlay: number;

  get currentZindex(): number {
    return this.activeTourSlide && this.activeTourSlide.highLevelElement ? 1001 : 998;
  }

  get currentSlideIndex(): number {
    return this._activeSlideNumber + 1;
  }

  get currentTourSlidesCount(): number {
    return this._activeTour ? this._activeTour.list.length : 0;
  }

  set holeParams(params: HoleParamsModel) {
    this._holeParams = params;
    this._calcDumpers();
  }

  get holeParams(): HoleParamsModel {
    return this._holeParams;
  }

  constructor(
    private _tourService: TourService
  ) {
    super();
  }


  ngOnInit() {
    this._initHole();
    setTimeout( () => {
      this._subscribeOnToursList();
      this._subscribeToActiveTour();
      this._subscribeToGlobalScrollEvent();

      // TODO: need to improvement in nearest future
      // this._tourService.detectUnfinishedTours();
    }, 0);
  }

  private _initHole(): void {
    this.holeParams = new HoleParamsModel(0, 0, 0, 0);
  }

  private _calcDumpers(): void {
    if (!this.holeParams || ( !this.holeParams.clearHeight || !this.holeParams.clearWidth ) ) return;
    const {clearWidth: Wh, clearHeight: Hh, clearTop: Th, clearLeft: Lh} = this.holeParams;

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    this.overlayDumpers = [
      new DamperModel(
        Lh,
        Th + Hh,
        0,
        0
      ),
      new DamperModel(
        windowWidth - Lh,
        Th,
        0,
        Lh
      ),
      new DamperModel(
        windowWidth - ( Lh + Wh ),
        Hh < (windowHeight - Th) ? windowHeight - Th : Hh,
        Th,
        Lh + Wh
      ),
      new DamperModel(
        Lh + Wh,
        windowHeight - (Th + Hh),
        Th + Hh,
        0
      )
    ];
  }

  private _fullOffset(el) {
    const rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { offsetTop: rect.top + scrollTop, offsetLeft: rect.left + scrollLeft };
  }

  private _setSlide(item: TourItemModel): void {
    if (!item.element || !item.elementNative) return;
    try {
      this._resizeListener && this._resizeListener.detach();
      const element = item.elementNative;

      if( item.isForm || item.resizable ) {
        this._resizeListener = new ResizeSensor(element, () => {
          this._calculateHolePositionAndOverlay(item);
        });
      }

      this._calculateHolePositionAndOverlay(item);

      if( item.action === TourActionType.HIGHLIGHT || item.action === TourActionType.CLICK ) {
        element.addEventListener('click', this._clickListener.bind(this), { once: true });
      } else if ( item.isForm ) {
        item.cancelElementNative && item.cancelElementNative
          .addEventListener('click', this._finishTour.bind(this), { once: true });
      }
    } catch (e) {
      console.log('tour initialization error', e);
    }

  }

  private _calculateHolePositionAndOverlay(item: TourItemModel): void {
    const {elementNative: element} = item;

    let { offsetWidth, offsetHeight } = element as any;
    let { offsetLeft, offsetTop } = this._fullOffset(element);
    const { pageYOffset } = window;
    item.isElementFixed && (offsetTop = offsetTop - pageYOffset);
    this.holeParams = new HoleParamsModel(offsetWidth, offsetHeight, offsetTop, offsetLeft);
    this.activeTourSlide = item;

    // Height for absolute overlay below the dumpers
    const bodyOffsetHeight = document.querySelector('body').offsetHeight;

    if(!item.isElementFixed) {
      const leftDumperHeight = this.overlayDumpers[0].clearHeight;
      const bottomDumperHeight = this.overlayDumpers[3].clearHeight;
      this.topOffsetOfOverlay = leftDumperHeight + bottomDumperHeight;
      const calculatedHeight = (bodyOffsetHeight + 110 - this.topOffsetOfOverlay);
      this.currentScrollHeight = calculatedHeight > 0 ? calculatedHeight + 'px' : '0px';
    } else {
      this.currentScrollHeight = (bodyOffsetHeight + 110 - window.innerHeight) + 'px';
      delete this.topOffsetOfOverlay;
    }

  }

  private _clickListener(): void {
    if ( this._activeTour.list.length - 1 === this._activeSlideNumber) {
      this._finishTour();
    } else {
      this._tourService.showNext(this._activeTour.type, this._activeSlideNumber + 1);
    }
  }

  private _subscribeToActiveTour(): void {
    this._activeTourSubscription = this._tourService.tourActiveAsObservable
      .pipe(
        filter(type => !!type)
      )
      .subscribe( ( pointer: ActiveTourPointer ) => {
        const {type, index} = pointer;
        this._activeTour = this._availableTours.find(item => item.type === type);
        this._activeSlideNumber = index;
        this._setSlide(this._activeTour.list[index]);
      } );
  }

  private _subscribeToGlobalScrollEvent(): void {
    this._scrollEventSubscription = this._tourService.scrollHandleAsObservable
      .pipe(
        filter(type => !!type)
      )
      .subscribe( () => {
        if( this._activeSlideNumber
          && this._activeTour
          && this._activeTour.list[this._activeSlideNumber].isProbablyFixed
        ) {
          const item = this._activeTour.list[this._activeSlideNumber];
          item && item.elementNative && this._calculateHolePositionAndOverlay(item);
        }
      } );
  }

  private _subscribeOnToursList(): void {
    this._tourService
      .toursListAsObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(list => {
        this._availableTours = list;
      });
  }

  private _finishTour(): void {
    this._resizeListener && this._resizeListener.detach();
    this._tourService.closeAllTours();
    delete this._activeSlideNumber;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._activeTourSubscription.unsubscribe();
    this._scrollEventSubscription.unsubscribe();
  }

  tourOverlayClick(): void {
    this._finishTour();
  }

  public nextClickHandler(): void {
    this._clickListener();
  }

}


