import {Component, EventEmitter, Input, OnInit, Optional, Output} from '@angular/core';
import {DynamicFormGroup} from 'ngx-dynamic-form-builder';
import {MatIconEnum} from '../../../models/common/mat-icon.enum';
import {FormGroupDirective} from '@angular/forms';

@Component({
  selector: 'app-input-wrapper',
  templateUrl: './input-wrapper.component.html',
  styleUrls: ['./input-wrapper.component.scss']
})
export class InputWrapperComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  iconBefore: MatIconEnum;

  @Input()
  iconAfter: MatIconEnum;

  @Input()
  showSpinner = false;

  @Input()
  noForm = false;

  @Input()
  formControlNameString: string;

  @Output()
  onBeforeIconClick = new EventEmitter<void>();

  @Output()
  onAfterIconClick = new EventEmitter<void>();


  form: DynamicFormGroup<any>;


  constructor(
    @Optional() private _parentForm: FormGroupDirective
  ) {}


  ngOnInit(): void {
    this._setFormGroup();
  }


  get isInputInvalid(): boolean {
    const control = this.form.controls[this.formControlNameString];
    return control.invalid && control.touched && !!control.errors && (!!control.errors ? control.errors.length > 0 : false);
  }


  beforeIconClick(): void {
    this.onBeforeIconClick.emit();
  }

  afterIconClick(): void {
    this.onAfterIconClick.emit();
  }



  private _setFormGroup(): void {
    if(!this._parentForm && !this.noForm) {
      throw new Error('<app-input-wrapper> should be used inside FormGroup or with noForm flag');
    } else if(this._parentForm && !this.noForm) {
      this.form = this._parentForm.form as DynamicFormGroup<any>;
    }
  }

}
