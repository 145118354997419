import {Price} from '../types';
import {MyMin} from '../../modules/form-validation/decorators';
import {LeadTimeUom} from './enums/lead-time-uom.enum';

export class StockRecordForm {
  @MyMin(1)
  min_pallet_quantity = 1;

  @MyMin(0)
  case_quantity = 0;

  price: Price = 0;

  has_infinite_quantity = false;
  is_flat_rate = false;
  on_sale = false;

  lead_time: number = null;
  lead_time_uom: LeadTimeUom | string = '';


  get isPriceNull(): boolean {
    return this.isNumberNull(+this.price);
  }

  get isLeadTimeNull(): boolean {
    return this.isNumberNull(this.lead_time);
  }

  get isQtyNull(): boolean {
    return this.isNumberNull(this.case_quantity) && !this.has_infinite_quantity;
  }

  protected isNumberNull(number: number): boolean {
    return !number || number == null || number.toString().includes('0.000');
  }
}
