import {UrlPath} from '../../../../shared/models/types';
import {CertificateAffiliation} from './enums/certificate-affiliation.enum';
import {BackendFile} from '../../../../shared/modules/blocks/file-uploader/models/backend-file';
import {MyIsNotEmpty} from '../../../../shared/modules/form-validation/decorators';

export class VendorCertificate extends BackendFile {

  readonly group: string;

  @MyIsNotEmpty()
  name = '';

  @MyIsNotEmpty()
  file: UrlPath = null;

  @MyIsNotEmpty()
  expiration_date = '';

  // @MyIsNotEmpty()
  // responsible_person = '';

  @MyIsNotEmpty()
  access_request_required = false;

  @MyIsNotEmpty()
  affiliation: CertificateAffiliation = CertificateAffiliation.COMPANY;


  get expirationMonthFromDate(): number {
    const monthStrStartPoint = 0;
    const monthStrLength = 2;

    return +this.expiration_date.substr(monthStrStartPoint, monthStrLength);
  }

  get expirationYearFromDate(): number {
    const yearStrStartPoint = 3;
    const yearStrLength = 4;

    return +this.expiration_date.substr(yearStrStartPoint, yearStrLength);
  }

  get isComplete(): boolean {
    return !!this.file && !!this.affiliation && !!this.name && !!this.expiration_date;
  }
}



