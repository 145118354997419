import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../api';
import { VendorHidePopUp } from '../../models/status/vendor-hide-popup';

@Injectable({
  providedIn: 'root'
})
export class VendorHidePopUpService {

  private _URL: string;

  constructor(
    private _api: ApiService,
    private _http: HttpClient,
  ) {
    this._URL = `${_api.API}vendor/vendor_hide_popup`;
  }

  async changeCloseDate(close_date: VendorHidePopUp): Promise<void> {
    return this._http
      .post<void>(this._URL, close_date)
      .toPromise();
  }

}