import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ApiService} from './services/api';
import {RouterModule} from '@angular/router';
import {NumberFormatPipe} from './pipes/number-format.pipe';
import {LoginService} from './services/login.service';
import {SearchComponent} from './components/search/search.component';
import {MatDialogModule, MatIconModule, MatInputModule, MatRippleModule, MatTooltipModule, MatSelectModule, MatProgressSpinnerModule} from '@angular/material';
import {NumberPipe} from './pipes/number.pipe';
import {CamelcasePipe} from './pipes/camelcase.pipe';
import {DeleteDialog} from './components/delete-dialog/delete-dialog.component';
import {RemoveDecimalPipe} from './pipes/remove-decimal.pipe';
import {CapitalizeFirstPipe} from './pipes/capitalize-first.pipe';
import {MainNavigationService} from './services/main-navigation.service';
import {MainNavigationComponent} from './components/main-navigation/main-navigation.component';
import {NoticeDialogComponent} from './components/notice-dialog/notice-dialog.component';
import {PopupService} from './services/popup.service';
import {CertificationRequestService} from './services/certification-request.service';
import {DeleteTypeConfirmDialogComponent} from './components/delete-type-confirm-dialog/delete-type-confirm-dialog.component';
import {FormsModule} from '@angular/forms';
import {NumberFilterPipe} from './pipes/number-filter.pipe';
import {BurgerButtonModule, DirectivesModule, PipesModule} from 'lib-shared';
import {ActionsDialog} from './components/actions-dialog/actions-dialog.component';
import {CustomDialog} from './components/custom-dialog/custom-dialog.component';
import {ProcessingDialog} from './components/processing-dialog/processing-dialog.component';
import {SuccessDialog} from './components/success-dialog/success-dialog.component';
import {CorrectTickModule} from './modules/blocks/correct-tick/correct-tick.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatInputModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
    MatRippleModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    FormsModule,
    BurgerButtonModule,
    DirectivesModule,
    PipesModule,
    CorrectTickModule
  ],
  declarations: [
    MainNavigationComponent,
    SearchComponent,
    NumberFormatPipe,
    NumberPipe,
    CamelcasePipe,
    DeleteDialog,
    CustomDialog,
    ProcessingDialog,
    SuccessDialog,
    ActionsDialog,
    DeleteTypeConfirmDialogComponent,
    RemoveDecimalPipe,
    CapitalizeFirstPipe,
    NoticeDialogComponent,
    NumberFilterPipe
  ],
  providers: [
    ApiService,
    LoginService,
    MainNavigationService,
    PopupService,
    CertificationRequestService
  ],
  entryComponents: [
    DeleteDialog,
    ActionsDialog,
    CustomDialog,
    ProcessingDialog,
    SuccessDialog,
    DeleteTypeConfirmDialogComponent,
    NoticeDialogComponent,
  ],
  exports: [
    CommonModule,
    MainNavigationComponent,
    SearchComponent,
    NumberFormatPipe,
    MatDialogModule,
    DeleteDialog,
    ActionsDialog,
    CustomDialog,
    ProcessingDialog,
    SuccessDialog,
    DeleteTypeConfirmDialogComponent,
    RemoveDecimalPipe,
    CapitalizeFirstPipe,
    NumberFilterPipe,
    DirectivesModule,
    PipesModule
  ],
})

export class SharedModule {}
