import {EventEmitter, Input, Output} from '@angular/core';
import {DynamicFormGroup} from 'ngx-dynamic-form-builder';

export class FormComponent<T> {

  @Input()
  form: DynamicFormGroup<T>;

  @Output()
  onBlur = new EventEmitter<T>();

  @Output()
  onSubmit = new EventEmitter<T>();


  emitValue(): void {
    this.onBlur.emit(this.form.object);
  }

  submit(): void {
    this.onSubmit.emit(this.form.object);
  }

}
