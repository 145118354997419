import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {Router} from '@angular/router';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {tap} from 'rxjs/internal/operators';
import {VendorStatusCacheService} from '../../shared/services/vendor-status/vendor-status-cache.service';


@Injectable()
export class ResponseStatusWatcherService implements HttpInterceptor {

  constructor(
    private _vendorStatusCache: VendorStatusCacheService,
    private _router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(req)
      .pipe(
        tap(
          event => {},
          error => {
            if(error instanceof HttpErrorResponse) {
              if(error.status === 403 || error.status === 401) {
                setTimeout(() => {
                  this._vendorStatusCache.clearCache();
                  this._router.navigate(['']);
                }, 2000);
              }

              if(error.status === 502 || error.status === 504) {
                this._router.navigate(['oops/bad-gateway']);
              }
            }
          }
        )
      );
  }
}
