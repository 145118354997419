import {AfterViewInit, ComponentFactoryResolver, ComponentRef, Directive, Input, Optional, Self, ViewContainerRef} from '@angular/core';
import {AbstractControl, NgControl} from '@angular/forms';
import {AppErrorsComponent} from '../components/form-control-error.component';


@Directive({
  selector: '[appFormControl]',
})
export class AppFormControlDirective implements AfterViewInit {
  @Input('appFormControl')
  explicitControl?: AbstractControl;


  constructor(
    @Optional() @Self() private control: NgControl,
    private vcr: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
  ) {}


  ngAfterViewInit(): void {
    const control = this.explicitControl ? this.explicitControl : this.control.control;

    const factory = this.resolver.resolveComponentFactory(AppErrorsComponent);

    let component: ComponentRef<AppErrorsComponent>;

    setTimeout(() => {
      component = this.vcr.createComponent(factory);
      component.instance.control = control;
      control['errorsComponentRef'] = component;
      control['parentElementRef'] = this.vcr.element.nativeElement.parentElement;
    }, 0);
  }
}
