import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatSlideToggleChange } from "@angular/material";
import { classToPlain } from "class-transformer";
import { FormComponentContainer } from "lib-shared";
import { DynamicFormGroup } from "ngx-dynamic-form-builder";
import { MatIconEnum } from "../shared/models/common/mat-icon.enum";
import { MinimumPalletLoadPercents } from "../shared/models/product/enums/minimum-pallet-load-percents.enum";
import { MixedPalletSettingForm } from "../shared/models/product/product-list/mixed-pallet-setting.form";
import { ProductListItem } from "../shared/models/product/product-list/product-list-item";
import { PopupService } from "../shared/services/popup.service";
import { MixedPalletService } from "../shared/services/product/mixed-pallet.service";
import { ProductListService } from "../shared/services/product/product-list.service";

@Component({
    selector: 'app-mixed-pallet-setting',
    templateUrl: './mixed-pallet-setting.component.html',
    styleUrls: ['./mixed-pallet-setting.component.scss']
})
export class MixedPalletSettingComponent extends FormComponentContainer<MixedPalletSettingForm> implements OnInit {

    showSelectedProducts: boolean = false;
    productList: ProductListItem[]= [];
    readonly MatIconEnum = MatIconEnum;
    readonly minimumAvailablePalletPercents = new Map<string, MinimumPalletLoadPercents>([
        ['25%', MinimumPalletLoadPercents.PER_25],
        ['50%', MinimumPalletLoadPercents.PER_50],
        ['75%', MinimumPalletLoadPercents.PER_75],
        ['100%', MinimumPalletLoadPercents.PER_100],
      ]);
    isDeleted: boolean = false;
    showSpinner = false;

    @Input()
    mixedPallet: MixedPalletSettingForm;

    @Output()
    refreshList = new EventEmitter<void>();

    constructor(
        private _productListService: ProductListService,
        private mixedPalletService: MixedPalletService,
        private showPop: PopupService,
      ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this._resetForm(this.mixedPallet);
        this.getMixedPalletProducts();
        this.showSelectedProducts = !this.mixedPallet.for_all_products;
    
    }

    setMinimumPalletPercents(percents: MinimumPalletLoadPercents): void {
        this.form.controls['minimum_pallet_load_percents'].setValue(percents);
      }

    protected _getFormGroup(): DynamicFormGroup<MixedPalletSettingForm> {
        const form = this._fb.group(MixedPalletSettingForm, classToPlain(new MixedPalletSettingForm()));
        return form;
    }
    
    private async getMixedPalletProducts(): Promise<void> {
        try {
          this.productList = await this._productListService.getMixedPalletProducts();
        }
        catch (e) {
          this._handleBackendErrors
        }
      }
    
    public toggle(event: MatSlideToggleChange) {  
        this.showSelectedProducts = !event.checked;
    }
    
    async onSave(): Promise<void> {
        this.messages.clearMessages();
        this.showSpinner = true;

        this._validateForm();

        if(this.showSelectedProducts == true && (this.form.object.for_selected_products == null || this.form.object.for_selected_products.length == 0)){
            this.errors.setErrorsFromBackend({for_selected_products: 'Please select any product.'});
            this.form.controls['for_selected_products'].markAsTouched();
            return;
        }
        this.form.controls['for_selected_products'].markAsTouched();
        if(this.form.valid){
            if(this.form.object.for_all_products == true){
                this.form.controls['for_selected_products'].setValue([]);
            }
            try {
                await this.mixedPalletService.editMixedPallet(this.form.value);
                await this.showPop.showSuccessPopup("Mixed pallet settings updated successfully.");
                this.refreshList.emit();
            }
            catch (err) {
                this._handleBackendErrors(err);
                setTimeout(function() {
                    window.scrollTo(0, document.body.scrollHeight);
                  }, 100);
            }
        }
        this.showSpinner = false;
    }

    async deleteMixedPallet(mixedPallet:  MixedPalletSettingForm): Promise<void> {
        this.messages.clearMessages();
        this.showSpinner = true;

        const response = await this.showPop.showDeletePopup(`Do you want to delete this setting?`);
        try {
            if(response){
                await this.mixedPalletService.deleteMixedPallet(mixedPallet.id)
                this.isDeleted = true;
                this.refreshList.emit();
            }
        }
        catch(err){
            this._handleBackendErrors(err)
        }
        this.showSpinner = false;
    }
    
}