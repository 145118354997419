import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageListComponent } from './message-list.component';
import {MessageModule} from '../message/message.module';

@NgModule({
  imports: [
    CommonModule,
    MessageModule
  ],
  declarations: [MessageListComponent],
  exports: [
    MessageListComponent
  ]
})
export class MessageListModule { }
