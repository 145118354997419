import {GeneralMessageType} from './enums/general-message-type.enum';

export class GeneralMessage {
  type: GeneralMessageType;
  title: string;
  payload: Object;

  constructor(title: string, type: GeneralMessageType, payload: Object) {
    this.type = type;
    this.title = title;
    this.payload = payload;
  }
}
