import {TourActionType} from '../models/tour-action.enum';
import {TourTypes} from '../models/tour-types';
import {plainToClass} from 'class-transformer';
import {TourModel} from '../models/tour.model';
import {TourStatusesEnum} from '../models/tour-statuses.enum';

export const editNutritionalTour = plainToClass(TourModel, {
  type: TourTypes.EDIT_NUTRITIONAL,
  status: TourStatusesEnum.BLOCKED,
  title: 'How to manually add nutritional info for a product',
  unlockConditions: 'Please add product with "Food" type',
  list: [
    {
      action: TourActionType.HIGHLIGHT,
      description: 'To start adding the nutritional info go to the Products tab',
      element: TourTypes.EDIT_NUTRITIONAL + '0',
      title: 'How to manually add nutritional info for a product',
      isElementFixed: true
    },
    {
      action: TourActionType.FORM,
      description: 'Edit one of your added products',
      element: TourTypes.EDIT_NUTRITIONAL + '1',
      title: 'How to manually add nutritional info for a product',
      isElementFixed: false
    },
    {
      action: TourActionType.FORM,
      description: 'Go to the nutrition tab',
      element: TourTypes.EDIT_NUTRITIONAL + '2',
      title: 'How to manually add nutritional info for a product',
      isElementFixed: false,
      isProbablyFixed: true
    },
    {
      action: TourActionType.FORM,
      description: 'Add the new nutrition',
      element: TourTypes.EDIT_NUTRITIONAL + '3',
      title: 'How to manually add nutritional info for a product',
      isElementFixed: false
    },
    {
      action: TourActionType.TIP,
      description: 'And fill all required fields in the nutritional form',
      element: TourTypes.EDIT_NUTRITIONAL + '4',
      title: 'How to manually add nutritional info for a product',
      isElementFixed: false
    },
    {
      action: TourActionType.HIGHLIGHT,
      description: 'Finally press "Add" button',
      element: TourTypes.EDIT_NUTRITIONAL + '5',
      title: 'How to manually add nutritional info for a product',
      isElementFixed: false,
      resizable: true
    }
  ]
});
