import { Injectable } from '@angular/core';
import {HttpServiceAbstract} from '../models/components/http-service.abstract';
import { TokenRestorationType } from './token-restoration-type.enum';
import { EmailDto } from './email.dto';

@Injectable()
export class RequestLinkService extends HttpServiceAbstract {

	protected _apiUrl = '/request_link';

	async sendLinkRequest(email: string, linkType: TokenRestorationType): Promise<void> {
		const body = new EmailDto(email);
		const params = this._constructParamsFromObject({
			type: linkType
		});

		return this._post<void>(this._url, body, null, params);
	}

}
