<div
  matRipple
  [matRippleDisabled]="disabled"
  [class]="type + ' ' + color"
  [ngClass]="{'disabled': disabled, 'shadow': shadow}"
>
  <span
    class="button-name"
    [class.hidden]="showSpinner"
  >
    <ng-content></ng-content>
  </span>
  <mat-progress-spinner
    *ngIf="showSpinner"
    [mode]="'indeterminate'"
    [diameter]="spinnerParameters.get(type).diameter"
    [strokeWidth]="spinnerParameters.get(type).strokeWidth"
  ></mat-progress-spinner>
</div>
