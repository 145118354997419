import { Component, OnInit } from '@angular/core';
import {BusinessRulesService} from '../../shared/services/product/business-rules.service';
import {MinimumPalletLoadPercents} from '../../shared/models/product/enums/minimum-pallet-load-percents.enum';
import {FormComponentContainer} from '../../shared/modules/form-validation';
import {ProductsSettingsForm} from './models/products-settings.form';
import {DynamicFormGroup} from 'ngx-dynamic-form-builder';
import {classToPlain} from 'class-transformer';
import { MatSlideToggleChange } from '@angular/material';
import { OrderWeightService } from '../../shared/services/product/order-weight.service';
import { OrderWeightForm } from '../../shared/models/product/product-list/order-weight.form';
import { Id } from 'lib-shared/shared/models/types';
import { MatIconEnum } from '../../shared/models/common/mat-icon.enum';
import { Messages } from '../../shared/helpers/messages';
import { HttpErrorResponse } from '@angular/common/http';
import { OrderQuantityForm } from '../../shared/models/product/product-list/order-quantity.form';
import { OrderQuantityService } from '../../shared/services/product/order-quantity.service';
import { MixedPalletSettingForm } from '../../shared/models/product/product-list/mixed-pallet-setting.form';
import { MixedPalletService } from '../../shared/services/product/mixed-pallet.service';
import { PopupService } from '../../shared/services/popup.service';
import { amountPercents } from '../../shared/data/static';
import { InputHelper } from 'lib-shared';
import { OrderCaseQuantityForm } from '../../shared/models/product/product-list/order-case-quantity.form';
import { OrderCaseQuantityService } from '../../shared/services/product/order-quantity-case.service';
import { ChargePerPalletForm } from '../../shared/models/product/product-list/charge-per-pallet.form';
import { ChargePerPalletService } from '../../shared/services/product/charge-per-pallet.service';


@Component({
  selector: 'app-product-settings',
  templateUrl: './product-settings.component.html',
  styleUrls: ['./product-settings.component.scss']
})
export class ProductSettingsComponent extends FormComponentContainer<ProductsSettingsForm> implements OnInit {

  showSpinner = false;

  showSelectedProducts: boolean = false;
  chargePerPalletServiceList: ChargePerPalletForm[] = [];
  orderWeightServiceList : OrderWeightForm[] = [];
  orderQuantityServiceList: OrderQuantityForm[] = [];
  orderCaseQuantityServiceList: OrderCaseQuantityForm[] = [];

  mixPalletServiceList: MixedPalletSettingForm[] = [];
  id : Id;

  readonly order_weight_setting_messages = new Messages();
  readonly order_quantity_setting_messages = new Messages();
  readonly order_case_quantity_setting_messages = new Messages();
  readonly charge_per_pallet_setting_messages = new Messages();
  readonly mixed_pallet_setting_messages = new Messages();
  readonly amountPercents = amountPercents;
  readonly InputHelper = InputHelper;


  readonly MatIconEnum = MatIconEnum;


  readonly minimumAvailablePalletPercents = new Map<string, MinimumPalletLoadPercents>([
    ['25%', MinimumPalletLoadPercents.PER_25],
    ['50%', MinimumPalletLoadPercents.PER_50],
    ['75%', MinimumPalletLoadPercents.PER_75],
    ['100%', MinimumPalletLoadPercents.PER_100],
  ]);

  constructor(
    private businessRulesService: BusinessRulesService,
    private chargePerPalletService: ChargePerPalletService,
    private orderWeightService: OrderWeightService,
    private mixedPalletService: MixedPalletService,
    private orderQuantityService: OrderQuantityService,
    private orderCaseQuantityService: OrderCaseQuantityService,
    private _popupService: PopupService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this._getBusinessRules();
    this.getOrderWeight();
    this.getOrderQuantity();
    this.getOrderCaseQuantity();
    this.getChargePerPallet();
    this.getMixpallet();

  }

  showPopUp():void {
    const all_products_for_mixed_pallet = this.form.controls['all_products_for_mixed_pallet'].value;
    if(all_products_for_mixed_pallet != true){
      this._popupService.showConfirmPopup('You agree that ALL products can be in a mixed pallet.');
    }
  }

  setMinimumPalletPercents(percents: MinimumPalletLoadPercents): void {
    this.form.controls['minimum_pallet_load_percents'].setValue(percents);
  }

  async updateBusinessRules(): Promise<void> {
    this.showSpinner = true;
    this.messages.clearMessages();
    this._validateForm();

    if(this.form.valid) {
      // if(this.form.object.for_all_products == true){
      //   this.form.controls['for_selected_products'].setValue([]);
      // }
      try {
        const updatedRules = await this.businessRulesService
          .setBusinessRules(this.form.value)
          .toPromise();
        this._resetForm(updatedRules);
      } catch (err) {
        this._handleBackendErrors(err);
      }
    }

    this.showSpinner = false;
  }

  protected _getFormGroup(): DynamicFormGroup<ProductsSettingsForm> {
    return this._fb.group(ProductsSettingsForm, classToPlain(new ProductsSettingsForm()));
  }

  private async _getBusinessRules(): Promise<void> {
    this.showSpinner = true;

    try {
      const updatedRules = await this.businessRulesService.getBusinessRules().toPromise();
      this._resetForm(updatedRules);
      // this.showSelectedProducts = !updatedRules.for_all_products;
    } catch (err) {
      this.messages.setBackendErrors(err);
    }

    this.showSpinner = false;
  }

  public toggle(event: MatSlideToggleChange) {  
    this.showSelectedProducts = !event.checked;
  }
  
  private async getMixpallet(): Promise<void>{
    try{
      this.mixPalletServiceList = await this.mixedPalletService.getMixedPallet();
    }
    catch (err){
      const error = err instanceof HttpErrorResponse ? err.error : err;
      this.mixed_pallet_setting_messages.setBackendErrors(error);
      this.errors.setErrorsFromBackend(error);
    }
  }

  async addMixedPallet(): Promise<void> {
    this.showSpinner = true;
    this.mixed_pallet_setting_messages.clearMessages();
    const mixedPallet = new MixedPalletSettingForm();

    try{
      const newMixedPallet = await this.mixedPalletService.addMixedPallet(mixedPallet);
      this.mixPalletServiceList.push(newMixedPallet);
    }
    catch (err){
      const error = err instanceof HttpErrorResponse ? err.error : err;
      this.mixed_pallet_setting_messages.setBackendErrors(error);
      this.errors.setErrorsFromBackend(error);
    }
    this.showSpinner = false;
  }

  private async getOrderWeight(): Promise<void> {
    try {
      this.orderWeightServiceList = await this.orderWeightService.getOrderWeight();
    }
   catch(err) {
    const error = err instanceof HttpErrorResponse ? err.error : err;
    this.order_weight_setting_messages.setBackendErrors(error);
    this.errors.setErrorsFromBackend(error);
    }
  }

  async addOrderWeight(): Promise<void> {
    this.showSpinner = true;
    this.order_weight_setting_messages.clearMessages();
    const orderWeight = new OrderWeightForm();
  
    try{
      const newOrderWeight = await this.orderWeightService.addOrderWeight(orderWeight);
      this.orderWeightServiceList.push(newOrderWeight);
    }
    catch(err){
      const error = err instanceof HttpErrorResponse ? err.error : err;
      this.order_weight_setting_messages.setBackendErrors(error);
      this.errors.setErrorsFromBackend(error);
    }
    this.showSpinner = false;
  }

  private async getOrderQuantity(): Promise<void> {
    try {
      this.orderQuantityServiceList = await this.orderQuantityService.getOrderQuantity();
    }
   catch(err) {
    const error = err instanceof HttpErrorResponse ? err.error : err;
    this.order_quantity_setting_messages.setBackendErrors(error);
    this.errors.setErrorsFromBackend(error);
    }
  }

  private async getOrderCaseQuantity(): Promise<void> {
    try {
      this.orderCaseQuantityServiceList = await this.orderCaseQuantityService.getOrderCaseQuantity();
    }
    catch(err) {
      const error = err instanceof HttpErrorResponse ? err.error : err;
      this.order_case_quantity_setting_messages.setBackendErrors(error);
      this.errors.setErrorsFromBackend(error);
    }
  }

  private async getChargePerPallet(): Promise<void> {
    try {
      this.chargePerPalletServiceList = await this.chargePerPalletService.getChargePerPallet();
    }
    catch(err) {
      const error = err instanceof HttpErrorResponse ? err.error : err;
      this.charge_per_pallet_setting_messages.setBackendErrors(error);
      this.errors.setErrorsFromBackend(error);
    }
  }


  async addOrderQuantity(): Promise<void> {
    this.showSpinner = true;
    this.order_quantity_setting_messages.clearMessages();
    const orderQuantity = new OrderQuantityForm();
    try{
      const newOrderQuantity = await this.orderQuantityService.addOrderQuantity(orderQuantity);
      this.orderQuantityServiceList.push(newOrderQuantity);
    }
    catch(err){
      const error = err instanceof HttpErrorResponse ? err.error : err;
      this.order_quantity_setting_messages.setBackendErrors(error);
      this.errors.setErrorsFromBackend(error);
    }
    this.showSpinner = false;
  }

  async addOrderCaseQuantity(): Promise<void> {
    this.showSpinner = true;
    this.order_case_quantity_setting_messages.clearMessages();
    const orderQuantityCase = new OrderCaseQuantityForm();
    try {
      const newOrderCaseQuantity = await this.orderCaseQuantityService.addOrderCaseQuantity(orderQuantityCase);
      this.orderCaseQuantityServiceList.push(newOrderCaseQuantity);      
    }
    catch(err){
      const error = err instanceof HttpErrorResponse ? err.error : err;
      this.order_case_quantity_setting_messages.setBackendErrors(error);
      this.errors.setErrorsFromBackend(error);
    }
    this.showSpinner = false;
  }

  async addChargePerPallet(): Promise<void> {
    this.showSpinner = true;
    this.charge_per_pallet_setting_messages.clearMessages();
    const chargePerPallet = new ChargePerPalletForm();
    try{
      const newChargePerPallet = await this.chargePerPalletService.addChargePerPallet(chargePerPallet);
      this.chargePerPalletServiceList.push(newChargePerPallet);
    }
    catch(err) {
      const error = err instanceof HttpErrorResponse ? err.error: err;
      this.charge_per_pallet_setting_messages.setBackendErrors(error);
      this.errors.setErrorsFromBackend(error);
    }
    this.showSpinner = false;
  }
}