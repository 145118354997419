import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SideMenuService} from '../../../services/side-menu.service';
import {ObservableComponent} from 'lib-shared';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent extends ObservableComponent implements OnInit {

  @Input()
  allowClosing = true;

  @Input()
  showCrossButton = true;

  @Input()
  excludeOnClickOutside = '';

  @Output()
  onCloseModal = new EventEmitter<void>();

  sideMenuOpened = false;


  constructor(
    private _sideMenuService: SideMenuService
  ) {
    super();
  }


  ngOnInit() {
    this._subscribeOnSideMenuOpenedStatus();
  }


  closeModal(): void {
    if(this.allowClosing) {
      this.onCloseModal.emit();
    }
  }


  private _subscribeOnSideMenuOpenedStatus(): void {
    this._sideMenuService
      .sideMenuOpenedObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(opened => this.sideMenuOpened = opened);
  }

}
