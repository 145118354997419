import { Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { DeactivateReason } from '../../models/product/product-deactivate-reason.enum';
@Component({
  selector: 'custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.scss']
})
export class CustomDialog {
  DeactivateReasons = DeactivateReason;
  deactivate_reason : string;
  validationErrors = false;

  constructor(
    public dialogRef: MatDialogRef<CustomDialog>,
    @Inject(MAT_DIALOG_DATA) public data: {msg: string, activate: boolean}
  ) {}

  confirm(): void {
    if (!this.data.activate) {
      if(this.deactivate_reason=="" || this.deactivate_reason==null) {
        this.validationErrors = true;
        return;
      }
      this.validationErrors = false;
    }
    this.dialogRef.close({data: this.deactivate_reason, action: 'confirm'});
  }

  decline(): void {
    this.dialogRef.close({action: 'decline'});
  }
}
