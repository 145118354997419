import {decoratorType} from 'lib-shared';
import {Type} from 'class-transformer';
import {Id, UrlPath} from '../../types';
import {ProductNutritionElementWithDv} from './product-nutrition-element-with-dv';
import {ProductNutritionElement} from './product-nutrition-element';
import {ProductNutritionElementCustom} from './product-nutrition-element-custom';


export class ProductNutrition {
  nutrition_name = '';
  serving_size = '';
  servings_per_container = '';
  calories: number = null;
  calories_from_fat: number = null;

  @Type(decoratorType(ProductNutritionElementWithDv))
  total_fat = new ProductNutritionElementWithDv(null, null);

  @Type(decoratorType(ProductNutritionElementWithDv))
  saturated_fat = new ProductNutritionElementWithDv(null, null);

  @Type(decoratorType(ProductNutritionElement))
  trans_fat = new ProductNutritionElement(null, null);

  @Type(decoratorType(ProductNutritionElementWithDv))
  cholesterol = new ProductNutritionElementWithDv(null, null);

  @Type(decoratorType(ProductNutritionElementWithDv))
  sodium = new ProductNutritionElementWithDv(null, null);

  @Type(decoratorType(ProductNutritionElementWithDv))
  total_carbohydrate = new ProductNutritionElementWithDv(null, null);

  @Type(decoratorType(ProductNutritionElementWithDv))
  dietary_fiber = new ProductNutritionElementWithDv(null, null);

  @Type(decoratorType(ProductNutritionElement))
  total_sugars = new ProductNutritionElement(null, null);

  @Type(decoratorType(ProductNutritionElementWithDv))
  includes_added_sugars = new ProductNutritionElementWithDv(null, null);

  @Type(decoratorType(ProductNutritionElement))
  protein = new ProductNutritionElement(null, null);

  @Type(decoratorType(ProductNutritionElementWithDv))
  vitamin_a = new ProductNutritionElementWithDv(null, null);

  @Type(decoratorType(ProductNutritionElementWithDv))
  vitamin_c = new ProductNutritionElementWithDv(null, null);

  @Type(decoratorType(ProductNutritionElementWithDv))
  calcium = new ProductNutritionElementWithDv(null, null);

  @Type(decoratorType(ProductNutritionElementWithDv))
  iron = new ProductNutritionElementWithDv(null, null);

  @Type(decoratorType(ProductNutritionElementWithDv))
  vitamin_d = new ProductNutritionElementWithDv(null, null);

  @Type(decoratorType(ProductNutritionElementWithDv))
  potassium = new ProductNutritionElementWithDv(null, null);

  ingredients = '';
  allergens = '';
  cooking_instructions = '';
  fat_free: boolean = null;
  low_fat: boolean = null;
  low_sodium: boolean = null;
  gluten_free: boolean = null;
  organic: boolean = null;
  non_gmo: boolean = null;
  kosher: boolean = null;
  halal: boolean = null;
  vegan: boolean = null;
  info = '';
  id: Id = null;
  image: UrlPath = '';

  @Type(decoratorType(ProductNutritionElementCustom))
  custom_nutritions: ProductNutritionElementCustom[] = [];


  get nutritionWithoutImage() {
    const nutrition = JSON.parse(JSON.stringify(this));
    delete nutrition.image;
    return nutrition;
  }
}
