import {CheckedProductsRequest} from './checked-products-request';
import {Id} from '../../../types';
import { FilterQuery } from '../../../../modules/blocks/filters/shared/models/filter-query';


export class CheckedProductsOnSaleToggleRequest extends CheckedProductsRequest {
  on_sale: boolean;
  deactivate_reason: string;
  constructor(ids: Id[], areAllProductsChecked: boolean, on_sale: boolean, deactivate_reason: string, filtersQuery: FilterQuery = new FilterQuery(), search: string) {
    super(ids, areAllProductsChecked, filtersQuery, search);
    this.on_sale = on_sale;
    this.deactivate_reason = deactivate_reason; 
  }
}
