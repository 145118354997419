import {TourTypes} from './tour-types';
import {TourItemModel} from './tour-item.model';
import {Type} from 'class-transformer';
import {decoratorType} from 'lib-shared';
import {TourStatusesEnum} from './tour-statuses.enum';

export class TourModel {
  type: TourTypes;
  title: string;
  status: TourStatusesEnum = TourStatusesEnum.AVAILABLE;
  @Type(decoratorType(TourItemModel))
  list: TourItemModel[];
  visibleAtList = true;
  unlockConditions: string = '';

  get isFinished(): boolean {
    return this.status === TourStatusesEnum.FINISHED;
  }

  get isBlocked(): boolean {
    return this.status === TourStatusesEnum.BLOCKED;
  }

  get isAvailable(): boolean {
    return this.status === TourStatusesEnum.AVAILABLE;
  }
}
