import {CheckedProductsRequest} from './checked-products-request';
import {Id} from '../../../types';
import { FilterQuery } from '../../../../modules/blocks/filters/shared/models/filter-query';


export class CheckedProductsDownloadRequest extends CheckedProductsRequest {
  export_type: string;

  constructor(ids: Id[], areAllProductsChecked: boolean, export_type: string, filtersQuery: FilterQuery = new FilterQuery(), search: string) {
    super(ids, areAllProductsChecked, filtersQuery, search);
    this.export_type = export_type;
  }
}
