import {ProductNutritionElementSign} from './enums/product-nutrition-element-sign.enum';
import {ProductNutritionElementUom} from './enums/product-nutrition-element-uom.enum';
import {productNutritionElementSigns} from './data/product-nutrition-element-signs';

export class ProductNutritionElement {
  value: string;
  sign: ProductNutritionElementSign;
  uom: ProductNutritionElementUom;

  protected _signs = productNutritionElementSigns;

  constructor(value: string, sign?: ProductNutritionElementSign, uom?: ProductNutritionElementUom) {
    this.value = value;
    this.sign = sign || ProductNutritionElementSign.EQUAL;
    this.uom = uom || ProductNutritionElementUom.MG;
  }

  get nutritionSign(): string {
    return this.sign === ProductNutritionElementSign.EQUAL
      ? ''
      : Array.from(this._signs.entries()).find(([key, value]) => value === this.sign)[0];
  }

  get valueText(): string {
    return this.nutritionSign + this.value + this.uom.toLowerCase();
  }

  isNutritionFilled(): boolean {
    return !!this.value && !!this.uom;
  }
}
