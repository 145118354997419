import {TourActionType} from '../models/tour-action.enum';
import {TourTypes} from '../models/tour-types';
import {plainToClass} from 'class-transformer';
import {TourModel} from '../models/tour.model';
import {TourStatusesEnum} from '../models/tour-statuses.enum';

export const goLiveTour = plainToClass(TourModel, {
  type: TourTypes.GO_LIVE,
  title: 'Add payment details to Go live',
  status: TourStatusesEnum.BLOCKED,
  unlockConditions: 'Make sure you fill out your profile, have access ' +
    'to products, add at least one FOB location and have NEW status',
  list: [
    {
      action: TourActionType.HIGHLIGHT,
      description: 'To start adding the payment details and go live let\'s open Products tab',
      element: TourTypes.GO_LIVE + '0',
      title: 'How to add the payment details to go live',
      isElementFixed: true
    },
    {
      action: TourActionType.FORM,
      description: 'Then press "Go live" button',
      element: TourTypes.GO_LIVE + '1',
      title: 'How to add the payment details to go live',
      isElementFixed: false
    },
    {
      action: TourActionType.FORM,
      description: 'Add the payment card',
      element: TourTypes.GO_LIVE + '2',
      title: 'How to add payment card',
      isElementFixed: false,
      highLevelElement: true
    },
    {
      action: TourActionType.FORM,
      description: 'Then fill the form and press the "Add card" button',
      element: TourTypes.GO_LIVE + '3',
      title: 'How to add payment card',
      isElementFixed: false,
      cancelElement: 'CANCEL',
      highLevelElement: true
    }
  ]
});
