import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusTextComponent } from './status-text.component';

@NgModule({
  exports: [StatusTextComponent],
  declarations: [StatusTextComponent],
  imports: [
    CommonModule
  ]
})
export class StatusTextModule { }
