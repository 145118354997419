import {TourActionType} from '../models/tour-action.enum';
import {TourTypes} from '../models/tour-types';
import {plainToClass} from 'class-transformer';
import {TourModel} from '../models/tour.model';
import {TourStatusesEnum} from '../models/tour-statuses.enum';

export const addNutritionalLabelTour = plainToClass(TourModel, {
  type: TourTypes.NUTRITIONAL_LABEL,
  status: TourStatusesEnum.BLOCKED,
  title: 'How to upload a nutritional label',
  unlockConditions: 'Please add product with "Food" type',
  list: [
    {
      action: TourActionType.HIGHLIGHT,
      description: 'To start uploading the nutritional label go to the Products tab',
      element: TourTypes.NUTRITIONAL_LABEL + '0',
      title: 'How to upload a nutritional label',
      isElementFixed: true
    },
    {
      action: TourActionType.FORM,
      description: 'Edit one of your added products',
      element: TourTypes.NUTRITIONAL_LABEL + '1',
      title: 'How to upload a nutritional label',
      isElementFixed: false
    },
    {
      action: TourActionType.FORM,
      description: 'Go to the nutrition tab',
      element: TourTypes.NUTRITIONAL_LABEL + '2',
      title: 'How to upload a nutritional label',
      isElementFixed: false,
      isProbablyFixed: true
    },
    {
      action: TourActionType.FORM,
      description: 'Add the new nutrition',
      element: TourTypes.NUTRITIONAL_LABEL + '3',
      title: 'How to upload a nutritional label',
      isElementFixed: false
    },
    {
      action: TourActionType.FORM,
      description: 'Upload the nutrition label',
      element: TourTypes.NUTRITIONAL_LABEL + '4',
      title: 'How to upload a nutritional label',
      isElementFixed: false
    },
    {
      action: TourActionType.HIGHLIGHT,
      description: 'Finally press "Add" button',
      element: TourTypes.NUTRITIONAL_LABEL + '5',
      title: 'How to upload a nutritional label',
      isElementFixed: false,
      resizable: true
    }
  ]
});
