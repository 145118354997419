import {ShippingAddressForm} from '../../../shared/modules/forms/shipping-address-form/models/shipping-address-form';
import {BillingAddressForm} from '../../../shared/modules/forms/billing-address-form/models/billing-address-form';
import {ArrayNotEmpty, IsArray} from 'class-validator';
import {applyMixins} from 'lib-shared';
import {VendorCompanyType} from './vendor-company-type.enum';
import {MyIsNotEmpty, MyIsOptional, MyIsNumberString} from '../../../shared/modules/form-validation/decorators';
import {fileNameDisplay} from '../../../shared/helpers/file-name-display';

const addressParameters = ['address', 'company_name', 'city', 'state', 'country', 'post_zip'];

class CompanyInfoForm implements ShippingAddressForm, BillingAddressForm {
  @IsArray()
  @ArrayNotEmpty({message: 'Please, select at least one option'})
  company_type: VendorCompanyType[] = [];

  @MyIsNotEmpty()
  company_name = '';

  @MyIsNotEmpty()
  company_email = '';

  @MyIsNotEmpty()
  contact_name = '';

  @MyIsNotEmpty()
  title = '';

  founded: number = null;
  fax = '';
  website = '';
  import_export_permits = '';

  @MyIsOptional()
  @MyIsNumberString()
  fda_registration_number = '';

  account_number = '';

  credit_application_file = null;

  // shipping address
  country = '';
  state = '';
  city = '';
  address = '';
  post_zip = '';
  phone_number = '';
  phone_ext = '';
  getFullAddress: () => string;

  // billing address
  billing_company_name = '';
  billing_country = '';
  billing_state = '';
  billing_city = '';
  billing_address = '';
  billing_post_zip = '';

  email_lists = [];
  c_email_lists = [];

  contact_lists = [];
  c_contact_lists = [];

  facility_phone_number_list = [];
  // f_phone_numbers = [];


  get isBillingAddressSimilarToCompanyAddress(): boolean {
    return addressParameters.every(parameter => this[parameter] === this[`billing_${parameter}`]);
  }

  get creditApplicationFileName(): string {
    return fileNameDisplay(this.credit_application_file);
  }


  setBillingAddressToCompanyAddress(): this {
    addressParameters.forEach(parameter => this[`billing_${parameter}`] = this[parameter]);
    return this;
  }
}

applyMixins(CompanyInfoForm, [ShippingAddressForm, BillingAddressForm]);

export {CompanyInfoForm};
