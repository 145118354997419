import { Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import { ArchiveReason } from '../../models/product/product-archive-reason.enum';

@Component({
  selector: 'delete-type-confirm-dialog',
  templateUrl: './delete-type-confirm-dialog.component.html',
  styleUrls: ['./delete-type-confirm-dialog.component.scss']
})
export class DeleteTypeConfirmDialogComponent {

  input: string;
  ArchiveReasons = ArchiveReason;
  archived_reason: string;

  readonly REQUIRED_INPUT_TEXT= 'yes';

  constructor(
    public dialogRef: MatDialogRef<DeleteTypeConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}


  get isInputCorrect(): boolean {
    if(this.isArchive) {
      return this.input === this.REQUIRED_INPUT_TEXT && this.archived_reason && this.archived_reason != '';
    }
    return this.input === this.REQUIRED_INPUT_TEXT;
  }

  get isArchive(): boolean {
    return this.data[1] == 'Archive';
  }

  confirmDelete(): void {
    if(this.isInputCorrect) {
      if(this.isArchive) {
        this.dialogRef.close({data: this.archived_reason, is_archive: true});
      }
      else {
        this.dialogRef.close(true);
      }
    }
  }

  declineDelete(): void {
    if(this.isArchive) {
      this.dialogRef.close({is_archive: false});
    }
    else {
      this.dialogRef.close(false);
    }
  }
}
