import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {GeneralMessage} from './models/general-message';
import {GeneralMessageType} from './models/enums/general-message-type.enum';
import {MessageStyle} from './models/enums/message-style.enum';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {

  @ViewChild('bodyWrapper')
  bodyWrapper: ElementRef;

  @Input()
  message: GeneralMessage;

  @Input()
  style: MessageStyle = MessageStyle.BLOCK;

  @Output()
  removeMessage = new EventEmitter<void>();

  readonly GeneralMessageType = GeneralMessageType;


  onRemoveMessage(): void {
    this.removeMessage.emit();
  }


  get isBodyEmpty(): boolean {
    return this.bodyWrapper && this.bodyWrapper.nativeElement.children.length === 0;
  }

  get isStyleBlock(): boolean {
    return this.style === MessageStyle.BLOCK;
  }

  get isStyleText(): boolean {
    return this.style === MessageStyle.TEXT;
  }

}
