export enum Colors {
  MAIN_ORANGE = '#ffac05',
  MAIN_RED = '#f7443a',
  LIGHT_RED = '#FFF3F1',
  MAIN_GREEN = '#61cc61',
  MAIN_BLUE = '#076ddc',
  LIGHT_BLUE = '#70D1FF',
  TABLE_BLUE = '#f9f9f9',
  TEXT_GRAY = '#9b9b9b',
  TEXT_LIGHT_GRAY = '#b7bcbf',
  GRAY_BCG = '#d8d8d8',
  MAIN_BLACK = '#041317',
  MAIN_WHITE = '#ffffff',
  TRANSPARENT = 'transparent'
}
