import {Injectable} from '@angular/core';
import {ApiService} from './api';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';


@Injectable()
export class LoginService {

  private _URL: string;

  constructor(
    private _http: HttpClient,
    private _api: ApiService
  ) {
    this._URL = `${_api.API}logout`;
  }


  async logout(): Promise<void> {
    await this._http
      .post<void>(this._URL, null)
      .toPromise();
    window.location.href = environment.customerUrl;
  }

}
