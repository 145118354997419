<div class="payment-widget" [attr.data-tour]="TourTypes.GO_LIVE + '2'">
  <app-button
    *ngIf="showBigAddButton"
    [color]="ButtonColorScheme.DASHED"
    (click)="openAddNewCard()"
    style="display: block;"
  >
    + Add Payment Card
  </app-button>

  <app-payment-item
    *ngIf="showDefaultPayment"
    [payment]="defaultPayment"
    [widgetMode]="true"
    (onChange)="openCardList()"
  ></app-payment-item>

  <app-widget-payments-list
    *ngIf="showCardList"
    (onChoose)="closeCardList()"
    (onAddNewPayment)="openAddNewCard()"
  ></app-widget-payments-list>

  <app-add-payment
    *ngIf="showAddNewCard"
    (onPaymentAdd)="closeAddNewCard()"
    (onClose)="closeAddNewCard()"
    [insideGoLiveModal]="true"
  ></app-add-payment>
</div>
