import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PaymentsService} from '../shared/services/payments.service';
import {applyMixins, ObservableComponent} from 'lib-shared';
import {Payment} from '../shared/models/payment';
import {ButtonColorScheme} from '../../../ux/button/models/button-color-scheme.enum';
import {TourImplementedComponent} from '../../../../../site-tour/shared/tour-implemented.component';
import {TourTypes} from '../../../../../site-tour/models/tour-types';
import {MonoTypeOperatorFunction} from 'rxjs';
import {TourService} from '../../../../../site-tour/services/tour.service';

@Component({
  selector: 'app-payments-widget',
  templateUrl: './payments-widget.component.html',
  styleUrls: ['./payments-widget.component.scss']
})
class PaymentsWidgetComponent extends ObservableComponent implements OnInit, AfterViewInit {

  @Output()
  selectedPayment = new EventEmitter<Payment>();

  defaultPayment: Payment;


  readonly ButtonColorScheme = ButtonColorScheme;


  private _showAddNewCard = false;
  private _showCardList = false;

  // TourImplementedComponent
  private _tourTypes: TourTypes[] = [
    TourTypes.GO_LIVE
  ];
  readonly TourTypes = TourTypes;
  private _tourItemIndex = 2;

  _subscribeOnCurrentTour: () => MonoTypeOperatorFunction<any>;
  _setClickHandler: (type: string, index: number, delay: number) => MonoTypeOperatorFunction<void>;
  _toggleNextTour: (type: string, index: number) => MonoTypeOperatorFunction<void>;

  constructor(
    private _paymentsService: PaymentsService,
    protected _tourService: TourService
  ) {
    super();
  }


  ngOnInit() {
    this._subscribeOnPayments();
  }


  get showDefaultPayment(): boolean {
    return !!this.defaultPayment && !this.showCardList && !this.showAddNewCard;
  }

  get showBigAddButton(): boolean {
    return !this.defaultPayment && !this.showAddNewCard;
  }

  get showAddNewCard(): boolean {
    return this._showAddNewCard;
  }

  get showCardList(): boolean {
    return this._showCardList && !this.showAddNewCard;
  }


  openAddNewCard(): void {
    this._showAddNewCard = true;
    this._setClickHandler(TourTypes.GO_LIVE,  2, 200);
  }

  closeAddNewCard(): void {
    this._showAddNewCard = false;
  }

  openCardList(): void {
    this._showCardList = true;
  }

  closeCardList(): void {
    this._showCardList = false;
  }


  private _subscribeOnPayments(): void {
    this._paymentsService
      .defaultPaymentObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(payment => {
        this.defaultPayment = payment;
        this.selectedPayment.emit(this.defaultPayment);

        if(this.showDefaultPayment) {
          setTimeout(() => {
            this._toggleNextTour(TourTypes.GO_LIVE_WITH_PAYMENT,  0);
          }, 200);
        }
      });
  }

  ngAfterViewInit(): void {
    this._subscribeOnCurrentTour();
  }

}

applyMixins( PaymentsWidgetComponent, [TourImplementedComponent] );

export { PaymentsWidgetComponent }
