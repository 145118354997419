import { Component, OnInit } from '@angular/core';
import {VendorStatus} from '../../../shared/models/status/vendor-status';
import {ButtonColorScheme} from '../../../shared/modules/ux/button/models/button-color-scheme.enum';
import {ButtonType} from '../../../shared/modules/ux/button/models/button-type.enum';
import {GoLiveService} from '../../../shared/services/vendor-status/go-live.service';
import {VendorStatusService} from '../../../shared/services/vendor-status/vendor-status.service';
import {Router} from '@angular/router';
import {GoLiveModalService} from '../shared/services/go-live-modal.service';
import {FormComponentContainer} from '../../../shared/modules/form-validation';
import {PromoCodeForm} from '../shared/components/promo-code-form-container/models/promo-code.form';
import {DynamicFormGroup} from 'ngx-dynamic-form-builder';
import {classToPlain} from 'class-transformer';
import {MatIconEnum} from '../../../shared/models/common/mat-icon.enum';
import {MessageStyle} from '../../../shared/modules/ux/message/models/enums/message-style.enum';
import {TourTypes} from '../../../site-tour/models/tour-types';
import {applyMixins, ObservableComponent} from 'lib-shared';
import {MonoTypeOperatorFunction} from 'rxjs';

@Component({
  selector: 'app-go-live-modal',
  templateUrl: './go-live-modal.component.html',
  styleUrls: ['./go-live-modal.component.scss']
})
class GoLiveModalComponent extends FormComponentContainer<PromoCodeForm> implements OnInit {

  showModal = false;
  showGoLiveButtonSpinner = false;

  vendorStatus: VendorStatus;

  isGoLiveFree = false;

  isGoLiveSucceed = false;

  // ObservableComponent
  _isComponentAlive = true;
  _takeWhileComponentAlive: () => MonoTypeOperatorFunction<any>;

  readonly ButtonColorScheme = ButtonColorScheme;
  readonly ButtonType = ButtonType;
  readonly MatIconEnum = MatIconEnum;
  readonly MessageStyle = MessageStyle;
  readonly GO_LIVE_COST = 99;
  readonly TourTypes = TourTypes;

  constructor(
    private _goLiveModalService: GoLiveModalService,
    private _goLiveService: GoLiveService,
    private _vendorStatusService: VendorStatusService,
    private _router: Router
  ) {
    super();
  }



  ngOnInit() {
    super.ngOnInit();
    this._subscribeOnShowGoLiveModal();
    this._subscribeOnVendorStatus();
  }


  get totalAmount(): number {
    return this.isGoLiveFree
      ? 0
      : this.GO_LIVE_COST;
  }


  closeModal(): void {
    this._goLiveModalService.closeModal();
    this.messages.clearMessages();
  }

  applyPromoCode(promoCodeForm: PromoCodeForm): void {
    this.form.setValue(promoCodeForm);
    this.isGoLiveFree = true;
  }

  navigateToRegistration(): void {
    this._router.navigate(['registration']);
    this.closeModal();
  }

  async goLive(): Promise<void> {
    this.showGoLiveButtonSpinner = true;
    this.messages.clearMessages();

    try {
      await this._goLiveService.goLive(this.form.object);
      this.isGoLiveSucceed = true;
      await this._vendorStatusService.updateVendorStatus();
    } catch(err) {
      this.messages.setBackendErrors(err);
    }

    this.showGoLiveButtonSpinner = false;
  }


  protected _getFormGroup(): DynamicFormGroup<PromoCodeForm> {
    return this._fb.group(PromoCodeForm, classToPlain(new PromoCodeForm()));
  }


  private _subscribeOnVendorStatus(): void {
    this._vendorStatusService
      .vendorStatusObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(status => this.vendorStatus = status);
  }

  private _subscribeOnShowGoLiveModal(): void {
    this._goLiveModalService
      .showModalObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(show => this.showModal = show);
  }
}

applyMixins(GoLiveModalComponent, [ObservableComponent]);

export {GoLiveModalComponent};
