import {Injectable} from '@angular/core';
import {GuardsCheckStart, Router} from '@angular/router';
import {filter} from 'rxjs/internal/operators';
import {RoutingHistoryPage} from './routing-history-page';

@Injectable()
export class RoutingHistoryService {
  private readonly _history: RoutingHistoryPage[] = [];
  private readonly _DEFAULT_URL = new RoutingHistoryPage('/');

  constructor(
    private _router: Router
  ) {}


  get history(): RoutingHistoryPage[] {
    return this._history;
  }

  get previousUrl(): RoutingHistoryPage {
    return this._history[this._history.length - 2] || this._DEFAULT_URL;
  }

  get currentUrl(): RoutingHistoryPage {
    return this._history[this._history.length - 1] || this._DEFAULT_URL;
  }


  initLoggingHistory(): void {
    this._router
      .events
      .pipe(filter(event => event instanceof GuardsCheckStart))
      .subscribe(({urlAfterRedirects}: GuardsCheckStart) => {
        const page = new RoutingHistoryPage(urlAfterRedirects);

        if(page.fullUrl !== this.currentUrl.fullUrl) {
          this._history.push(page);
        }
      });
  }

  getBackToRoute(routeIncludes: RegExp, defaultRoute: RoutingHistoryPage): void {
    const routeIndex = this._history.reverse().findIndex(route => !!route.url.match(routeIncludes));
    const previousRoute = this._history[routeIndex];

    routeIndex === -1
      ? this._navigateToRoute(defaultRoute)
      : this._navigateToRoute(previousRoute);
  }

  getBack(defaultRoute: RoutingHistoryPage = this._DEFAULT_URL): void {
    if(this._history.length <= 1) {
      this._navigateToRoute(defaultRoute);
    } else {
      this._navigateToRoute(this.previousUrl);
    }
  }


  private _navigateToRoute(route: RoutingHistoryPage): void {
    this._router.navigate([route.url], {queryParams: route.queryParams});
  }
}
