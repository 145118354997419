import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class TransactionsUpdateService {

  private _transactionsUpdate = new BehaviorSubject<void>(null);


  get transactionsUpdateObservable(): Observable<void> {
    return this._transactionsUpdate.asObservable();
  }


  emitTransactionsUpdate(): void {
    this._transactionsUpdate.next(null);
  }

}
