import {TourActionType} from '../models/tour-action.enum';
import {TourTypes} from '../models/tour-types';
import {plainToClass} from 'class-transformer';
import {TourModel} from '../models/tour.model';
import {TourStatusesEnum} from '../models/tour-statuses.enum';

export const addUserTour = plainToClass(TourModel, {
  type: TourTypes.ADD_USER,
  status: TourStatusesEnum.BLOCKED,
  title: 'How to add users to profile',
  unlockConditions: 'Please ensure to have Manage Users permission',
  list: [
    {
      action: TourActionType.HIGHLIGHT,
      description: 'To start adding the users go to Users page',
      element: TourTypes.ADD_USER + '0',
      title: 'How to add users to profile',
      isElementFixed: true
    },
    {
      action: TourActionType.HIGHLIGHT,
      description: 'Then press "Add new user" button to open the form',
      element: TourTypes.ADD_USER + '1',
      title: 'How to add users to profile',
      isElementFixed: false,
      isChild: true,
      childLevel: 1,
      nextDisabled: true
    },
    {
      action: TourActionType.FORM,
      description: 'Fill all fields at the form and select user permission level',
      element: TourTypes.ADD_USER + '2',
      title: 'How to add users to profile',
      isElementFixed: false,
      cancelElement: 'CANCEL'
    },
    {
      action: TourActionType.HIGHLIGHT,
      description: 'All added users are displayed here',
      element: TourTypes.ADD_USER + '3',
      title: 'How to add users to profile',
    },
  ]
});
