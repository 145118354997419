import {TourActionType} from '../models/tour-action.enum';
import {TourTypes} from '../models/tour-types';
import {plainToClass} from 'class-transformer';
import {TourModel} from '../models/tour.model';
import {TourStatusesEnum} from '../models/tour-statuses.enum';

export const addFobLocationTour = plainToClass(TourModel, {
  type: TourTypes.ADD_FOB_LOCATION,
  status: TourStatusesEnum.BLOCKED,
  title: 'How to add FOB location',
  unlockConditions: 'You should have permission to access the Company Profile',
  list: [
    {
      action: TourActionType.HIGHLIGHT,
      description: 'To start adding the FOB locations to your profile go to Company Profile tab',
      element: TourTypes.ADD_FOB_LOCATION + '0',
      title: 'How to add FOB location',
      isElementFixed: true
    },
    {
      action: TourActionType.HIGHLIGHT,
      description: 'Then go to Locations tab',
      element: TourTypes.ADD_FOB_LOCATION + '1',
      title: 'How to add FOB location',
      isElementFixed: false,
      isChild: true,
      childLevel: 2
    },
    {
      action: TourActionType.HIGHLIGHT,
      description: 'Lets create a new FOB Location',
      element: TourTypes.ADD_FOB_LOCATION + '2',
      title: 'How to add FOB location',
      isElementFixed: false,
      nextDisabled: true
    },
    {
      action: TourActionType.FORM,
      description: 'Then fill the form and press the "Save" button',
      element: TourTypes.ADD_FOB_LOCATION + '3',
      title: 'How to add FOB location',
      isElementFixed: false,
      cancelElement: 'CANCEL'
    },
    {
      action: TourActionType.HIGHLIGHT,
      description: 'Here is displaying all your locations',
      element: TourTypes.ADD_FOB_LOCATION + '4',
      title: 'How to add FOB location',
      isElementFixed: false
    }
  ]
});
