<div class="container">
  <div class="converter-wrapper">
    <h2 class="form-title">Video Constructor</h2>
    <form [formGroup]="form" class="main-form" novalidate>
      <div class="row">
        <div class="col-lg-2">
          <p class="menu-name">Title<span class="menu-required">*</span></p>
        </div>
        <div class="col-lg-10">
          <mat-form-field class="form-field">
            <textarea class="form-field__title" matInput formControlName="title_screen"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2">
          <p class="menu-name">Pictures<span class="menu-required">*</span></p>
        </div>
        <div class="col-lg-10">
          <div class="form-field">
            <input id="picture_1" type="file" hidden accept="image/*" (change)="fileUploaded($event)"/>
            <label for="picture_1" class="btn-fill btn label-btn">BROWSE</label>
            <span class="file-path">{{form.controls['picture_1'].value?.name}}</span>
          </div>
          <div class="form-field">
            <input id="picture_2" type="file" hidden accept="image/*" (change)="fileUploaded($event)"/>
            <label for="picture_2" class="btn-fill btn label-btn">BROWSE</label>
            <span class="file-path">{{form.controls['picture_2'].value?.name}}</span>
          </div>
          <div class="form-field" *ngIf="picture_3_visible">
            <input id="picture_3" type="file" hidden accept="image/*" (change)="fileUploaded($event)"/>
            <label for="picture_3" class="btn-fill btn label-btn">BROWSE</label>
            <span class="file-path">{{form.controls['picture_3'].value?.name}}</span>
          </div>
          <div class="form-field" *ngIf="picture_4_visible">
            <input id="picture_4" type="file" hidden accept="image/*" (change)="fileUploaded($event)"/>
            <label for="picture_4"  class="btn-fill btn label-btn">BROWSE</label>
            <span class="file-path">{{form.controls['picture_4'].value?.name}}</span>
          </div>
          <div class="form-field" *ngIf="picture_5_visible">
            <input id="picture_5" type="file" hidden accept="image/*" (change)="fileUploaded($event)"/>
            <label for="picture_5"  class="btn-fill btn label-btn">BROWSE</label>
            <span class="file-path">{{form.controls['picture_5'].value?.name}}</span>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="add_picture_button_visible">
        <div class="col-lg-2"></div>
        <div class="col-lg-10">
          <div class="form-field">
            <label  class="btn-fill btn label-btn" (click)="addPicture()">+</label>
          </div>
        </div>
      </div>
      <hr/>
      <div class="row">
        <div class="col-lg-2">
          <p class="menu-name">Pictures audio</p>
        </div>
        <div class="col-lg-10">
          <div class="form-field">
            <input id="pictures_audio" type="file" hidden accept="audio/*" (change)="fileUploaded($event)"/>
            <label for="pictures_audio"  class="btn-fill btn label-btn">BROWSE</label>
            <span class="file-path">{{form.controls['pictures_audio'].value?.name}}</span>
          </div>
        </div>
      </div>
      <hr/>
      <div class="row">
        <div class="col-lg-2">
          <p class="menu-name">Video 1<span class="menu-required">*</span></p>
        </div>
        <div class="col-lg-10">
          <div class="form-field">
            <input id="video_1" type="file" hidden accept="video/mp4,video/x-m4v,video/*" (change)="fileUploaded($event)"/>
            <label for="video_1"  class="btn-fill btn label-btn">BROWSE</label>
            <span class="file-path">{{form.controls['video_1'].value?.name}}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2">
          <p class="menu-name">Audio 1</p>
        </div>
        <div class="col-lg-10">
          <div class="form-field">
            <input id="audio_1" type="file" hidden accept="audio/*" (change)="fileUploaded($event)"/>
            <label for="audio_1"  class="btn-fill btn label-btn">BROWSE</label>
            <span class="file-path">{{form.controls['audio_1'].value?.name}}</span>
          </div>
        </div>
      </div>
      <hr/>
      <div class="row">
        <div class="col-lg-2">
          <p class="menu-name">Video 2</p>
        </div>
        <div class="col-lg-10">
          <div class="form-field">
            <input id="video_2" type="file" hidden accept="video/mp4,video/x-m4v,video/*" (change)="fileUploaded($event)"/>
            <label for="video_2"  class="btn-fill btn label-btn">BROWSE</label>
            <span class="file-path">{{form.controls['video_2'].value?.name}}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2">
          <p class="menu-name">Audio 2</p>
        </div>
        <div class="col-lg-10">
          <div class="form-field">
            <input id="audio_2" type="file" hidden accept="audio/*" (change)="fileUploaded($event)"/>
            <label for="audio_2"  class="btn-fill btn label-btn">BROWSE</label>
            <span class="file-path">{{form.controls['audio_2'].value?.name}}</span>
          </div>
        </div>
      </div>
      <hr/>
      <div class="row" *ngIf="video_3_visible">
        <div class="col-lg-2">
          <p class="menu-name">Video 3</p>
        </div>
        <div class="col-lg-10">
          <div class="form-field">
            <input id="video_3" type="file" hidden accept="video/mp4,video/x-m4v,video/*" (change)="fileUploaded($event)"/>
            <label for="video_3"  class="btn-fill btn label-btn">BROWSE</label>
            <span class="file-path">{{form.controls['video_3'].value?.name}}</span>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="video_3_visible">
        <div class="col-lg-2">
          <p class="menu-name">Audio 3</p>
        </div>
        <div class="col-lg-10">
          <div class="form-field">
            <input id="audio_3" type="file" hidden accept="audio/*" (change)="fileUploaded($event)"/>
            <label for="audio_3"  class="btn-fill btn label-btn">BROWSE</label>
            <span class="file-path">{{form.controls['audio_3'].value?.name}}</span>
          </div>
        </div>
      </div>
      <hr *ngIf="video_3_visible"/>
      <div class="row" *ngIf="video_4_visible">
        <div class="col-lg-2">
          <p class="menu-name">Video 4</p>
        </div>
        <div class="col-lg-10">
          <div class="form-field">
            <input id="video_4" type="file" hidden accept="video/mp4,video/x-m4v,video/*" (change)="fileUploaded($event)"/>
            <label for="video_4"  class="btn-fill btn label-btn">BROWSE</label>
            <span class="file-path">{{form.controls['video_4'].value?.name}}</span>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="video_4_visible">
        <div class="col-lg-2">
          <p class="menu-name">Audio 4</p>
        </div>
        <div class="col-lg-10">
          <div class="form-field">
            <input id="audio_4" type="file" hidden accept="audio/*" (change)="fileUploaded($event)"/>
            <label for="audio_4"  class="btn-fill btn label-btn">BROWSE</label>
            <span class="file-path">{{form.controls['audio_4'].value?.name}}</span>
          </div>
        </div>
      </div>
      <hr *ngIf="video_4_visible"/>
      <div class="row" *ngIf="video_5_visible">
        <div class="col-lg-2">
          <p class="menu-name">Video 5</p>
        </div>
        <div class="col-lg-10">
          <div class="form-field">
            <input id="video_5" type="file" hidden accept="video/mp4,video/x-m4v,video/*" (change)="fileUploaded($event)"/>
            <label for="video_5"  class="btn-fill btn label-btn">BROWSE</label>
            <span class="file-path">{{form.controls['video_5'].value?.name}}</span>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="video_5_visible">
        <div class="col-lg-2">
          <p class="menu-name">Audio 5</p>
        </div>
        <div class="col-lg-10">
          <div class="form-field">
            <input id="audio_5" type="file" hidden accept="audio/*" (change)="fileUploaded($event)"/>
            <label for="audio_5"  class="btn-fill btn label-btn">BROWSE</label>
            <span class="file-path">{{form.controls['audio_5'].value?.name}}</span>
          </div>
        </div>
      </div>
      <hr *ngIf="video_5_visible"/>
      <div class="row" *ngIf="add_video_button_visible">
        <div class="col-lg-2">
        </div>
        <div class="col-lg-10">
          <div class="form-field">
            <label  class="btn-fill btn label-btn" (click)="addVideo()">+</label>
          </div>
        </div>
      </div>
      <hr *ngIf="add_video_button_visible"/>
      <div class="row">
        <div class="col-lg-2">
          <p class="menu-name">Background music</p>
        </div>
        <div class="col-lg-10">
          <div class="form-field">
            <input id="background_music" type="file" hidden accept="audio/*" (change)="fileUploaded($event)"/>
            <label for="background_music"  class="btn-fill btn label-btn">BROWSE</label>
            <span class="file-path">{{form.controls['background_music'].value?.name}}</span>
          </div>
        </div>
      </div>
      <p class="error" *ngIf="error">{{error}}</p>
      <p class="message" *ngIf="progress">{{progress}}</p>
      <div class="row">
        <div class="col-lg-2">

        </div>
        <div class="col-lg-10">
          <app-button
            [disabled]="!form.valid || submit_disabled"
            (click)="uploadVideos()"
          >
            Process
          </app-button>
        </div>
      </div>
    </form>
  </div>
</div>
