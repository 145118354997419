import {TourActionType} from '../models/tour-action.enum';
import {TourTypes} from '../models/tour-types';
import {plainToClass} from 'class-transformer';
import {TourModel} from '../models/tour.model';
import {TourStatusesEnum} from '../models/tour-statuses.enum';

export const embedCodeTour = plainToClass(TourModel, {
  type: TourTypes.EMBED_CODE,
  status: TourStatusesEnum.BLOCKED,
  title: 'Epallet embed code guide',
  unlockConditions: 'Make sure you have at least one completed product and LIVE status',
  list: [
    {
      action: TourActionType.HIGHLIGHT,
      description: 'You can add Epallet eCODE to any website',
      element: TourTypes.EMBED_CODE + '0',
      title: 'Epallet eCode',
      isElementFixed: true
    },
    {
      action: TourActionType.HIGHLIGHT,
      description: 'Let\'s start from Product Buttons',
      element: TourTypes.EMBED_CODE + '1',
      title: 'Epallet eCode',
      isElementFixed: false
    },
    {
      action: TourActionType.HIGHLIGHT,
      description: 'Get code for selected product',
      element: TourTypes.EMBED_CODE + '2',
      title: 'Epallet eCode',
      isElementFixed: false,
      nextDisabled: true
    },
    {
      action: TourActionType.TIP,
      description: 'Here is the button configuration panel',
      element: TourTypes.EMBED_CODE + '3',
      title: 'Epallet eCode',
      isElementFixed: false,
      highLevelElement: true
    },
    {
      action: TourActionType.TIP,
      description: 'Here is the embed product button preview',
      element: TourTypes.EMBED_CODE + '4',
      title: 'Epallet eCode',
      isElementFixed: false,
      highLevelElement: true
    },
    {
      action: TourActionType.TIP,
      description: 'This is HTML code for product button',
      element: TourTypes.EMBED_CODE + '5',
      title: 'Epallet eCode',
      isElementFixed: false,
      highLevelElement: true
    }
  ]
});
