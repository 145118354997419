import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { Id } from '../../models/types';
import { ProductList } from '../../models/product/product-list/product-list';
import { CheckedProductsOnSaleToggleRequest } from '../../models/product/product-list/checked-products/checked-products-on-sale-toggle-request';
import { CheckedProductsRequest } from '../../models/product/product-list/checked-products/checked-products-request';
import { FilterQuery } from '../../modules/blocks/filters/shared/models/filter-query';
import { ProductSearchRequestOrdering } from '../../models/product/product-list/product-search-request-ordering';
import { ApiHttpService } from '../../../core/ApiHttp/api-http.service';
import {ProductListItem} from '../../models/product/product-list/product-list-item';
import {SearchHintsServiceAbstract} from '../../modules/blocks/filters/shared/search-hints-service.abstract';

@Injectable()
export class ProductListService implements SearchHintsServiceAbstract {

  private readonly _URL: string;

  constructor(
    private http: ApiHttpService
  ) {
    this._URL = 'vendor/products';
  }


  async getProducts(
    page: number,
    search: string,
    ordering: ProductSearchRequestOrdering[],
    filters: FilterQuery = new FilterQuery()
  ): Promise<ProductList> {
    let params = new HttpParams().set('page', page.toString());

    if (!!search) {
      params = params.append('search', search);
    }

    if (!!ordering) {
      params = params.append('ordering', ordering.toString());
    }

    params = params.append('page_size','20');

    params = filters.getFiltersHttpParams(params);
    
    return this.http
      .get<ProductList>(this._URL, params)
      .pipe(
        map(data => plainToClass(ProductList, data))
      )
      .toPromise();
  }

  async getArchivedProducts(
    page: number,
    search: string,
    ordering: ProductSearchRequestOrdering[],
    filters: FilterQuery = new FilterQuery()
  ): Promise<ProductList> {
    let params = new HttpParams()
      .set('page', page.toString())
      .append('archived', 'true');

    if (!!search) {
      params = params.append('search', search);
    }

    if (!!ordering) {
      params = params.append('ordering', ordering.toString());
    }

    params = params.append('page_size','20');

    params = filters.getFiltersHttpParams(params);


    return this.http
      .get<ProductList>(this._URL, params)
      .pipe(
        map(data => plainToClass(ProductList, data))
      )
      .toPromise();
  }

  async getListProduct(productId: Id): Promise<ProductListItem> {
    const url = `${this._URL}/${productId}/list_single`;

    return this.http
      .get<ProductListItem>(url)
      .pipe(
        map(product => plainToClass(ProductListItem, product))
      )
      .toPromise();
  }

  async getAllProducts(): Promise<ProductListItem[]> {
    const url = `${this._URL}/all`;

    return this.http
      .get<ProductListItem[]>(url)
      .pipe(
        map(product => plainToClass(ProductListItem, product))
      )
      .toPromise();
  }

  async getMixedPalletProducts(): Promise<ProductListItem[]> {
    const url = `${this._URL}/mixed_pallet`;

    return this.http.get<ProductListItem[]>(url).pipe(
      map(product => plainToClass(ProductListItem, product))
    ).toPromise();
  }

  async getSearchHints(search: string, archived: boolean = false): Promise<string[]> {

    let params = new HttpParams().append('search', search);

    if (archived) {
      params = params.append('archived', 'true');
    }

    return this.http
      .get<string[]>(`${this._URL}/search_completions`, params)
      .toPromise();
  }

  async archiveProducts(ids: Id[], checkedAllProducts: boolean , filtersQuery: FilterQuery = new FilterQuery(), search: string, archived_reason: string): Promise<void> {
    const data = new CheckedProductsRequest(ids, checkedAllProducts, filtersQuery, search);
    data['archived_reason'] = archived_reason;
    return this.http
      .post<void>(`${this._URL}/archive`, data)
      .toPromise();
  }

  async restoreProductsFromArchive(ids: Id[], checkedAllProducts: boolean, filtersQuery: FilterQuery = new FilterQuery(), search: string): Promise<void> {
    const data = new CheckedProductsRequest(ids, checkedAllProducts, filtersQuery, search);

    return this.http
      .post<void>(`${this._URL}/restore`, data)
      .toPromise();
  }

  async toggleProductsSaleStatus(ids: Id[], checkedAllProducts: boolean, onSale: boolean , deactivate_reason: string, filtersQuery: FilterQuery = new FilterQuery(), search: string): Promise<void> {
    const body = new CheckedProductsOnSaleToggleRequest(ids, checkedAllProducts, onSale, deactivate_reason, filtersQuery, search );

    return this.http
      .post<void>(`${this._URL}/set_on_sale`, body)
      .toPromise();
  }

  async cloneProduct(id): Promise<void> {
    return this.http
      .post<void>(`${this._URL}/${id}/clone`)
      .toPromise();
  }

  async setDeactivateReason(id: Id, deactivate_reason: string): Promise<void> {
    const body = {'deactivate_reason': deactivate_reason};
    return this.http
      .post<void>(`${this._URL}/${id}/set_deactivate_reason`, body)
      .toPromise();
  }
}
