import {SubscriptionPlan} from './subscription-plan.enum';

export class SubscriptionPlanDto {
  cost: string;
  period: string;
  plan: SubscriptionPlan;

  constructor(cost: string, period: string, plan: SubscriptionPlan) {
    this.cost = cost;
    this.period = period;
    this.plan = plan;
  }

  get isFree(): boolean {
    return this.plan === SubscriptionPlan.FREE;
  }
}
