import { Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { MatIconEnum } from '../../models/common/mat-icon.enum';

@Component({
    selector: 'success-dialog',
    templateUrl: './success-dialog.component.html',
    styleUrls: ['./success-dialog.component.scss']
}) 
export class SuccessDialog {

    readonly MatIconEnum = MatIconEnum;

    constructor(
        public dialogRef: MatDialogRef<SuccessDialog>,
        @Inject(MAT_DIALOG_DATA) public data: {msg: string}
      ) {}
}