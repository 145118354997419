export class HoleParamsModel {

  width: string;
  height: string;
  top: string;
  left: string;

  private readonly _width: number;
  private readonly _height: number;
  private readonly _top: number;
  private readonly _left: number;

  constructor(width: number, height: number, top: number, left: number) {
    this.width = width + 'px';
    this.height = height + 'px';
    this.top = top + 'px';
    this.left = left + 'px';

    this._width = width;
    this._height = height;
    this._top = top;
    this._left = left;
  }

  get clearWidth(): number {
    return this._width > 0 ? this._width : 0;
  }

  get clearHeight(): number {
    return this._height > 0 ? this._height : 0;
  }

  get clearLeft(): number {
    return this._left > 0 ? this._left : 0;
  }

  get clearTop(): number {
    return this._top > 0 ? this._top : 0;
  }

}
