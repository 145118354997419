import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CertificationRequestService} from '../shared/services/certification-request.service';
import {environment} from '../../environments/environment';
import {VendorStatusService} from '../shared/services/vendor-status/vendor-status.service';
import {AccessStatus} from './models/access-status.enum';
import {CertificateRequestQueryParams} from './models/certificate-request.query-params';
import { Messages } from '../shared/helpers/messages';
import { DynamicFormGroup } from 'ngx-dynamic-form-builder/lib/utils/dynamic-form-group';
import { CertificateRequestForm } from './models/certificate-request.form';
import { classToPlain } from 'class-transformer';
import { FormComponentContainer } from '../shared/modules/form-validation/models/form-component-container';

@Component({
  selector: 'app-certification-request',
  templateUrl: './certification-request.component.html',
  styleUrls: ['./certification-request.component.scss']
})
export class CertificationRequestComponent extends FormComponentContainer<CertificateRequestForm>implements OnInit {

  queryParams: CertificateRequestQueryParams;

  showLoader = true;

  certificateName: string;

  showDeclinedMessage = false;

  notDeclined = true;

  readonly messages = new Messages();

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _certRequestService: CertificationRequestService,
    private _vendorStatusService: VendorStatusService
  ) { 
      super(); 
    } 


  get isRequestApproved(): boolean {
    return this.queryParams.status === AccessStatus.APPROVE;
  }

  get isRequestDeclined(): boolean {
    return this.queryParams.status === AccessStatus.DECLINE;
  }

  async ngOnInit() {
    super.ngOnInit();
    await this._getInfo();
    await this._checkVendorStatus();
    if (this.isRequestApproved){
      this._sendStatus();
    }
    else{
    try{
      this.certificateName = await this._certRequestService.getCertificateName(this.queryParams.token);
      this.showLoader = false;
    }
    catch{
      window.location.href = environment.vendorUrl;
    }
    }
  }

  protected _getFormGroup(): DynamicFormGroup<CertificateRequestForm> {
    const form = this._fb.group(CertificateRequestForm, classToPlain(new CertificateRequestForm()));
    return form;
  }

  private _getInfo() {
    this.queryParams = new CertificateRequestQueryParams(this._activatedRoute.snapshot.params);
    this._checkVendorStatus();
  }

  private async _sendStatus(): Promise<void> {
    try {
      await this._certRequestService.sendCertificateRequestStatus(this.queryParams.token, this.queryParams.status);
      this.showLoader = false;
    } catch(err) {
      // console.log(err);
      this.messages.setBackendErrors(err);
      window.location.href = environment.vendorUrl;
    }
  }

  private async _checkVendorStatus(): Promise<void> {
    const status = await this._vendorStatusService.checkVendorStatus();
    if(!status.is_vendor) {
      window.location.href = environment.customerUrl;
    }
  }

  async onSubmit(): Promise<void>
  {
    this.showLoader = true;
    this._validateForm();

    if(this.form.valid){
      console.log(this.form.value);
      try{
        await this._certRequestService.saveVendorDeclinedReason(this.queryParams.token, this.form.value)
        this.notDeclined = false;
        this.showDeclinedMessage = true;
      }
      catch(err) {
        console.log(err);
        this._handleBackendErrors(err);
      }
    }
    else {
      this.messages.addErrorMessage('Declined reason missing.');
    }
    this.showLoader = false;
  }

}
