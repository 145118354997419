import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {ClassType} from 'class-transformer/ClassTransformer';
import {plainToClass} from 'class-transformer';

// TODO: apply among services
export abstract class HttpServiceAbstract {

  protected abstract _apiUrl: string;


  constructor(
    protected _http: HttpClient
  ) {}


  protected async _get<T>(url: string, classToTransform: ClassType<any> = null, params: HttpParams = new HttpParams()): Promise<T> {
    const response = await this._http
      .get<T>(url, {params})
      .toPromise();

    return this._tranformResponse<T>(classToTransform, response);
  }

  protected async _getBlobResponse(url: string): Promise<HttpResponse<Blob>> {

    return this._http
      .get<Blob>(url, {
        responseType: 'blob' as 'json',
        observe: 'response'
      })
      .toPromise();
  }

  protected async _getPdf(url: string): Promise<Blob> {
    const buffer = await this._http
      .get(url, { responseType: 'arraybuffer' })
      .toPromise();

    return new Blob([buffer], { type: 'application/pdf' });
  }

  protected async _post<T>(url: string, body = null, classToTransform: ClassType<any> = null, params: HttpParams = new HttpParams()): Promise<T> {
    const response = await this._http
      .post<T>(url, body, {params})
      .toPromise();

    return this._tranformResponse<T>(classToTransform, response);
  }

  protected async _patch<T>(url: string, body = null, classToTransform: ClassType<any> = null, params: HttpParams = new HttpParams()): Promise<T> {
    const response = await this._http
      .patch<T>(url, body, {params})
      .toPromise();

    return this._tranformResponse<T>(classToTransform, response);
  }

  protected async _delete<T>(url: string, classToTransform: ClassType<any> = null, params: HttpParams = new HttpParams()): Promise<T> {
    const response = await this._http
      .delete<T>(url, {params})
      .toPromise();

    return this._tranformResponse<T>(classToTransform, response);
  }


  protected get _url(): string {
    return `${environment.apiUrl}/api${this._apiUrl}`;
  }

  protected _constructParamsFromObject(object: Object): HttpParams {
    let params = new HttpParams();

    Object
      .entries(object)
      .filter(([key, value]) => !!value)
      .forEach(([key, value]) => params = params.append(key, value.toString()));

    return params;
  }


  private _tranformResponse<T>(classToTransform: ClassType<any> = null, response: T): T {
    return classToTransform
      ? plainToClass(classToTransform, response)
      : response;
  }
}
