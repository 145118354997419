import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule, MatInputModule, MatSnackBarModule} from '@angular/material';
import {ClipboardModule} from 'ngx-clipboard';
import {SharedModalComponent} from './shared-modal.component';
import {ShareReceiverFormComponent} from './share-form/share-receiver-form-list/share-receiver-form/share-receiver-form.component';
import {ShareReceiverFormListComponent} from './share-form/share-receiver-form-list/share-receiver-form-list.component';
import {ShareFormComponent} from './share-form/share-form.component';
import {RecaptchaFormsModule} from 'ng-recaptcha/forms';
import {RecaptchaModule} from 'ng-recaptcha';
import {ModalModule} from '../../../ux/modal/modal.module';
import {SuccessModalModule} from '../../../ux/success-modal/success-modal.module';
import {EmailFormModule} from '../../../forms/email-form/email-form.module';
import {FormValidationModule} from '../../../form-validation/form-validation.module';
import {ButtonModule} from '../../../ux/button/button.module';
import {InputWrapperModule} from '../../../inputs/input-wrapper/input-wrapper.module';
import {CopyToClipboardModule} from '../../../ux/copy-to-clipboard/copy-to-clipboard.module';

@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    SuccessModalModule,
    EmailFormModule,
    FormsModule,
    ReactiveFormsModule,
    FormValidationModule,
    MatInputModule,
    MatIconModule,
    ButtonModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    InputWrapperModule,
    CopyToClipboardModule
  ],
  declarations: [
    SharedModalComponent,
    ShareReceiverFormComponent,
    ShareReceiverFormListComponent,
    ShareFormComponent,
  ],
  exports: [
    SharedModalComponent,
  ]
})
export class SharedModalModule { }
