import { Component, OnInit } from '@angular/core';
import {VendorStatus} from '../../../models/status/vendor-status';
import {Router} from '@angular/router';
import {VendorStatusService} from '../../../services/vendor-status/vendor-status.service';
import {MainNavigationService} from '../../../services/main-navigation.service';
import {TermsOfUseService} from './shared/terms-of-use.service';
import {UserPermission} from '../../../models/user/user-permissions.enum';
import {ShowTermsOfUseModalService} from '../../../services/show-terms-of-use-modal.service';
import {LoginService} from '../../../services/login.service';
import {ObservableComponent} from 'lib-shared';
import {FobLocationsService} from '../../../../registration/fob-locations/shared/services/fob-locations.service';
import {ProfileService} from '../../../../registration/shared/services/profile.service';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent extends ObservableComponent implements OnInit {

  isAccepted = false;
  vendorStatus: VendorStatus;

  isShowSpinner = false;

  showModal = false;

  showFinishModal = false;


  constructor(
    private _router: Router,
    private _vendorStatusService: VendorStatusService,
    private _termsOfUseService: TermsOfUseService,
    private _mainMenu: MainNavigationService,
    private _showTermsOfUseModal: ShowTermsOfUseModalService,
    private _loginService: LoginService,
    private _fobLocationsService: FobLocationsService,
    private _profileService: ProfileService
  ) {
    super();
  }



  ngOnInit() {
    this._subscribeOnVendorStatus();
    this._subscribeOnShowModal();
  }



  async acceptTermsOfUse(): Promise<void> {

    if (!this.isAccepted) { return; }

    this.isShowSpinner = true;

    try {
      await this._termsOfUseService.acceptTermsOfUse();
      this.vendorStatus = await this._vendorStatusService.updateVendorStatus();
      const profileCompleted = await this._isProfileCompleted();
      this._createNextRoute();
      // here we can add logic for "complete modal" behavior
      if( !profileCompleted ) {
        this.openFinishModal();
      }
      this.showModal = false;
    } catch(err) {
      console.log(err);
    }

    this.isShowSpinner = false;
  }

  async logout(): Promise<void> {
    this.isShowSpinner = true;

    try {
      await this._loginService.logout();
    } catch(err) {
      console.log(err);
    }

    this.isShowSpinner = false;
  }

  openFinishModal(): void {
    this.showFinishModal = true;
  }

  closeFinishModal(): void {
    this.showFinishModal = false;
  }



  private _subscribeOnVendorStatus(): void {
    this._vendorStatusService
      .vendorStatusObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(status => this.vendorStatus = status);
  }

  private _createNextRoute(): void {
    const newRoute =  UserPermission.DASHBOARD;

    this._router.navigate([newRoute.toLowerCase()]);
    this._mainMenu.openMenu();
  }

  private _subscribeOnShowModal(): void {
    this._showTermsOfUseModal
      .showModalObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(showModal => this.showModal = showModal);
  }


  private async _isProfileCompleted(): Promise<boolean> {
    const facilities = await this._fobLocationsService.getAllFobLocation();
    const info = await this._profileService.getCompanyInfo();
    return !!info.contact_name && !!facilities.length;
  }

}
