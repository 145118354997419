<div
  class="modal-container"
  [class.opened]="sideMenuOpened"
>
  <div
    class="background"
    (click)="closeModal()"
  ></div>
  <app-card
    class="modal-card"
    [class.opened]="sideMenuOpened"
  >
    <img
      *ngIf="allowClosing && showCrossButton"
      class="close-btn"
      src="../../../../../assets/images/cross-gray.svg"
      alt="close"
      (click)="closeModal()"
    >
    <ng-content></ng-content>
  </app-card>
</div>
