import {EventEmitter, Output} from '@angular/core';
import {FormComponentContainer} from '../../../../../shared/modules/form-validation';
import {PromoCodeForm} from './models/promo-code.form';
import {DynamicFormGroup} from 'ngx-dynamic-form-builder';
import {classToPlain} from 'class-transformer';
import {MessageStyle} from '../../../../../shared/modules/ux/message/models/enums/message-style.enum';
import {Messages} from '../../../../../shared/helpers/messages';

export abstract class PromoCodeFormContainerComponentAbstract extends FormComponentContainer<PromoCodeForm> {

  @Output()
  onSuccessfulSubmit = new EventEmitter<PromoCodeForm>();

  showSpinner = false;
  promoCodeSucceed = false;
  promoCodeError = false;

  readonly MessageStyle = MessageStyle;


  async sendPromoCode(): Promise<void> {
    this.showSpinner = true;

    try {
      await this._sendPromoCodeRequest();
      this.promoCodeSucceed = true;
      this.onSuccessfulSubmit.emit(this.form.object);
    } catch (err) {
      const error = {[Messages.BACKEND_ERROR_KEY]: Object.values(err.error || err)[0]};
      this.promoCodeError = true;
      this.messages.setBackendErrors(error);
    }

    this.showSpinner = false;
  }

  clearForm(): void {
    this.form.reset();
    this.resetErrors();
  }

  resetErrors(): void {
    this.messages.clearMessages();
    this.promoCodeError = false;
  }


  protected _getFormGroup(): DynamicFormGroup<PromoCodeForm> {
    return this._fb.group(PromoCodeForm, classToPlain(new PromoCodeForm()));
  }

  protected abstract async _sendPromoCodeRequest(): Promise<void>;

}


