import {Component, Input} from '@angular/core';
import {Messages} from '../../../helpers/messages';
import {MessageStyle} from '../message/models/enums/message-style.enum';

@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss']
})
export class MessageListComponent {

  @Input()
  messages: Messages;

  @Input()
  style: MessageStyle = MessageStyle.BLOCK;

}
