import {Injectable} from '@angular/core';
import {ApiService} from '../../../shared/services/api';
import {HttpClient} from '@angular/common/http';
import {CompanyInfoForm} from '../../company-info/models/company-info.form';
import {map, tap} from 'rxjs/internal/operators';
import {plainToClass} from 'class-transformer';


@Injectable()
export class ProfileService {

  private _companyInfo: CompanyInfoForm;
  private readonly _URL: string;



  constructor(
    private _api: ApiService,
    private _http: HttpClient
  ) {
    this._URL = `${_api.API}vendor/profile`;
  }



  async getCompanyInfo(): Promise<CompanyInfoForm> {
    if (this._companyInfo) {
      return this._companyInfo;
    } else {
      return this._http
        .get<CompanyInfoForm>(this._URL)
        .pipe(
          map(companyInfo => plainToClass(CompanyInfoForm, companyInfo)),
          tap(profile => this._companyInfo = profile)
        )
        .toPromise();
    }
  }

  async sendCompanyInfo(info: CompanyInfoForm): Promise<CompanyInfoForm> {
    this._companyInfo = await this._http
      .post<CompanyInfoForm>(this._URL, info)
      .pipe(
        map(companyInfo => plainToClass(CompanyInfoForm, companyInfo))
      )
      .toPromise();

    return this._companyInfo;
  }

}
