import {PhoneNumberForm} from 'lib-shared';
import {MyIsNotEmpty} from '../../../form-validation/decorators';

export class ShippingAddressForm extends PhoneNumberForm {
  @MyIsNotEmpty()
  country = '';

  state = '';

  @MyIsNotEmpty()
  city = '';

  @MyIsNotEmpty()
  address = '';

  @MyIsNotEmpty()
  post_zip = '';


  getFullAddress(): string {
    return `${this.address}, ${this.city}, ${this.state ? this.state + ',' : ''} ${this.country}`;
  }

  facility_phone_number_list = [];
  // f_phone_numbers = [];
}
