import {ProfileTab} from './profile-tab.enum';
import {Params} from '@angular/router';

export class ProfileQueryParams {
  tab: ProfileTab;

  static constructProfileQueryParams(tab: ProfileTab): ProfileQueryParams {
    const params = {
      tab
    };

    return new this(params);
  }

  constructor(params: Params) {
    this.tab = params['tab'];
  }
}
