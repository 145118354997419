import {MyIsNotEmpty, MyMin} from '../../../../shared/modules/form-validation/decorators';
import {Id} from '../../../../shared/models/types';
import {ThresholdType} from './threshold-type.enum';
import {StartEndDatetimeForm} from 'lib-shared';

export class CouponRequestForm extends StartEndDatetimeForm {
  @MyIsNotEmpty()
  amount: number = null;

  only_for_first_order = false;
  max_global_applications: number = null;

  @MyIsNotEmpty()
  @MyMin(1)
  max_user_applications = 1;

  products: Id[] = [];

  quantity_threshold: number = null;
  quantity_threshold_type: ThresholdType = ThresholdType.CASES;


  start_date: string;
  end_date: string;
  format_start_date: string;
  format_end_date: string;

}
