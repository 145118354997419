<app-modal
  (onCloseModal)="closeModal()"
>
  <div class="container">
    <h3 class="title">To go Live you need to complete your profile</h3>
    <p class="subtitle">You can start adding products now and complete your profile later</p>

    <div class="buttons">
      <app-button
        (click)="navigateToRegistration()"
      >
        Complete Profile
      </app-button>

      <app-button
        [color]="ButtonColorScheme.DEFAULT"
        (click)="closeModal()"
      >
        Later
      </app-button>
    </div>
  </div>
</app-modal>
