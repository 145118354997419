<div class="certificate-request">
  <div class="container">

    <div class="logo">
      <!-- <img src="../../assets/images/epallet-logo-blue.svg" alt="logo"> -->
      <img src="../../assets/images/epallet-logo.png" alt="logo">

    </div>

    <div class="content max" *ngIf="!showLoader">
      <div class="content max" *ngIf="isRequestApproved">
        <h1>Request Access Approved</h1>
        <p>Customer will get email notification</p>
        <a class="link" [routerLink]="['/dashboard']">Go To Dashboard</a>
      </div>

      <div class="content" *ngIf="isRequestDeclined">
        <div *ngIf="notDeclined">
          <h1>Decline certificate name: {{ certificateName }}</h1>
          <form style="margin-top: 25px;"
            [formGroup]="form"
          >
            <div>
                <textarea 
                matInput
                placeholder="Enter declined reason here" 
                name="vendor_declined_reason"
                formControlName="vendor_declined_reason"
                rows="4"
                cols="50"
                style="margin-bottom: 10px;"
                required
               ></textarea>
              <div class="form-buttons">
                <app-button (click)="onSubmit()">
                  Submit
                </app-button>
              </div>
            </div>
          </form>
        </div>
        <div *ngIf="showDeclinedMessage">
          <h1>Request Access Rejected</h1>
          <p>Customer will get email notification</p>
        </div>
      </div>
    </div>

    <div class="content" *ngIf="showLoader">
      <mat-progress-spinner
        class="spinner"
        [mode]="'indeterminate'"
      >
      </mat-progress-spinner>
    </div>

    <div class="messages">
      <app-message-list
        [messages]="messages"
      ></app-message-list>
    </div>

  </div>
</div>
