import {Id, UrlPath} from '../../../../models/types';
import {fileNameDisplay} from '../../../../helpers/file-name-display';

export class BackendFile {
  readonly id?: Id;
  file: UrlPath;

  constructor(file: UrlPath) {
    this.file = file;
  }

  get fileName(): string {
    return fileNameDisplay(this.file);
  }
}
