import {TourActionType} from '../models/tour-action.enum';
import {TourTypes} from '../models/tour-types';
import {plainToClass} from 'class-transformer';
import {TourModel} from '../models/tour.model';
import {TourStatusesEnum} from '../models/tour-statuses.enum';

export const addProductTour = plainToClass(TourModel, {
  type: TourTypes.ADD_PRODUCT,
  status: TourStatusesEnum.BLOCKED,
  title: 'How to add the Product',
  unlockConditions: 'You should have permission to add Products',
  list: [
    {
      action: TourActionType.HIGHLIGHT,
      description: 'To start adding the product go to Products tab',
      element: TourTypes.ADD_PRODUCT + '0',
      title: 'How to add the Product',
      isElementFixed: true
    },
    {
      action: TourActionType.FORM,
      description: 'Let\'s add a new Product',
      element: TourTypes.ADD_PRODUCT + '1',
      title: 'How to add the Product',
      isElementFixed: false,
      nextDisabled: true
    },
    {
      action: TourActionType.FORM,
      description: 'Fill the product form step by step and press Save button.',
      element: TourTypes.ADD_PRODUCT + '2',
      title: 'How to add the Product',
      isElementFixed: false,
      cancelElement: 'CANCEL',
      isProbablyFixed: true
    }
  ]
});
