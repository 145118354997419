import { Injectable } from '@angular/core';
import {ApiService} from "../../shared/services/api";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {HttpRequest} from "@angular/common/http";

@Injectable()
export class VideoConverterService {

    constructor(
        private http: HttpClient,
        private api: ApiService
    ) { }

    createVideo(form: any): Observable<any> {
        let url = this.getUploadVideoURL();
        //return this.http.post(url, form);
        const req = new HttpRequest('POST', url, form, {
            reportProgress: true
        });
        return this.http.request(req);
    }

    getUploadVideoURL() {
        return this.api.API + 'vendor_process_video';
    }
}
