import {TourActionType} from '../models/tour-action.enum';
import {TourTypes} from '../models/tour-types';
import {plainToClass} from 'class-transformer';
import {TourModel} from '../models/tour.model';
import {TourStatusesEnum} from '../models/tour-statuses.enum';

export const goLiveWithPaymentTour = plainToClass(TourModel, {
  type: TourTypes.GO_LIVE_WITH_PAYMENT,
  title: 'Add payment details to Go live',
  visibleAtList: false,
  status: TourStatusesEnum.BLOCKED,
  list: [
    {
      action: TourActionType.CLICK,
      description: 'Make sure you select the correct card and press the "Go Live" button',
      element: TourTypes.GO_LIVE_WITH_PAYMENT + '0',
      title: 'How to add payment card',
      isElementFixed: true,
      cancelElement: 'CANCEL',
      highLevelElement: true,
      childLevel: 1,
      isChild: true
    }
  ]
});
