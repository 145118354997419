import {ArrayNotEmpty, IsArray} from 'class-validator';
import {SharedReceiverForm} from './shared-receiver.form';
import {EmailForm} from '../../../../../forms/email-form/models/email.form';
import {MyIsNotEmpty, MyMaxLength} from '../../../../../form-validation/decorators';

export class ShareForm extends EmailForm {

  @MyIsNotEmpty()
  @MyMaxLength(100)
  contact_name = '';

  comments = '';

  @MyIsNotEmpty({message: 'Please, fill the captcha'})
  recaptcha = '';

  @IsArray()
  @ArrayNotEmpty({message: 'Please, add at least one receiver'})
  receivers: SharedReceiverForm[] = [];


  get formattedForm(): this {
    const form = Object.assign({}, this);
    form.receivers = form
      .receivers
      .filter(formObject => !formObject.isFormEmpty)
      .reduce<SharedReceiverForm[]>((accum, curr) => {
        if(!accum.find(receiver => receiver.email === curr.email)) {
          accum.push(curr);
        }
        return accum;
      }, []);

    return form;
  }

}
