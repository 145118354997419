<form
*ngIf="!!form && !!errors && !isDeleted"
[formGroup]="form"
[appForm]="errors.errors"
(submit)="onSave()"
>

  <div class="settings">
    <div class="option1">
      <h3>Order minimum quantity (pallet)</h3>
      <app-icon-button
      [matIcon]="MatIconEnum.BIN"
      [width]="12"
      [height]="16"
      (click)="deleteOrderQuantity(orderquantity)"
    ></app-icon-button>
    </div>
          

    <div class="option2">
      <p>For All Products</p>
      <mat-slide-toggle
          formControlName="for_all_products"
          appFormControl
          (change)="toggle($event)"
        >
      </mat-slide-toggle>
    </div>

    <div class="option"  *ngIf="showSelectedProducts">    
      <app-input-wrapper
        title="For Selected Products"
        [formControlNameString]="'for_selected_products'"
      >
      <mat-select
          multiple
          formControlName="for_selected_products"
          appFormControl
        >
          <mat-option
            *ngFor="let product of productList"
            [value]="product.id"
          >
            {{ product.productName }}
          </mat-option>
        </mat-select>
      </app-input-wrapper>
    </div>

    <mat-form-field>
      <input
        type="number"
        matInput
        appAreYouSure
        [minQty]="750"
        formControlName="min_order_quantity"
        appFormControl
      >
    </mat-form-field>

    <div class="save_button">
      <input type="submit" style="display: none;" />
      <app-button 
      (click)="onSave()">
        Save
      </app-button>
    </div>
   
  </div>
  <div class="messages">
    <app-message-list
      [messages]="messages"
    ></app-message-list>
  </div>
  </form>