import {OnInit} from '@angular/core';
import {SharedItemBase} from '../../models/shared-item-base';
import {SharedModalServiceAbstract} from './shared-modal-service.abstract';
import {ObservableComponent} from 'lib-shared';

export class SharedModalComponentContainerAbstract extends ObservableComponent implements OnInit {

  sharedItem: SharedItemBase;


  constructor(
    protected _sharedModalService: SharedModalServiceAbstract
  ) {
    super();
  }


  ngOnInit() {
    this._subscribeOnSharedCartModal();
  }


  closeModal(): void {
    this._sharedModalService.closeModal();
  }


  private _subscribeOnSharedCartModal(): void {
    this._sharedModalService
      .showModalObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(item => this.sharedItem = item);
  }
}
