import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AreYouSureInputDirective } from './are-you-sure-input.directive';
import { AreYouSureHintComponent } from './are-you-sure-hint/are-you-sure-hint.component';
import {MarkInCircleLogoModule} from '../../modules/ux/mark-in-circle-logo/mark-in-circle-logo.module';
import {MatInputModule} from '@angular/material';

@NgModule({
  exports: [AreYouSureInputDirective],
  entryComponents: [AreYouSureHintComponent],
  declarations: [AreYouSureInputDirective, AreYouSureHintComponent],
  imports: [
    CommonModule,
    MarkInCircleLogoModule,
    MatInputModule
  ]
})
export class AreYouSureInputModule { }
