import {Component, OnInit} from '@angular/core';
import {RegistrationModalsService} from '../../../services/registration-modals.service';
import {ButtonColorScheme} from '../../ux/button/models/button-color-scheme.enum';
import {VendorStatusService} from '../../../services/vendor-status/vendor-status.service';
import {VendorStatus} from '../../../models/status/vendor-status';
import {GoLiveModalService} from '../../../../profile/account/shared/services/go-live-modal.service';
import {ObservableComponent} from 'lib-shared';

@Component({
  selector: 'app-registration-finish-modal',
  templateUrl: './registration-finish-modal.component.html',
  styleUrls: ['./registration-finish-modal.component.scss']
})
export class RegistrationFinishModalComponent extends ObservableComponent implements OnInit {

  showModal = false;

  vendorStatus: VendorStatus;

  readonly ButtonColorScheme = ButtonColorScheme;


  constructor(
    private _registrationFinishService: RegistrationModalsService,
    private _vendorStatusService: VendorStatusService,
    private _goLiveModalService: GoLiveModalService
  ) {
    super();
  }



  ngOnInit() {
    this._subscribeOnShowRegistrationFinishModal();
    this._subscribeOnVendorStatus();
  }



  closeModal(): void {
    this._registrationFinishService.closeModal();
  }

  goLive(): void {
    this._goLiveModalService.openModal();
    this.closeModal();
  }


  private _subscribeOnShowRegistrationFinishModal(): void {
    this._registrationFinishService
      .showModalObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(show => this.showModal = show);
  }

  private _subscribeOnVendorStatus(): void {
    this._vendorStatusService
      .vendorStatusObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(status => this.vendorStatus = status);
  }

}
