<app-card
  *ngIf="form"
  class="coupon-card"
  [class.success]="promoCodeSucceed"
  [class.error]="promoCodeError"
>
  <form
    class="coupon-form"
    [formGroup]="form"
  >
    <input
      class="coupon-input"
      type="text"
      placeholder="Enter promo code"
      formControlName="promo_code"
      maxlength="20"
      [disabled]="promoCodeSucceed"
      (paste)="resetErrors()"
      (keydown)="resetErrors()"
      (keypress.enter)="submit()"
    >

    <div class="icon">
      <app-icon-button
        class="arrow"
        *ngIf="canBeSubmitted"
        [width]="24"
        [height]="16"
        [matIcon]="MatIconEnum.ARROW_RIGHT_SMALL"
        (click)="submit()"
      ></app-icon-button>

      <app-icon-button
        *ngIf="promoCodeError"
        [width]="16"
        [height]="16"
        [matIcon]="MatIconEnum.CROSS"
        (click)="clearForm()"
      ></app-icon-button>

      <mat-icon
        *ngIf="promoCodeSucceed"
        [svgIcon]="MatIconEnum.TICK"
      ></mat-icon>

      <mat-spinner
        *ngIf="showSpinner"
        [diameter]="20"
        [strokeWidth]="3"
      ></mat-spinner>
    </div>
  </form>
</app-card>
