import { Id } from "lib-shared/shared/models/types";
import { MyIsNotEmpty, MyMin } from "../../../modules/form-validation/decorators";

export class OrderCaseQuantityForm {
  id : Id = null;

  @MyIsNotEmpty()
  @MyMin(1)
  min_order_case_quantity = 1;

  for_selected_products: Array<Id> = [];
  for_all_products: boolean = true;
}