import {Component, Input, ViewEncapsulation} from '@angular/core';
import {ButtonType} from './models/button-type.enum';
import {ButtonColorScheme} from './models/button-color-scheme.enum';
import {SpinnerConfig} from './models/spinner-config';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ButtonComponent {

  @Input()
  type: ButtonType = ButtonType.SIMPLE;

  @Input()
  color: ButtonColorScheme = ButtonColorScheme.PRIMARY;

  @Input()
  disabled = false;

  @Input()
  showSpinner = false;

  @Input()
  shadow = false;

  readonly spinnerParameters = new Map<ButtonType, SpinnerConfig>([
    [ButtonType.SIMPLE, new SpinnerConfig(3, 20)],
    [ButtonType.ROUND, new SpinnerConfig(2, 15)],
  ]);

}
