import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {TourTypes} from '../models/tour-types';
import {ActiveTourPointer} from '../models/active-tour-pointer.model';
import {TourModel} from '../models/tour.model';
import {addProductTour} from '../tour-pressets/add-product.tour';
import {addPaymentCardTour} from '../tour-pressets/add-payment-card.tour';
import {addUserTour} from '../tour-pressets/add-users-to-profile.tour';
import {addCouponTour} from '../tour-pressets/add-coupon.tour';
import {addFobLocationTour} from '../tour-pressets/add-fob-location.tour';
import {embedCodeTour} from '../tour-pressets/embed-code.tour';
import {archiveRestoreProductTour} from '../tour-pressets/archive-product.tour';
import {goLiveTour} from '../tour-pressets/go-live.tour';
import {addNutritionalLabelTour} from '../tour-pressets/add-nutritional-label.tour';
import {activateDeactivateProductsTour} from '../tour-pressets/activate-products.tour';
import {addProductRestrictionsTour} from '../tour-pressets/add-product-restrictions.tour';
import {bulkEditTour} from '../tour-pressets/bulk-edit.tour';
import {editNutritionalTour} from '../tour-pressets/edit-nutritional.tour';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../../shared/services/api';
import {TourStatusesEnum} from '../models/tour-statuses.enum';
import {goLiveWithPaymentTour} from '../tour-pressets/goLiveWithPayment.tour';

@Injectable()
export class TourService {

  private _availableToursList: TourModel[] = [
    addProductTour,
    addFobLocationTour,
    addNutritionalLabelTour,
    editNutritionalTour,
    addProductRestrictionsTour,
    bulkEditTour,
    activateDeactivateProductsTour,
    archiveRestoreProductTour,
    goLiveTour,
    goLiveWithPaymentTour,
    addPaymentCardTour,
    addUserTour,
    addCouponTour,
    embedCodeTour
  ];

  private readonly _tourAvailable = new BehaviorSubject<boolean>(false);
  private readonly _globalScrollHandler = new BehaviorSubject<boolean>(false);
  private readonly _tourActive = new BehaviorSubject<ActiveTourPointer>(null); // used in site tour component only
  private readonly _tourCurrent = new ReplaySubject<ActiveTourPointer>(null); // like unfinished tour but works without page reloading
  private readonly _toursList = new ReplaySubject<TourModel[]>(2); // like unfinished tour but works without page reloading

  private readonly _TOUR_STORAGE_PREFIX = 'APP-TOUR-UNFINISHED';

  private readonly _URL: string;


  constructor(
    private http: HttpClient,
    private api: ApiService
  ) {
    this._URL = `${api.API}vendor/tours`;
    this.loadToursStatuses();
  }

  private _checkTourAvailability(): void {
    const available = true; // In the future, can be retrieved from Api or localStorage
    this._tourAvailable.next(available);
  }

  get tourAvailableAsObservable(): Observable<boolean> {
    return this._tourAvailable.asObservable();
  }

  get scrollHandleAsObservable(): Observable<boolean> {
    return this._globalScrollHandler.asObservable();
  }

  get tourActiveAsObservable(): Observable<ActiveTourPointer> {
    return this._tourActive.asObservable();
  }

  get tourCurrentAsObservable(): Observable<ActiveTourPointer> {
    return this._tourCurrent.asObservable();
  }

  get toursListAsObservable(): Observable<TourModel[]> {
    return this._toursList.asObservable();
  }

  private _setUnfinishedTour(tourId: TourTypes, index = 0): void {
    localStorage.setItem(this._TOUR_STORAGE_PREFIX,  JSON.stringify( { type: tourId, index: index } ));
    this._tourCurrent.next(new ActiveTourPointer(tourId, index));
  }

  public scrollEmitter(): void {
    this._globalScrollHandler.next(true);
  }

  public detectUnfinishedTours(): void {
    // automatically set first tour as unfinished
    // localStorage.setItem(this._TOUR_STORAGE_PREFIX, JSON.stringify({type: TourTypes.ADD_PAYMENT_CARD, index: 0}));

    const unfinished = localStorage.getItem(this._TOUR_STORAGE_PREFIX);
    if ( unfinished ) {
      const {type, index} = JSON.parse(unfinished);
      this.setActiveTour(type, index);
    }
  }

  public async checkUnfinishedTour(tourId: TourTypes, unfinished_index: number = 0): Promise<void> {
    const unfinished = localStorage.getItem(this._TOUR_STORAGE_PREFIX);
    if ( unfinished ) {
      const {type, index} = JSON.parse(unfinished);
      if ( type === tourId && index === unfinished_index ) {
        this.setActiveTour(tourId, index);
      }
    }
  }

  public showNext( tourId: TourTypes, index: number ): void {
    if( this._tourActive.value && this._tourAvailable.value && tourId === this._tourActive.value.type ) {
      this._setUnfinishedTour(tourId, index);
    }
  }

  public setActiveTour(tourId: TourTypes, index = 0): void {
    // should be called after component init
    this._tourActive.next(new ActiveTourPointer(tourId, index));
  }

  public showAllTours(): void {
    this._tourAvailable.next(true);
  }

  public closeAllTours(): void {
    localStorage.removeItem(this._TOUR_STORAGE_PREFIX);
    this._tourActive.next(null);
    this._tourAvailable.next(false);
  }

  public startNewTour(tourId: TourTypes): void {
    localStorage.removeItem(this._TOUR_STORAGE_PREFIX);
    this.showAllTours();
    this.setActiveTour(tourId, 0);
  }

  async loadToursStatuses(): Promise<void> {
    try {
      const tours = await this.getToursStatusesFromApi();
      const list = this._availableToursList.map(tour => {
        const item = tours.find( ({id}) => id === tour.type );
        if( item && item.is_available ) {
          tour.status = TourStatusesEnum.AVAILABLE;
        } else {
          tour.status = TourStatusesEnum.BLOCKED;
        }
        return tour;
      });
      this._toursList.next( list );
      this._checkTourAvailability();
    } catch (e) {
      console.error('Tours Loading Error');
    }
  }

  async getToursStatusesFromApi(): Promise<Array<any>> {
    return this.http.get<Array<any>>(this._URL).toPromise();
  }

}
