import {Component, Input, ViewEncapsulation} from '@angular/core';
import {MatIconEnum} from '../../../models/common/mat-icon.enum';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IconButtonComponent {

  @Input()
  matIcon: MatIconEnum;

  @Input()
  width = 24;

  @Input()
  height = 24;

}
