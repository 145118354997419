<app-modal
  *ngIf="showModal"
  (onCloseModal)="closeModal()"
>
  <div
    *ngIf="!vendorStatus.can_go_live"
    class="container"
  >
    <h3 class="title">Congratulations, your profile is complete!</h3>

    <p class="subtitle">You don't have any products added, to go live add some products</p>

    <div class="buttons">
      <app-button
        [routerLink]="['/products']"
        (click)="closeModal()"
      >
        Start Adding Products
      </app-button>

      <app-button
        [color]="ButtonColorScheme.DEFAULT"
        [routerLink]="['/dashboard']"
        (click)="closeModal()"
      >
        Go to Dashboard
      </app-button>
    </div>
  </div>

  <div
    *ngIf="vendorStatus.can_go_live"
    class="container"
  >
    <h3 class="title">Congratulations, your profile is complete!</h3>

    <div class="buttons">
      <app-button
        [color]="ButtonColorScheme.GREEN"
        (click)="goLive()"
      >
        Go Live
      </app-button>

      <app-button
        [color]="ButtonColorScheme.DEFAULT"
        [routerLink]="['/dashboard']"
        (click)="closeModal()"
      >
        Go to Dashboard
      </app-button>
    </div>
  </div>
</app-modal>
