import {registerDecorator, ValidationArguments, ValidationOptions} from 'class-validator';

export function MatchField(property: string, validationOptions: ValidationOptions = {}) {
  const options = Object.assign({
    message: 'This field should be equal to \'$constraint1\' field'
  }, validationOptions);

  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'matchField',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [property],
      options,
      validator: {
        validate(value: any, args: ValidationArguments) {
          const [relatedPropertyName] = args.constraints;
          const relatedValue = (args.object as any)[relatedPropertyName];
          return  typeof value === 'string' &&
            typeof relatedValue === 'string' &&
            value === relatedValue;
        }
      }
    });
  };
}
