import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountComponent } from './account.component';
import { SubscriptionCardComponent } from './subscription-card/subscription-card.component';
import { TransactionsListComponent } from './transactions-list/transactions-list.component';
import {PaymentsModule} from '../../shared/modules/blocks/payments/payments.module';
import {SubscriptionsService} from './shared/services/subscriptions.service';
import {CardModule} from '../../shared/modules/ux/card/card.module';
import {IconButtonModule} from '../../shared/modules/ux/icon-button/icon-button.module';
import { SubscriptionsListComponent } from './subscriptions-list/subscriptions-list.component';
import { SubscriptionsListItemComponent } from './subscriptions-list/subscriptions-list-item/subscriptions-list-item.component';
import { SubscriptionChangeModalComponent } from './subscription-change-modal/subscription-change-modal.component';
import {ModalModule} from '../../shared/modules/ux/modal/modal.module';
import {ButtonModule} from '../../shared/modules/ux/button/button.module';
import { PromoCodeFormComponent } from './shared/components/promo-code-form-container/promo-code-form/promo-code-form.component';
import { SubscriptionPromoCodeComponent } from './subscription-change-modal/subscription-promo-code/subscription-promo-code.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PaginationModule, TableModule} from 'lib-shared';
import {FormValidationModule} from '../../shared/modules/form-validation/form-validation.module';
import {MessageListModule} from '../../shared/modules/ux/message-list/message-list.module';
import {MatIconModule, MatProgressSpinnerModule, MatTableModule} from '@angular/material';
import {SharedModule} from '../../shared/shared.module';
import { GoLiveModalComponent } from './go-live-modal/go-live-modal.component';
import { GoLivePromoCodeFormComponent } from './go-live-modal/go-live-promo-code-form/go-live-promo-code-form.component';
import {TransactionsService} from './shared/services/transactions.service';
import {TotalAmountModule} from '../../shared/modules/blocks/total-amount/total-amount.module';
import {CorrectTickModule} from '../../shared/modules/blocks/correct-tick/correct-tick.module';
import { SubscriptionInformationModalComponent } from './subscription-card/subscription-information-modal/subscription-information-modal.component';
import {StatusTextModule} from '../../shared/modules/ux/status-text/status-text.module';

@NgModule({
  declarations: [
    AccountComponent,
    SubscriptionCardComponent,
    TransactionsListComponent,
    SubscriptionsListComponent,
    SubscriptionsListItemComponent,
    SubscriptionChangeModalComponent,
    PromoCodeFormComponent,
    SubscriptionPromoCodeComponent,
    GoLiveModalComponent,
    GoLivePromoCodeFormComponent,
    SubscriptionInformationModalComponent,
  ],
  imports: [
    CommonModule,
    PaymentsModule,
    CardModule,
    ButtonModule,
    ModalModule,
    IconButtonModule,
    FormsModule,
    ReactiveFormsModule,
    FormValidationModule,
    MessageListModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTableModule,
    SharedModule,
    PaginationModule,
    TotalAmountModule,
    CorrectTickModule,
    StatusTextModule,
    TableModule
  ],
  providers: [SubscriptionsService, TransactionsService],
  exports: [AccountComponent, SubscriptionChangeModalComponent, GoLiveModalComponent]
})
export class AccountModule { }
