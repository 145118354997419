import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SubscriptionPlanDto} from '../../shared/models/subscription-plan.dto';
import {MatIconEnum} from '../../../../shared/models/common/mat-icon.enum';

@Component({
  selector: 'app-subscriptions-list-item',
  templateUrl: './subscriptions-list-item.component.html',
  styleUrls: ['./subscriptions-list-item.component.scss']
})
export class SubscriptionsListItemComponent {

  @Input()
  plan: SubscriptionPlanDto;

  @Input()
  selected = false;

  @Output()
  onSelect = new EventEmitter<void>();

  hovered = false;


  readonly MatIconEnum = MatIconEnum;


  select(): void {
    this.onSelect.emit();
  }

}
