import {MatDialog, MatDialogRef} from '@angular/material';
import {DeleteDialog} from '../components/delete-dialog/delete-dialog.component';
import {NoticeDialogComponent} from '../components/notice-dialog/notice-dialog.component';
import {Injectable} from '@angular/core';
import {DeleteTypeConfirmDialogComponent} from '../components/delete-type-confirm-dialog/delete-type-confirm-dialog.component';
import {ActionsDialog} from '../components/actions-dialog/actions-dialog.component';
import { CustomDialog } from '../components/custom-dialog/custom-dialog.component';
import { ProcessingDialog } from '../components/processing-dialog/processing-dialog.component';
import { SuccessDialog } from '../components/success-dialog/success-dialog.component';

@Injectable()
export class PopupService {

  constructor(
    private dialog: MatDialog
  ) {}

  showCustomDeletePopup(msg: string, activate: boolean): MatDialogRef<CustomDialog> {
    const dialogRef = this.dialog.open(CustomDialog, {
      data:{
        msg, activate
      },
      
      restoreFocus: false
    });
    return dialogRef;
  }

  showProcessingPopup(msg: string) : MatDialogRef<ProcessingDialog> {
    const dialogRef = this.dialog.open(ProcessingDialog, { 
      data: {
        msg
      },
      restoreFocus: false,
      disableClose: true
    });
    return dialogRef;
  }

  showSuccessPopup(msg: string) : MatDialogRef<SuccessDialog> {
    const dialogRef = this.dialog.open(SuccessDialog, {
      data: {
        msg
      },
      restoreFocus: false,
    });
    return dialogRef;
  }

  // TODO: make a class for {data: ...}
  showDeletePopup(msg: string, buttons: string[] = ['Yes', 'No']): Promise<boolean> {
    const dialogRef = this.dialog.open(DeleteDialog, {
      data: {
        msg,
        buttons
      },
      restoreFocus: false
    });

    return dialogRef.afterClosed().toPromise();
  }

  showActionsPopup(msg: string, buttons: string[] = ['Close'], closeButton: boolean = false, panelClass: string = ''): Promise<string> {
    const dialogRef = this.dialog.open(ActionsDialog, {
      data: {
        msg,
        buttons,
        closeButton
      },
      panelClass: panelClass,
      restoreFocus: false
    });

    return dialogRef.afterClosed().toPromise();
  }

  showTypeConfirmDeletePopup(msg: string, action: string = 'Delete'): Promise<boolean> {
    const dialogRef = this.dialog.open(DeleteTypeConfirmDialogComponent, {
      data: [msg, action],
      restoreFocus: false
    });

    return dialogRef.afterClosed().toPromise();
  }

  showConfirmPopup(msg: string): Promise<boolean> {
    const dialogRef = this.dialog.open(NoticeDialogComponent, {
      data: [msg],
      restoreFocus: false
    });

    return dialogRef.afterClosed().toPromise();
  }
}
