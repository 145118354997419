import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationFinishModalComponent } from './registration-finish-modal.component';
import {ModalModule} from '../../ux/modal/modal.module';
import {ButtonModule} from '../../ux/button/button.module';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    ButtonModule,
    RouterModule
  ],
  declarations: [RegistrationFinishModalComponent],
  exports: [RegistrationFinishModalComponent],
})
export class RegistrationFinishModalModule { }
