import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material';
import { classToPlain } from 'class-transformer';
import { FormComponentContainer} from 'lib-shared';
import { DynamicFormGroup } from 'ngx-dynamic-form-builder';
import { MatIconEnum } from '../shared/models/common/mat-icon.enum';
import { OrderWeightForm } from '../shared/models/product/product-list/order-weight.form';
import { ProductListItem } from '../shared/models/product/product-list/product-list-item';
import { PopupService } from '../shared/services/popup.service';
import { OrderWeightService } from '../shared/services/product/order-weight.service';
import { ProductListService } from '../shared/services/product/product-list.service';

@Component({
  selector: 'app-order-minimum-weight',
  templateUrl: './order-minimum-weight.component.html',
  styleUrls: ['./order-minimum-weight.component.scss']
})
export class OrderMinimumWeightComponent extends FormComponentContainer<OrderWeightForm> {
  showSelectedProducts: boolean = false;
  productList: ProductListItem[]= [];
  readonly MatIconEnum = MatIconEnum;
  isDeleted: boolean = false;
  showSpinner = false;

  @Output()
  refreshList = new EventEmitter<void>();

  @Input()
  orderweight: OrderWeightForm;


  constructor(
    private _productListService: ProductListService,
    private orderWeightService: OrderWeightService,
    private showPop: PopupService,
  ) { 
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this._resetForm(this.orderweight);
    this.showSelectedProducts = !this.orderweight.for_all_products;
    this.getAllProducts();
  }

  protected _getFormGroup(): DynamicFormGroup<OrderWeightForm> {
    const form = this._fb.group(OrderWeightForm, classToPlain(new OrderWeightForm()));
    return form;
  }


  private async getAllProducts(): Promise<void> {
    try {
      this.productList = await this._productListService
        .getAllProducts();
    } catch (err) {
      this._handleBackendErrors(err)
    }
  }

  public toggle(event: MatSlideToggleChange) {  
    this.showSelectedProducts = !event.checked;
  }

  async onSave(): Promise<void> {
    this.messages.clearMessages();
    this.showSpinner = true;

    this._validateForm();

    if(this.showSelectedProducts == true && (this.form.object.for_selected_products == null || this.form.object.for_selected_products.length == 0)){ 
      
      this.errors.setErrorsFromBackend({for_selected_products: 'Please select any product.'})
      this.form.controls['for_selected_products'].markAsTouched();
      return;
    }

    if(this.form.valid){
      if(this.form.object.for_all_products == true){
        this.form.controls['for_selected_products'].setValue([]);
      }
      try{
        await this.orderWeightService.editOrderWeight(this.form.value);
        await this.showPop.showSuccessPopup("Order minimum weight updated successfully.");
        this.refreshList.emit();
      }
      catch(err) {
        this._handleBackendErrors(err);
      }
    }
    this.showSpinner = false;

  }

  async deleteOrderWeight(orderweight:  OrderWeightForm): Promise<void> {
    this.messages.clearMessages();
    this.showSpinner = true;

    const response = await this.showPop.showDeletePopup(`Do you want to delete this setting?`);
    try{

      if(response)
      {
        const orderWeight = await this.orderWeightService.deleteOrderWeight(orderweight.id);  
        this.isDeleted = true;
        this.refreshList.emit();
      }
    }
    catch(err){
      this._handleBackendErrors(err);
    }
    this.showSpinner = false;

  }
}