import {Injectable} from '@angular/core';
import {ApiService} from '../../../../services/api';
import {HttpClient} from '@angular/common/http';


@Injectable()
export class TermsOfUseService {

  private readonly _URL: string;


  constructor(
    private _api: ApiService,
    private _http: HttpClient
  ) {
    this._URL = `${_api.API}vendor/terms_of_use`;
  }


  async acceptTermsOfUse(): Promise<void> {
    return this._http
      .post<void>(this._URL, null)
      .toPromise();
  }
}
