<app-modal
  *ngIf="showModal"
  class="go-live-modal"
  (onCloseModal)="closeModal()"
>
  <!-- <div
    *ngIf="vendorStatus.can_go_live && !isGoLiveSucceed"
    class="inner-container"
    [attr.data-tour]=" TourTypes.GO_LIVE_WITH_PAYMENT + '0'"
  >
    <div class="title">Complete to go live on Epallet</div>
    <div class="subtitle">You will be charged ${{ GO_LIVE_COST }} from the payment type on record</div>

    <app-payments-widget></app-payments-widget>

    <div class="subtitle promo-code-block">
      Or use a promo code
    </div>
    <app-go-live-promo-code-form
      (onSuccessfulSubmit)="applyPromoCode($event)"
    ></app-go-live-promo-code-form>

    <app-total-amount
      [amount]="totalAmount"
    ></app-total-amount>

    <div
      *ngIf="messages.hasMessages"
      class="messages"
    >
      <app-message-list
        [style]="MessageStyle.TEXT"
        [messages]="messages"
      ></app-message-list>
    </div>

    <app-button
      [showSpinner]="showGoLiveButtonSpinner"
      (click)="goLive()"
    >
      Go live
    </app-button>
  </div> -->

  <!-- <div
    *ngIf="!vendorStatus.can_go_live && !isGoLiveSucceed"
    class="inner-container"
  >
    <div class="title">You need to complete your profile</div>
    <div class="subtitle">Add information about your company, your payment information and locations of your warehouses</div>

    <app-button
      (click)="navigateToRegistration()"
    >
      Complete Profile
    </app-button>
  </div> -->

  <div
    class="inner-container"
  >
    <div class="title">Thank you!</div>
    <div class="subtitle">Your Request is accepted. We need to validate your account.</div>
      <div class="subtitle">Please contact <a href="mailto:vendorsuccess@epallet.com">vendorsuccess@epallet.com</a> for any questions.</div>

    <app-correct-tick></app-correct-tick>

    <app-button
      (click)="closeModal()"
    >
      Ok, I got it
    </app-button>
  </div>

</app-modal>
