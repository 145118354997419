import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {ApiService} from '../../../../shared/services/api';
import {map} from 'rxjs/internal/operators';
import {VendorStatusService} from '../../../../shared/services/vendor-status/vendor-status.service';

@Injectable()
export class RegistrationGuard implements CanActivate {

  private _LOGIN_URL = `${this._api.customerApi}`;



  constructor(
    private _vendorStatusService: VendorStatusService,
    private _api: ApiService,
  ) { }




  canActivate() {
   return this._vendorStatusService
     .vendorStatusObservable
     .pipe(
       map(status => {
         if (!status.is_authenticated) {
           window.location.href = this._LOGIN_URL;
           return false;
         } else {
           return true;
         }
       })
     );
  }
}
