<app-card
  class="subscription-item"
  [class.selected]="selected"
  [blueBck]="hovered"
  (mouseenter)="hovered = true"
  (mouseleave)="hovered = false"
  (click)="select()"
>
  <div class="inner-container">
    <div class="payment-details">
      <span class="cost">
        {{ plan.isFree ? '' : '$' }}{{ plan.cost }}
      </span>

      <span
        *ngIf="plan.period"
        class="period"
      >
        per {{ plan.period }}
      </span>
    </div>

    <mat-icon
      *ngIf="selected"
      class="selected-icon"
      [svgIcon]="MatIconEnum.TICK"
    ></mat-icon>
  </div>
</app-card>
