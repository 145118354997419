import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../../../../shared/services/api';
import {SubscriptionPlan} from '../models/subscription-plan.enum';
import {SubscriptionPlanChangeDto} from '../models/subscription-plan-change.dto';
import {SubscriptionPlanDto} from '../models/subscription-plan.dto';
import {tap} from 'rxjs/operators';
import {VendorStatusService} from '../../../../shared/services/vendor-status/vendor-status.service';
import {PromoCodeForm} from '../components/promo-code-form-container/models/promo-code.form';
import {TransactionsUpdateService} from './transactions-update.service';

@Injectable()
export class SubscriptionsService {

  private readonly _URL: string;

  private readonly _subscriptionPlans: {[key: string]: SubscriptionPlanDto} = {
    [SubscriptionPlan.FREE]: new SubscriptionPlanDto('Free', null, SubscriptionPlan.FREE),
    [SubscriptionPlan.MONTHLY]: new SubscriptionPlanDto('40', 'month', SubscriptionPlan.MONTHLY),
    [SubscriptionPlan.QUARTERLY]: new SubscriptionPlanDto('120', '3 months', SubscriptionPlan.QUARTERLY),
    [SubscriptionPlan.ANNUALLY]: new SubscriptionPlanDto('480', 'year', SubscriptionPlan.ANNUALLY),
  };


  constructor(
    private _http: HttpClient,
    private _api: ApiService,
    private _vendorStatusService: VendorStatusService,
    private _transactionsUpdateService: TransactionsUpdateService
  ) {
    this._URL = `${_api.API}vendor/subscriptions`;
  }


  get subscriptionPlansTable() {
    return this._subscriptionPlans;
  }

  get subscriptionPlansArray(): SubscriptionPlanDto[] {
    return Object.values(this._subscriptionPlans);
  }

  get subscriptionPlansArrayAvailableToChoose(): SubscriptionPlanDto[] {
    return this.subscriptionPlansArray.filter(plan => plan.plan !== SubscriptionPlan.FREE);
  }


  async changeSubscriptionPlan(plan: SubscriptionPlan): Promise<void> {
    const body = new SubscriptionPlanChangeDto(plan);

    return this._http
      .post<void>(this._URL, body)
      .toPromise();
  }

  async trackSubscriptionModalShow(): Promise<void> {
    const url = `${this._URL}/track_subscription_plan_selection`;
    const body = {};

    return this._http
      .post<void>(url, body)
      .pipe(
        tap(() => this._vendorStatusService.updateVendorStatus()),
        tap(() => this._transactionsUpdateService.emitTransactionsUpdate()),
      )
      .toPromise();
  }

  async enterPromoCode(promoCodeForm: PromoCodeForm): Promise<void> {
    const url = `${this._URL}/enter_promo_code`;

    return this._http
      .post<void>(url, promoCodeForm)
      .toPromise();
  }
}
