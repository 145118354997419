import {getFromContainer, MetadataStorage, ValidationOptions, ValidationTypes} from 'class-validator';
import {ValidationMetadataArgs} from 'class-validator/metadata/ValidationMetadataArgs';
import {ValidationMetadata} from 'class-validator/metadata/ValidationMetadata';

export function MyIsOptional(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    const args: ValidationMetadataArgs = {
      type: ValidationTypes.CONDITIONAL_VALIDATION,
      target: object.constructor,
      propertyName: propertyName,
      constraints: [(object: any, value: any) => {
        return object[propertyName] !== null && object[propertyName] !== undefined && object[propertyName] !== '';
      }],
      validationOptions: validationOptions
    };
    getFromContainer(MetadataStorage).addValidationMetadata(new ValidationMetadata(args));
  };
}
