import {TourActionType} from '../models/tour-action.enum';
import {TourTypes} from '../models/tour-types';
import {plainToClass} from 'class-transformer';
import {TourModel} from '../models/tour.model';
import {TourStatusesEnum} from '../models/tour-statuses.enum';

export const activateDeactivateProductsTour = plainToClass(TourModel, {
  type: TourTypes.ACTIVATE_PRODUCTS,
  status: TourStatusesEnum.BLOCKED,
  title: 'How to activate/deactivate product for multiple FOB locations',
  unlockConditions: 'Please ensure to add at least one product with fob locations and configured inventory for this product',
  list: [
    {
      action: TourActionType.HIGHLIGHT,
      description: 'First open the Products tab',
      element: TourTypes.ACTIVATE_PRODUCTS + '0',
      title: 'How to activate/deactivate product for multiple FOB locations',
      isElementFixed: true
    },
    {
      action: TourActionType.FORM,
      description: 'Expand the stock records list',
      element: TourTypes.ACTIVATE_PRODUCTS + '1',
      title: 'How to activate/deactivate product for multiple FOB locations',
      isElementFixed: false
    },
    {
      action: TourActionType.TIP,
      description: 'Set the Quantity... ',
      element: TourTypes.ACTIVATE_PRODUCTS + '2',
      title: 'How to activate/deactivate product for multiple FOB locations',
      isElementFixed: false
    },
    {
      action: TourActionType.TIP,
      description: '...and Price fields for each location',
      element: TourTypes.ACTIVATE_PRODUCTS + '3',
      title: 'How to activate/deactivate product for multiple FOB locations',
      isElementFixed: false
    },
    {
      action: TourActionType.HIGHLIGHT,
      description: 'And activate this stock record',
      element: TourTypes.ACTIVATE_PRODUCTS + '4',
      title: 'How to activate/deactivate product for multiple FOB locations',
      isElementFixed: false
    }
  ]
});
