import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiHttpService {

  private readonly url = `${environment.apiUrl}/api`;

  constructor( private http: HttpClient ) { }

 get<T>(path: string, params = null): Observable<T> {
    return this.http.get<T>(`${this.url}/${path}`, {params});
  }

 put<T>(path: string, body: Object = {}): Observable<T> {
    return this.http.put<T>(`${this.url}/${path}`, body);
  }

 post<T>(path: string, body: Object = {}): Observable<T> {
    return this.http.post<T>(`${this.url}/${path}`, body);
  }

 delete<T>(path: string, body: Object = {}): Observable<T> {
    return this.http.delete<T>(`${this.url}/${path}`, body);
  }
}