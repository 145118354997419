import {Injectable} from '@angular/core';
import {Product} from '../../../shared/models/product/product';
import {Observable, BehaviorSubject} from 'rxjs';
import {plainToClass} from 'class-transformer';
import {StringifyCircularObject} from '../../../shared/helpers/StringifyCircularObject';

@Injectable()
export class ProductStoreService {

  private _productSubject = new BehaviorSubject<Product>(null);
  private _productBeforeChangesSubject = new BehaviorSubject<Product>(null);

  private _productSaved = new BehaviorSubject<boolean>(false);

  private _productIsSaved = new BehaviorSubject<boolean>(false);


  get productSaveObservable(): Observable<boolean> {
    return this._productSaved.asObservable();
  }

  get product(): Product {
    return this._productSubject.getValue();
  }

  get productIsSaved(): boolean {
    return this._productIsSaved.getValue();
  }

  get subscribeOnProduct(): Observable<Product> {
    return this._productSubject.asObservable();
  }

  subscribeOnProductBeforeChanges(): Observable<Product> {
    return this._productBeforeChangesSubject.asObservable();
  }

  isSendProduct(data): void {
    this._productIsSaved.next(data);
  }

  setEmptyProduct(): void {
    this.updateProduct(new Product());
  }

  resetProduct(): void {
    this.updateProduct(null);
  }

  resetChanges(): void {
    this.updateProduct(plainToClass(Product, this._productBeforeChangesSubject.getValue()));
  }

  resetProductBeforeChanges(): void {
    this._productBeforeChangesSubject.next(null);
  }

  setProductBeforeChanges(): void {
    const productBeforeChanges = JSON.parse( StringifyCircularObject(this.product) );
    this._productBeforeChangesSubject.next(productBeforeChanges);
  }

  updateProduct(product: Product): void {
    this._productSubject.next(product);
  }

  emitProductSave(): void {
    this._productSaved.next(true);
  }
}
