import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Notification} from '../../../models/notification';
import {NotificationService} from '../../../services/notification.service';
import {Subscription} from 'rxjs';
import {MatIconEnum} from '../../../models/common/mat-icon.enum';
import {ObservableComponent} from 'lib-shared';

@Component({
  selector: 'app-notification',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent extends ObservableComponent implements OnInit {

  @Input()
  isNotificationOpen: boolean;

  @Output()
  onCloseBtnClick = new EventEmitter();

  notifications: Notification[];
  activeNotificationsAmount: number;

  readonly MatIconEnum = MatIconEnum;


  constructor(
    private _notificationsService: NotificationService
  ) {
    super();
  }

  ngOnInit() {
    this._subscribeOnNotifications();
    this._subscribeOnNotificationsAmount();
  }


  closeNotificationPopup() {
    this.onCloseBtnClick.emit();
  }


  private _subscribeOnNotifications(): Subscription {
    return this._notificationsService
      .notificationsObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(notifications => this.notifications = notifications);
  }

  private _subscribeOnNotificationsAmount(): void {
    this._notificationsService
      .notificationsAmountObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(amount => this.activeNotificationsAmount = amount);
  }
}
