import {SharedItemBase} from '../../../../shared/modules/blocks/sharing-items';
import {environment} from '../../../../../environments/environment';
import {Id} from '../../../../shared/models/types';

export class DiscountGroup extends SharedItemBase {
  id: Id;
  available_for_registration: boolean;
  users_count: number;

  readonly sharedUrl = '?invited_group=';

  get sharedLink() {
    return `${environment.customerUrl}${this.sharedUrl}${this.uid}`;
  }
}
