import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Payment} from '../../shared/models/payment';
import {PaymentsService} from '../../shared/services/payments.service';
import {ObservableComponent} from 'lib-shared';
import {ButtonColorScheme} from '../../../../ux/button/models/button-color-scheme.enum';
import {Id} from '../../../../../models/types';

@Component({
  selector: 'app-widget-payments-list',
  templateUrl: './widget-payments-list.component.html',
  styleUrls: ['./widget-payments-list.component.scss']
})
export class WidgetPaymentsListComponent extends ObservableComponent implements OnInit {

  payments: Payment[];
  selectedPaymentId: Id;

  showSpinner = false;

  @Output()
  onChoose = new EventEmitter<void>();

  @Output()
  onAddNewPayment = new EventEmitter<void>();

  readonly ButtonColorScheme = ButtonColorScheme;


  constructor(
    private _paymentsService: PaymentsService
  ) {
    super();
  }


  ngOnInit() {
    this._subscribeOnPaymentsList();
  }


  async choosePayment(): Promise<void> {
    this.showSpinner = true;

    try {
      await this._paymentsService.setPaymentAsDefault(this.selectedPaymentId);
      this.onChoose.emit();
    } catch(err) {
      console.error(err);
    }

    this.showSpinner = false;
  }

  addNewCard(): void {
    this.onAddNewPayment.emit();
  }


  private _subscribeOnPaymentsList(): void {
    this.showSpinner = true;

    this._paymentsService
      .paymentsListObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(payments => {
        this.payments = payments;
        this.showSpinner = false;

        if(!this.selectedPaymentId) {
          this._subscribeOnDefaultPayment();
        }
      });
  }

  private _subscribeOnDefaultPayment(): void {
    this._paymentsService
      .defaultPaymentObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(payment => this.selectedPaymentId = payment.id);
  }

}
