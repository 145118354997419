import {Injectable} from '@angular/core';
import {NavigationService} from '../../../shared/models/navigation/navigation-service';
import {NavigationStep} from '../../../shared/models/navigation/navigation-step';
import {RegistrationStepName} from '../models/registration-step-name';
import {VendorStatusService} from '../../../shared/services/vendor-status/vendor-status.service';
import {BehaviorSubject} from 'rxjs/Rx';
import {applyMixins, ObservableComponent} from 'lib-shared';
import {MonoTypeOperatorFunction} from 'rxjs';

@Injectable()
class RegistrationNavService extends NavigationService<NavigationStep<RegistrationStepName>> {

  isFacilityFormOpen = new BehaviorSubject(false);
  isCompanyInfoFormChanged = new BehaviorSubject(false);

  protected _steps: NavigationStep<RegistrationStepName>[] = [
    new NavigationStep(RegistrationStepName.COMPANY_INFORMATION, 50, true),
    new NavigationStep(RegistrationStepName.LOCATIONS, 50)
  ];

  // ObservableComponent
  _isComponentAlive = true;
  _takeWhileComponentAlive: () => MonoTypeOperatorFunction<any>;

  constructor(
    private _vendorStatusService: VendorStatusService
  ) {
    super();
  }


  subscribeOnVendorStatusAndUpdateRegSteps(): void {
    this._vendorStatusService
      .vendorStatusObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(status => {
        this._setStepCompletedStatus(RegistrationStepName.LOCATIONS, status.has_fob_locations);
        this._setStepCompletedStatus(RegistrationStepName.COMPANY_INFORMATION, status.profile_completed);
      });
  }



  protected _isCurrentOrNavStepCompleted(): boolean {
    return true;
  }



  private _setStepCompletedStatus(stepName: RegistrationStepName, completed: boolean): void {
    this._steps = this._steps.map(step => {
      if(step.title === stepName) {
        step.completed = completed;
      }

      return step;
    });
  }

}

applyMixins(RegistrationNavService, [ObservableComponent]);

export {RegistrationNavService};
