import {Id} from '../types';

export class Restrictive {
//   for_customer_groups: Id[] = [];
  for_customer_types: Id[] = [];
  for_states: string[] = [];

  for_zip_from = '';
  for_zip_to = '';


  get hasRestrictions(): boolean {
    return this.hasZipCodeRestrictions || this.hasStatesRestrictions || this.hasCustomerTypesRestrictions;
  }

//   get hasCustomerGroupsRestrictions(): boolean {
//     return !!this.for_customer_groups && this.for_customer_groups.length > 0;
//   }

  get hasCustomerTypesRestrictions(): boolean {
    return !!this.for_customer_types && this.for_customer_types.length > 0;
  }

  get hasZipCodeRestrictions(): boolean {
    return !!this.for_zip_from && !!this.for_zip_to;
  }

  get hasStatesRestrictions(): boolean {
    return !!this.for_states && this.for_states.length > 0;
  }
}
