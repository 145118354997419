import {Component, Input} from '@angular/core';
import {Notification} from '../../../../models/notification';
import {DateHelper} from '../../../../helpers/date';
import {Messages} from '../../../../helpers/messages';
import {NotificationService} from '../../../../services/notification.service';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent {

  @Input()
  notification: Notification;

  readonly DateHelper = DateHelper;
  readonly messages = new Messages();

  private _isSeenRequestSent = false;

  constructor(
    private notificationService: NotificationService
  ) {}

  async setNotificationAsViewed(): Promise<void> {
    if(!this.notification.seen && !this._isSeenRequestSent) {
      this.notificationService.setNotificationAsViewed(this.notification);
      this._isSeenRequestSent = true;
    }
  }

}
