import * as moment from 'moment';


export class DateHelper {

  static formatDate(date, format: string): string {
    const timestamp = new Date(date).getTime();

    return moment(timestamp).format(format);
  }


  static getTimeFromNow(date: Date): string {
    return moment(date).fromNow();
  }

}
