<app-modal
  *ngIf="!!vendorStatus && showModal"
  class="subscriptions-modal"
  (onCloseModal)="closeModal()"
>
  <div class="bottom-images">
    <img src="../../../../assets/images/subscriptions/boxes.png" alt="boxes">
    <img src="../../../../assets/images/subscriptions/boxes.png" alt="boxes">
  </div>

  <div class="inner-container">
    <div class="title">
      {{ modalTitle }}
    </div>
    <div class="subtitle">
      Please select your auto renewal period
    </div>

    <app-subscriptions-list
      class="subscriptions-list"
      (onPlanChange)="setSubscription($event)"
    ></app-subscriptions-list>

    <div class="subtitle payment-block">
      Choose your payment card:
    </div>
    <app-payments-widget></app-payments-widget>

    <div class="subtitle promo-code-block">
      Or use a promo code:
    </div>
    <app-subscription-promo-code
      (onSuccessfulSubmit)="setSubscription(SubscriptionPlan.FREE)"
    ></app-subscription-promo-code>

    <app-total-amount
      [amount]="totalAmount"
    ></app-total-amount>

    <app-button
      [showSpinner]="showButtonSpinner"
      (click)="confirmModal()"
    >
      Confirm
    </app-button>
  </div>
</app-modal>

<app-modal
  *ngIf="showSuccessModal"
  class="success-modal"
  (onCloseModal)="closeSuccessModal()"
>
  <div class="inner-container">
    <div class="title">
      Thank you!
    </div>
    <div class="subtitle">
      {{ successSubtitle }}
    </div>
    <app-correct-tick></app-correct-tick>

    <app-button
      (click)="closeSuccessModal()"
    >
      Ok, I got it
    </app-button>
  </div>
</app-modal>
