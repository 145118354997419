import {PaginationList} from '../../../../shared/models/product/product-list/pagination-list';
import {DiscountGroupUser} from './discount-group-user';
import {Type} from 'class-transformer';
import {decoratorType} from 'lib-shared';

export class DiscountGroupUserList extends PaginationList<DiscountGroupUser> {

  @Type(decoratorType(DiscountGroupUser))
  results: DiscountGroupUser[];

}
