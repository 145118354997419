import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TourItemModel} from '../models/tour-item.model';
import {HoleParamsModel} from '../models/hole-params.model';
import {TourActionType} from '../models/tour-action.enum';

@Component({
  selector: 'app-tour-tooltip',
  templateUrl: 'tour-tooltip.component.html',
  styleUrls: ['tour-tooltip.component.scss']
})
export class TourTooltipComponent implements OnInit {

  @Input()
  tourItem: TourItemModel;

  @Input()
  set holePosition(position: HoleParamsModel) {
    this._holePosition = position;
    this.checkTipPosition();
  };

  @Input()
  index: number;

  @Input()
  length: number;

  @Output()
  onTourFinish: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onNextClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  positionClass: string = 'right';

  get holePosition(): HoleParamsModel {
    return this._holePosition;
  }

  private _holePosition: HoleParamsModel;

  private _availableClassNames = [
    'left',
    'top',
    'right',
    'bottom'
  ];

  constructor() { }

  ngOnInit(): void {}

  checkTipPosition(): void {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const right = windowWidth - ( this._holePosition.clearLeft + this._holePosition.clearWidth );
    const bottom = windowHeight - ( this._holePosition.clearTop + this._holePosition.clearHeight );
    const { clearLeft: left, clearTop: top } = this._holePosition;
    let max = 0;
    [left, top, right, bottom].forEach( (e, i, array) => {
      if( array[max] <= e ) {
        max = i;
      }
    } );
   this.positionClass = this._availableClassNames[max];
  }

  get topOffset(): string {
    let top: string;
    const {clearTop, clearHeight} = this._holePosition;
    if (this.positionClass === 'top') {
      top = `${clearTop}px`;
    } else if (this.positionClass === 'bottom') {
      top = `${clearTop + clearHeight}px`;
    } else {
      if (clearHeight >= 900) {
        top = `${clearTop + clearHeight / 4}px`;
      }else {
        top = `${clearTop + clearHeight / 2}px`;
      }
    }
    return top;
  }

  get leftOffset(): string {
    if ( this.positionClass === 'right' ) {
      return `${this._holePosition.clearLeft + this._holePosition.clearWidth + 10}px`;
    } else if (this.positionClass === 'left') {
      return `${this._holePosition.clearLeft - 270}px`;
    } else {
      return `${this._holePosition.clearLeft + this._holePosition.clearWidth / 2}px`;
    }
  }

  get isNextDisabled(): boolean {
    return this.index === this.length || this.tourItem.isForm || this.tourItem.nextDisabled;
  }

  next(): void {
    if( !this.tourItem || !this.tourItem.elementNative || this.isNextDisabled ) return;
    if( this.tourItem.action === TourActionType.TIP ) {
      this.onNextClick.emit();
    } else {
      this.tourItem.elementNative.click();
    }
  }

  finishTour(): void {
    this.onTourFinish.emit(true);
  }


}
