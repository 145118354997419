import {EventEmitter, Input, Output} from '@angular/core';
import {SharedModalServiceAbstract} from '../../shared-modal/models/shared-modal-service.abstract';
import {SharedItemBase} from '../../models/shared-item-base';

export abstract class ShareButtonComponentContainerAbstract {

  @Input()
  areAllItemsForCertainGroups: boolean;

  @Input()
  itemsForCertainGroupsNames: string;

  @Input()
  sharedItem: SharedItemBase;

  @Output()
  setSpinnerStatus = new EventEmitter<boolean>();


  constructor(
    protected _sharedModalService: SharedModalServiceAbstract,
  ) {}


  openShareModal(item: SharedItemBase): void {
    this._sharedModalService.openModal(item);
  }

  updateSpinnerStatus(showSpinner: boolean): void {
    this.setSpinnerStatus.emit(showSpinner);
  }

  async abstract shareItem(): Promise<void>;
}
