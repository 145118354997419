import {TourActionType} from './tour-action.enum';
import {TourTypes} from './tour-types';

export class TourItemModel {

  action: TourActionType;
  description: string;
  title: string;
  isChild: boolean; // can be using for access to parents element up to second level
  childLevel = 0; // declare levels of parent elements (for now up tp 2)
  isElementFixed = false; // set true when element has fixed position

  // element contains string, that mean data-attribute of highlighted element
  element: TourTypes | string;

  // TODO: find the way to make next button working everywhere
  nextDisabled: boolean = false; // temporary used for disable next button at tooltip for some tour steps

  // cancel button selector used to close tour when user cancel some tour steps from the UI
  cancelElement?: string;

  // used for calculating z-index
  highLevelElement: boolean = false;

  // recalculate hole position on scrolling when true
  isProbablyFixed: boolean = false;

  link?: string;

  resizable: boolean = false;

  constructor(
    action,
    description,
    element,
    link = null,
    title = '',
    isElementFixed = false,
    cancelElement = null,
    isChild = false,
    childLevel = 0,
    nextDisabled = false,
    highLevelElement = false
  ) {
    this.action = action;
    this.description = description;
    this.element = element;
    this.link = link;
    this.title = title;
    this.isChild = isChild;
    this.childLevel = childLevel;
    this.isElementFixed = isElementFixed;
    this.cancelElement = cancelElement;
    this.nextDisabled = nextDisabled;
    this.highLevelElement = highLevelElement;
  }

  get elementNative(): HTMLElement {
    return !this.isChild ? document.querySelector(`[data-tour*="${this.element}"]`) : this.parentNativeElement;
  }

  get cancelElementNative(): HTMLElement {
    return document.querySelector(`[data-tour="${this.cancelElement}"]`);
  }

  get parentNativeElement(): HTMLElement {
    const element = document.querySelector(`[data-tour*="${this.element}"]`);
    return this.childLevel && this.childLevel === 1 && !!element ?
      document.querySelector(`[data-tour*="${this.element}"]`).parentElement :
      this.childLevel && this.childLevel === 2 && !!element ?
        document.querySelector(`[data-tour*="${this.element}"]`).parentElement.parentElement :
        null;
  }

  get isForm(): boolean {
    return this.action === TourActionType.FORM;
  }

}
