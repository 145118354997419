import { ApiHttpService } from "../../../core/ApiHttp/api-http.service";
import { plainToClassSetDefaults } from "../../helpers/plain-to-class-defaults";
import { StringifyCircularObject } from "../../helpers/StringifyCircularObject";
import { OrderWeightForm } from "../../models/product/product-list/order-weight.form";
import { ApiService } from "../api";
import {map} from 'rxjs/internal/operators';
import { Id } from "lib-shared/shared/models/types";
import { MatDialog } from "@angular/material";

export class OrderWeightService {
    private readonly _URL: string;
    constructor(
        private api: ApiService,
        private http: ApiHttpService,
        private dialog: MatDialog

    ) {
        this._URL = 'vendor/order_weight_setting';
    }

    getOrderWeight(): Promise<OrderWeightForm[]> {
        return this.http.get<OrderWeightForm[]>(this._URL).toPromise();
    }

    async editOrderWeight(orderweight: OrderWeightForm | any): Promise<OrderWeightForm> {
        const url = `${this._URL}/${orderweight.id}`;
    
        const productProcessed = JSON.parse( StringifyCircularObject(orderweight) );
    
        return this.http
          .put<OrderWeightForm>(url, productProcessed)
          .pipe(
            map(newOrder => plainToClassSetDefaults(OrderWeightForm, newOrder)))
          .toPromise();
      }
    
    async addOrderWeight(orderweight: OrderWeightForm): Promise<OrderWeightForm> {
    return this.http
        .post<OrderWeightForm>(this._URL, orderweight)
        .pipe(
        map(newOrderWeight => plainToClassSetDefaults(OrderWeightForm, newOrderWeight))
        )
        .toPromise();
    }

    async deleteOrderWeight(id: Id): Promise<void> {
        const url = `${this._URL}/${id}`;

        return this.http
            .delete<void>(url).toPromise();
        }        
}