import {ArrayNotEmpty, IsArray} from 'class-validator';
import {DockHours, FobType} from './fob-type.enum';
import {ShippingAddressForm} from '../../../../shared/modules/forms/shipping-address-form/models/shipping-address-form';
import {TruckSizeRestriction} from './enums/truck-size-restriction';
import {MyIsNotEmpty, MyIsEmail, MyIsNumberString, MyIsOptional, MyMin} from '../../../../shared/modules/form-validation/decorators';
import {Id} from '../../../../shared/models/types';


export class FacilityForm extends ShippingAddressForm {
  id: Id;

  @IsArray()
  @ArrayNotEmpty({message: 'Please, select at least one option'})
  fob_type: FobType[] = [];

  @MyIsNotEmpty()
  name = '';

  @MyIsNotEmpty()
  company_name = '';

  @MyIsNotEmpty()
  contact_name = '';

  @MyIsNotEmpty()
  contacts = '';

  fax = '';

  @MyIsNotEmpty()
  @MyIsEmail()
  email = '';

  @MyIsOptional()
  @MyIsNumberString()
  bio_registration_number = '';

  @MyIsNotEmpty()
  for_delivery_from = '07:00:00';

  @MyIsNotEmpty()
  for_delivery_to = '17:00:00';

  @MyIsNotEmpty()
  truck_size_restrictions: TruckSizeRestriction = TruckSizeRestriction.NONE;

  @MyMin(1)
  min_pallet_quantity = 1;

  @MyMin(1)
  min_weight = 1;

  @MyMin(1)
  min_case_quantity = 1;

  restrictions_height = '';
  restrictions_weight = '';

  lift_gate_required = false;
  can_build_mixed_pallet = false;
  appointment_required = false;

  swing_door = false;
  rollup_door = false;
  pallet_exchange_required = false;

  first_come_first_serve = true;

  show_portal_site = false;
  portal_site = '';

  address_ : string;

  getFullAddress(): string {
    if(this.state){
      return `${this.name}, ${this.state}`;
    }
    else if(this.city) {
      return `${this.name}, ${this.city}`;
    }
    else{
      return this.name;
    }
  }

  facility_contact_name_list = [];
  f_contact_name_lists = [];

  facility_contact_email_list = [];
  f_contact_email_lists = [];

  warehouse_pickup_days_and_time_info = [];
}
