import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarkInCircleLogoComponent } from './mark-in-circle-logo.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [MarkInCircleLogoComponent],
  exports: [MarkInCircleLogoComponent],
})
export class MarkInCircleLogoModule { }
