import {ProductNutritionElementSign} from './enums/product-nutrition-element-sign.enum';
import {ProductNutritionElementUom} from './enums/product-nutrition-element-uom.enum';
import {ProductNutritionElement} from './product-nutrition-element';

export class ProductNutritionElementWithDv extends ProductNutritionElement {
  dv: number = null;

  constructor(dv: number, value: string, sign?: ProductNutritionElementSign, uom?: ProductNutritionElementUom) {
    super(value, sign, uom);
    this.dv = dv;
  }



  get dvText(): string {
    return (!!this.roundDailyValue() && this.roundDailyValue() >= 0) ? this.nutritionSign + this.roundDailyValue() + '%' : null;
  }

  getNutritionToRecalculate() {
    return {
      dv: null,
      value: this.value,
      sign: this.sign,
      uom: this.uom
    };
  }

  isNutritionFilled(): boolean {
    return super.isNutritionFilled() || !!this.dv;
  }

  roundDailyValue(): number {
    return (this.dv !== null && +this.dv >= 0) ? Math.round(+this.dv) : null;
  }
}
