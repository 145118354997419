export enum ButtonColorScheme {
  PRIMARY = 'primary',
  PRIMARY_SHADOW = 'primary-shadow',
  PRIMARY_REVERSE = 'primary-reverse',
  DEFAULT = 'default',
  GREEN = 'green',
  ORANGE = 'orange',
  GRAY = 'gray',
  DASHED = 'dashed',
  WHITE = 'white',
}
