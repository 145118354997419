import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailVerificationComponent } from './email-verification.component';
import {ModalModule} from '../shared/modules/ux/modal/modal.module';
import {MessageListModule, ButtonModule} from 'lib-shared';

@NgModule({
	imports: [
		CommonModule,
		ModalModule,
		ButtonModule,
		MessageListModule
	],
	declarations: [EmailVerificationComponent],
	exports: [EmailVerificationComponent],
})
export class EmailVerificationModule { }
