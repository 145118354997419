<button
  mat-icon-button
  type="button"
>
  <mat-icon
    [style.width]="width + 'px'"
    [style.height]="height + 'px'"
    [svgIcon]="matIcon"
  ></mat-icon>
</button>
