import {AfterContentInit, Component} from '@angular/core';
import {RegisterIconService} from './shared/services/register-icon.service';
import {SideMenuService} from './shared/services/side-menu.service';
import {Router, RoutesRecognized} from '@angular/router';
import {RoutingHistoryService} from './shared/services/routing-history/routing-history.service';
import {filter, map} from 'rxjs/operators';
import {TourService} from './site-tour/services/tour.service';
import * as _ from 'lodash';
import {ObservableComponent} from 'lib-shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends ObservableComponent implements AfterContentInit {

  isMenuOpened = true;

  showMenu = false;

  showTours = false;


  constructor(
    private _registerIconService: RegisterIconService,
    private _sideMenuService: SideMenuService,
    private _router: Router,
    private _routingHistoryService: RoutingHistoryService,
    private _tourService: TourService
  ) {
    super();
    this._subscribeOnSideMenuOpened();
    this._registerMatIcons();
    this._initRoutingHistory();
    this._subscribeOnShowMainMenu();
    // this.throwTestError();
  }

  // public throwTestError(): void {
  //   throw new Error("Sentry Test Error");
  // }


  private _subscribeOnSideMenuOpened(): void {
    this._sideMenuService
      .sideMenuOpenedObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe(opened => this.isMenuOpened = opened);
  }

  private _registerMatIcons(): void {
    this._registerIconService.registerIcons();
  }

  private _subscribeOnShowMainMenu(): void {
    this._router
      .events
      .pipe(
        this._takeWhileComponentAlive(),
        filter(event => event instanceof RoutesRecognized),
        map((event: RoutesRecognized) => event.state.root.firstChild.data['isShowMenu']),
        filter(show => typeof show === 'boolean')
      )
      .subscribe(showMenu => this.showMenu = showMenu);
  }

  private _subscribeToTourState(): void {
    this._tourService.tourAvailableAsObservable
      .pipe(
        this._takeWhileComponentAlive()
      )
      .subscribe( (value) => {
      this.showTours = value;
    } );
  }

  private _initRoutingHistory(): void {
    this._routingHistoryService.initLoggingHistory();
  }

  private _addEventListener(): void {
    document.addEventListener('scroll',
    _.debounce( () => {
      this._tourService.scrollEmitter();
    }, 500 ) );
  }

  ngAfterContentInit(): void {
    window.onload = () => {
      this._subscribeToTourState();
      this._addEventListener();
    };
  }

}
