import {environment} from '../../../../../../environments/environment';

export abstract class SharedItemBase {
  name: string;
  uid: string;

  abstract sharedUrl: string;

  get sharedLink(): string {
    return `${environment.customerUrl}${this.sharedUrl}/${this.uid}`;
  }
}
