import {Id} from '../../../shared/models/types';

export class SetPaymentDefaultRequestDto {
  id: Id;
  is_default: boolean;

  constructor(cardId: Id) {
    this.id = cardId;
    this.is_default = true;
  }
}
