import {TourActionType} from '../models/tour-action.enum';
import {TourTypes} from '../models/tour-types';
import {plainToClass} from 'class-transformer';
import {TourModel} from '../models/tour.model';
import {TourStatusesEnum} from '../models/tour-statuses.enum';

export const archiveRestoreProductTour = plainToClass(TourModel, {
  type: TourTypes.ARCHIVE_RESTORE_PRODUCT,
  status: TourStatusesEnum.BLOCKED,
  title: 'Archive and restore the product',
  unlockConditions: 'Please add at least one Product',
  list: [
    {
      action: TourActionType.HIGHLIGHT,
      description: 'Let\'s open a Products tab',
      element: TourTypes.ARCHIVE_RESTORE_PRODUCT + '0',
      title: 'How to archive and restore the product',
      isElementFixed: true
    },
    {
      action: TourActionType.FORM,
      description: 'Then select one or more products',
      element: TourTypes.ARCHIVE_RESTORE_PRODUCT + '1',
      title: 'How to archive and restore the product',
      isElementFixed: false
    },
    {
      action: TourActionType.FORM,
      description: 'Open actions menu',
      element: TourTypes.ARCHIVE_RESTORE_PRODUCT + '2',
      title: 'How to archive and restore the product',
      isElementFixed: false
    },
    {
      action: TourActionType.FORM,
      description: 'Select "Archive" option',
      element: TourTypes.ARCHIVE_RESTORE_PRODUCT + '3',
      title: 'How to archive and restore the product',
      isElementFixed: false,
      highLevelElement: true
    },
    {
      action: TourActionType.FORM,
      description: 'Confirm your decision',
      element: TourTypes.ARCHIVE_RESTORE_PRODUCT + '4',
      title: 'How to archive and restore the product',
      isElementFixed: false,
      highLevelElement: true,
      cancelElement: 'CANCEL'
    },
    {
      action: TourActionType.FORM,
      description: 'Now let\'s open Archived products list',
      element: TourTypes.ARCHIVE_RESTORE_PRODUCT + '5',
      title: 'How to archive and restore the product',
      isElementFixed: false
    },
    {
      action: TourActionType.HIGHLIGHT,
      description: 'Select "Product Archive" option',
      element: TourTypes.ARCHIVE_RESTORE_PRODUCT + '6',
      title: 'How to archive and restore the product',
      isElementFixed: false,
      highLevelElement: true
    },
    {
      action: TourActionType.TIP,
      description: 'Here is all archived products',
      element: TourTypes.ARCHIVE_RESTORE_PRODUCT + '7',
      title: 'How to archive and restore the product',
      isElementFixed: false
    },
    {
      action: TourActionType.FORM,
      description: 'Let\'s restore the archived product. First select the archived product',
      element: TourTypes.ARCHIVE_RESTORE_PRODUCT + '8',
      title: 'How to archive and restore the product',
      isElementFixed: false
    },
    {
      action: TourActionType.FORM,
      description: 'Click to "Restore" button and remove selected product from archive',
      element: TourTypes.ARCHIVE_RESTORE_PRODUCT + '9',
      title: 'How to archive and restore the product',
      isElementFixed: false,
      cancelElement: 'CANCEL'
    },
    {
      action: TourActionType.FORM,
      description: 'Confirm your decision',
      element: TourTypes.ARCHIVE_RESTORE_PRODUCT + '_10',
      title: 'How to archive and restore the product',
      isElementFixed: false,
      highLevelElement: true,
      cancelElement: 'CANCEL'
    },
    {
      action: TourActionType.HIGHLIGHT,
      description: 'And back to product list to see previously restored products.',
      element: TourTypes.ARCHIVE_RESTORE_PRODUCT + '_11', // I propose to use a underscore before two-digits slide numbers
      title: 'How to archive and restore the product',
      isElementFixed: false
    }
  ]
});
