export enum CardType {
  VISA = 'Visa',
  AMERICAN_EXPRESS = 'American Express',
  MASTERCARD = 'MasterCard',
  DISCOVER = 'Discover',
  JCB = 'JCB',
  DINNERS_CLUB = 'Diners Club',
  UNION_PAY = 'UnionPay',
  UNKNOWN = 'Unknown'
}
