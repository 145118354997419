import {ApiService} from '../../../../services/api';
import {HttpClient} from '@angular/common/http';
import {Id} from '../../../../models/types';
import {BackendFile} from './backend-file';

export abstract class MediaServiceAbstract {

  constructor(
    protected _api: ApiService,
    protected _http: HttpClient
  ) {}



  abstract async addFile(multipartData: FormData, options?: Object): Promise<BackendFile>;

  abstract async deleteFile(fileId: Id, options?: Object): Promise<void>;

}
