import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsOfUseComponent } from './terms-of-use.component';
import {TermsOfUseService} from './shared/terms-of-use.service';
import {ModalModule} from '../../ux/modal/modal.module';
import {ButtonModule} from '../../ux/button/button.module';
import {MatCheckboxModule, MatProgressSpinnerModule} from '@angular/material';
import {FormsModule} from '@angular/forms';
import { TermsOfUseFinishComponent } from './terms-of-use-finish/terms-of-use-finish.component';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    ButtonModule,
    MatCheckboxModule,
    FormsModule,
    MatProgressSpinnerModule,
    RouterModule
  ],
  providers: [
    TermsOfUseService
  ],
  declarations: [TermsOfUseComponent, TermsOfUseFinishComponent],
  exports: [TermsOfUseComponent],
})
export class TermsOfUseModule { }
