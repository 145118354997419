import { MatDialog } from "@angular/material";
import { Id } from "lib-shared/shared/models/types";
import { ApiHttpService } from "../../../core/ApiHttp/api-http.service";
import { plainToClassSetDefaults } from "../../helpers/plain-to-class-defaults";
import { StringifyCircularObject } from "../../helpers/StringifyCircularObject";
import { MixedPalletSettingForm } from "../../models/product/product-list/mixed-pallet-setting.form";
import { ApiService } from "../api";
import {map} from 'rxjs/internal/operators';

export class MixedPalletService{
    private readonly _URL: string;

    constructor(
        private api: ApiService,
        private http: ApiHttpService,
        private dialog: MatDialog
    ) {
        this._URL = 'vendor/mixed_pallet_setting';
    }

    getMixedPallet(): Promise<MixedPalletSettingForm[]> {
        return this.http.get<MixedPalletSettingForm[]>(this._URL).toPromise();
    }

    async editMixedPallet(mixedPallet: MixedPalletSettingForm | any): Promise<MixedPalletSettingForm>{
        const url = `${this._URL}/${mixedPallet.id}`;

        const productProcessed = JSON.parse(StringifyCircularObject(mixedPallet));

        return this.http.put<MixedPalletSettingForm>(url, productProcessed).pipe(
            map(newmixedPallet => plainToClassSetDefaults(MixedPalletSettingForm, newmixedPallet))
        ).toPromise();
    }

    async addMixedPallet(mixedPallet: MixedPalletSettingForm): Promise<MixedPalletSettingForm> {
        return this.http
            .post<MixedPalletSettingForm>(this._URL, mixedPallet)
            .pipe(
                map(newmixedPallet => plainToClassSetDefaults(MixedPalletSettingForm, newmixedPallet))).
                toPromise();
        }
    
    async deleteMixedPallet(id: Id): Promise<void> {
        const url = `${this._URL}/${id}`;

        return this.http
            .delete<void>(url).toPromise();
    } 
}