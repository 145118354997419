import {TourTypes} from './tour-types';

export class ActiveTourPointer {

  type: TourTypes;
  index: number;

  constructor(type, index) {
    this.type = type;
    this.index = index;
  }

}
