import { Id } from "lib-shared/shared/models/types";
import { MyIsNotEmpty, MyMin } from "../../../modules/form-validation/decorators";
import { MinimumPalletLoadPercents } from "../enums/minimum-pallet-load-percents.enum";

export class MixedPalletSettingForm {
    id : Id = null;
    for_selected_products: Array<Id> = [];
    for_all_products: boolean = true;

    @MyIsNotEmpty()
    minimum_pallet_load_percents: MinimumPalletLoadPercents = MinimumPalletLoadPercents.PER_50;

    @MyIsNotEmpty()
    @MyMin(1)
    mixed_pallet_max_weight = 2700;
  
    @MyIsNotEmpty()
    @MyMin(1)
    mixed_pallet_max_height = 78;
}