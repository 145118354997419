export enum UnitSizeUom {
  LBS = 'LBS',
  KG = 'KG',
  OZ = 'OZ',
  G = 'G',
  GALLON = 'GALLON',
  PINT = 'PINT',
  LITER = 'LITER',
  QUART = 'QUART',
  CT = 'CT',
  FL_OZ = 'FL_OZ',
  ML = 'ML',
  VARIOUS = 'VARIOUS'
}
